import {configureStore} from '@reduxjs/toolkit';
import loginReducer from './loginsSlice';
import ownerReducer from './ownersSlice';
import menusReducer from './menusSlice';
import ingredientReducer from './ingredientsSlice';
import translatedIngredientReducer from './translatedIngredientsSlice'

export const store = configureStore({
    reducer: {
      logins: loginReducer,
      owners: ownerReducer,
      menus: menusReducer,
      ingredients: ingredientReducer,
      translatedIngredients: translatedIngredientReducer
    },
  });