import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
const apiUrlTranslatedIngredients = `${process.env.REACT_APP_SERVER_BASE_URL}/ingredient/translations/owner`;
const apiUrlTranslatedIngredientCreation = `${process.env.REACT_APP_SERVER_BASE_URL}/ingredient/translations/create`;
const apiUrlIngredientTranslationUpdate = `${process.env.REACT_APP_SERVER_BASE_URL}/ingredient/translations`;

export const createIngredientTranslation = createAsyncThunk('ingredient/translation/create', async (ingredientTranslationDetail) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response =  await axios.post(`${apiUrlTranslatedIngredientCreation}`, ingredientTranslationDetail, {
            headers: { 'Authorization': `${token}` }
          });
          console.log(response)
          return response.data;
    } catch (error) {
        console.log(error)
        console.error('Error creating ingredient translation', error);
        throw new Error('Error creating ingredient translation');
    }
})

export const getTranslatedIngredients = createAsyncThunk('ingredient/translations/owner', async (language) => {
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response =  await axios.get(`${apiUrlTranslatedIngredients}?language=${language}`, {
            headers: { 'Authorization': `${token}` }
          });
          console.log(response)
          return response.data;
    } catch (error) {
        console.log(error)
        console.error('Error getting owner translated ingredients', error);
        throw new Error('Error getting owner translated ingredients');
    }
})

export const updateIngredientTranslation = createAsyncThunk('ingredient/translations/update', async ({ingredientTranslatedId, dataToUpdate}) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response =  await axios.patch(`${apiUrlIngredientTranslationUpdate}/${ingredientTranslatedId}/owner/update`, dataToUpdate, {
            headers: { 'Authorization': `${token}` }
          });
          console.log(response)
          return response.data;
    } catch (error) {
        console.log(error)
        console.error('Error updating ingredient', error);
        throw new Error('Error updating ingredient');
    }
})

const translatedIngredientsSlice = createSlice({
    name: 'translatedIngredients',
    initialState:{
        translatedIngredientsOwner: null,
        translatedIngredientsOwnerIsLoading: true,
        translatedIngredientsOwnerError: null,
        translatedIngredientsOwnerSuccess: null,
        translatedIngredientCreation: null,
        translatedIngredientCreationIsLoading: true,
        translatedIngredientCreationError: null,
        translatedIngredientCreationSuccess: null,
        ingredientTranslatedUpdate: null,
        ingredientTranslatedUpdateIsLoading: true,
        ingredientTranslatedUpdateError: null,
        ingredientTranslatedUpdateSuccess: null
    }, reducers:{

    }, extraReducers: (builder) =>{
        builder
        .addCase(getTranslatedIngredients.pending, (state, action) => {
            state.translatedIngredientsOwnerIsLoading = true
        })
        .addCase(getTranslatedIngredients.rejected, (state, action) => {
            state.translatedIngredientsOwnerError = action.payload
            state.translatedIngredientsOwnerIsLoading = false
        })
        .addCase(getTranslatedIngredients.fulfilled, (state, action) => {
            state.translatedIngredientsOwner = action.payload
            state.translatedIngredientsOwnerSuccess = action.payload.message
            state.translatedIngredientsOwnerIsLoading = false
        })
        .addCase(createIngredientTranslation.pending, (state, action) => {
            state.translatedIngredientCreationIsLoading = true
        })
        .addCase(createIngredientTranslation.rejected, (state, action) => {
            state.translatedIngredientCreationError = action.payload
            state.translatedIngredientCreationIsLoading = false
        })
        .addCase(createIngredientTranslation.fulfilled, (state, action) => {
            state.translatedIngredientCreation = action.payload
            state.translatedIngredientCreationSuccess = action.payload.message
            state.translatedIngredientCreationIsLoading = false
        })
        .addCase(updateIngredientTranslation.pending, (state, action) => {
            state.ingredientTranslatedUpdateIsLoading = true
        })
        .addCase(updateIngredientTranslation.rejected, (state, action) => {
            state.ingredientTranslatedUpdateError = action.payload
            state.ingredientTranslatedUpdateIsLoading = false
        })
        .addCase(updateIngredientTranslation.fulfilled, (state, action) => {
            state.ingredientTranslatedUpdate = action.payload
            state.ingredientTranslatedUpdateSuccess = action.payload.message
            state.ingredientTranslatedUpdateIsLoading = false
        })
    }
})

export default translatedIngredientsSlice.reducer;