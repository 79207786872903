import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialization } from '../redux/menusSlice';
import { FiEdit } from 'react-icons/fi';
import CreateMenuTranslations from './CreateMenuTranslations';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import AWS from 'aws-sdk';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';
import { XMarkIcon } from "@heroicons/react/24/outline";
import Buttons from './modal/Buttons';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import Language from './modal/Language';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import TranslationsNew from './TranslationsNew';
import TimeSlots from './modal/TimeSlots';


const CreateMenu = ({ owner, menu, showCreateMenu, setShowCreateMenu, refreshAllMenusOwner }) => {

    const url = process.env.REACT_APP_SERVER_BASE_URL
    const [showCreateMenuTranslations, setShowCreateMenuTranslations] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [translations, setTranslations] = useState([])
    const [outputTranslations, setOutputTranslations] = useState([])
    const isLoading = useSelector((state) => state.menus.isLoadingInitialization);
    const errorMessage = useSelector((state) => state.menus.errorInitialization);
    const successMessage = useSelector((state) => state.menus.successInitialization);
    const [forceHideSaveButton, setForceHideSaveButton] = useState(false)
    const [imageFileName, setImageFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadMessage, setUploadMessage] = useState(false)
    const [uploadError, setUploadError] = useState(false)



    useEffect(() => {

        const formattedTranslations = outputTranslations.map(lang => ({
            language: lang.language,
            translatedMenu: {
                translatedName: lang.translatedName,
                translatedDescription: lang.translatedDescription
            }
        }))
            .filter(lang => lang.translatedMenu.translatedName);
        setTranslations(formattedTranslations)

    }, [outputTranslations])
    // Riduciamo le lingue
    // const languages = ['ita', 'fra', 'spa', 'eng', 'ara', 'cmn', 'jpn', 'rus', 'por', 'nld', 'swe', 'deu'];
    const languages = ['fra', 'spa', 'eng', 'deu'];

    const [defaultLanguage, setDefaultLanguage] = useState('');
    const [filteredLanguages, setFilteredLanguages] = useState([]);

    const handleLanguageChange = (e) => {
        const selectedLanguage = e.target.value;
        setDefaultLanguage(selectedLanguage);
        setFilteredLanguages(languages.filter(language => language !== selectedLanguage));
        setMenuDetails(prevState => ({
            ...prevState,
            defaultLanguage: selectedLanguage
        }));
    };

    const inputTranslations = filteredLanguages.map(lang => ({
        language: lang,
        translatedName: '',
        translatedDescription: ''
    }));


    const [uploadedFileName, setUploadedFileName] = useState(false);





    // const handleFileInput = async (e) => {
    //     const file = e.target.files[0];

    //     if (!file) {
    //         console.log('No file selected');
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('menu', file);

    //     for (let pair of formData.entries()) {
    //         console.log(pair[0] + ', ' + pair[1]);
    //     }

    //     try {

    //         const response = await axios.post(`${url}/upload-menu`, formData);
    //         console.log('response', response)

    //         console.log('File uploaded successfully:', response.data.url);
    //         const uploadedFileName = response.data.url.split('/').pop();
    //         setMenuDetails(prevState => ({
    //             ...prevState,
    //             menu: {
    //                 ...prevState.menu,
    //                 image: uploadedFileName // URL to the uploaded file
    //             }
    //         }));
    //         setImagePreview(URL.createObjectURL(file));
    //         setUploadMessage(true);
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //         setUploadError(true);
    //     }
    // };

    useEffect(() => {

        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                image: uploadedFileName // URL to the uploaded file
            }
        }));

    }, [uploadedFileName])



    const handleRemoveImage = () => {

        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                image: ''
            }
        }));
        setUploadMessage(false)
        setUploadError(false);
        document.getElementById('fileInput').value = '';
    }

    const [showPicker, setShowPicker] = useState(false)
    const handleColorPickerOpen = () => setShowPicker(true);
    const handleColorPickerClose = () => setShowPicker(false);
    const [color, setColor] = useState('');

    console.log(color)

    const handleColorChange = (color) => {
        setColor(color.hex);
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                color: color.hex
            }
        }));
    };

    const deleteColor = () => {
        setColor('');
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                color: ''
            }
        }));
    }

    const [menuDetails, setMenuDetails] = useState({
        defaultLanguage: '',
        settings: {
            alwaysActive: false,
            daysActive: [],
            timeSlot: {
                startTime: '',
                endTime: ''
            },
            timeSlots: [] // Aggiungi questo
        },
        menu: {
            name: '',
            description: '',
            color: '',
            image: ''
        }
    });

    const closeCreateMenu = () => {
        setShowCreateMenu(false)
    }

    const dispatch = useDispatch();

    const weekDays = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (!name) {
            return;
        }
        console.log('CHECKED', checked)
        setMenuDetails((prevState) => {
            const newState = { ...prevState };
            if (type === 'checkbox') {
                if (name === 'settings.alwaysActive') {
                    newState.settings.alwaysActive = checked;
                    if (checked) {
                        newState.settings.daysActive = [];
                        newState.settings.timeSlot.startTime = '';
                        newState.settings.timeSlot.endTime = '';
                    }
                } else if (name === 'settings.daysActive') {
                    const dayIndex = parseInt(value, 10);
                    if (checked) {
                        newState.settings.daysActive = [...new Set([...prevState.settings.daysActive, dayIndex])];
                    } else {
                        newState.settings.daysActive = prevState.settings.daysActive.filter((day) => day !== dayIndex);
                    }
                }
            } else {
                const nameParts = name.split('.');
                let currentPart = newState;
                for (let i = 0; i < nameParts.length - 1; i++) {
                    currentPart = currentPart[nameParts[i]];
                }
                currentPart[nameParts[nameParts.length - 1]] = value;
            }
            return newState;
        });
    };


    const handleAddTimeSlot = (dayIndex) => {
        setMenuDetails(prevState => ({
            ...prevState,
            settings: {
                ...prevState.settings,
                timeSlots: prevState.settings.timeSlots.map(slot => {
                    if (slot.day === dayIndex) {
                        return {
                            ...slot,
                            slots: [...slot.slots, { startTime: '', endTime: '' }]
                        };
                    }
                    return slot;
                })
            }
        }));
    };

    const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
        setMenuDetails(prevState => {
            const updatedTimeSlots = prevState.settings.timeSlots.map(slot => {
                if (slot.day === dayIndex) {
                    return {
                        ...slot,
                        slots: slot.slots.filter((_, idx) => idx !== slotIndex)
                    };
                }
                return slot;
            });

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };


    const handleTimeSlotChange = (dayIndex, slotIndex, field, value) => {
        setMenuDetails(prevState => {
            const updatedTimeSlots = prevState.settings.timeSlots.map(slot => {
                if (slot.day === dayIndex) {
                    return {
                        ...slot,
                        slots: slot.slots.map((s, idx) => {
                            if (idx === slotIndex) {
                                return {
                                    ...s,
                                    [field]: value
                                };
                            }
                            return s;
                        })
                    };
                }
                return slot;
            });

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };

    const handleDayToggle = (dayIndex) => {
        setMenuDetails(prevState => {
            const existingSlot = prevState.settings.timeSlots.find(slot => slot.day === dayIndex);

            let updatedTimeSlots;
            if (existingSlot) {
                // Se esiste già, lo rimuoviamo
                updatedTimeSlots = prevState.settings.timeSlots.filter(slot => slot.day !== dayIndex);
            } else {
                // Se non esiste, lo aggiungiamo con un slot vuoto
                updatedTimeSlots = [
                    ...prevState.settings.timeSlots,
                    { day: dayIndex, slots: [{ startTime: '', endTime: '' }] }
                ];
            }

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };


    const handleSubmit = async (event) => {

        event.preventDefault();
        setLoading(true)
        setError(false)
        setSuccess(false)
        try {
            if (translations) {
                const completeMenuDetails = { ...menuDetails, translations }
                const response = await dispatch(initialization(completeMenuDetails))
                if (initialization.fulfilled.match(response)) {
                    setLoading(false);
                    setSuccess(true)
                    if (error) {
                        setError(false)
                    }
                    setForceHideSaveButton(true)
                    setTimeout(() => {
                        refreshAllMenusOwner();
                        closeCreateMenu();
                    }, 2000);
                } else {
                    setLoading(false)
                    setError(true)
                }
            } else {
                const response = await dispatch(initialization(menuDetails))
                if (initialization.fulfilled.match(response)) {
                    setLoading(false);
                    setSuccess(true)
                    if (error) {
                        setError(false)
                    }
                    setForceHideSaveButton(true)
                    setTimeout(() => {
                        refreshAllMenusOwner();
                        closeCreateMenu();
                    }, 2000);
                } else {
                    setLoading(false)
                    setError(true)
                }
            }

        } catch (error) {
            console.error('Errore nell\'invio dei dati del menu:', error);
        }
    };

    const handleToggle = () => {
        const alwaysActive = !menuDetails.settings.alwaysActive;
        setMenuDetails({
            ...menuDetails,
            settings: {
                ...menuDetails.settings,
                alwaysActive: alwaysActive,
                daysActive: alwaysActive ? [] : menuDetails.settings.daysActive,
                timeSlot: {
                    startTime: alwaysActive ? '' : menuDetails.settings.timeSlot.startTime,
                    endTime: alwaysActive ? '' : menuDetails.settings.timeSlot.endTime,
                }
            }
        });
    };

    useEffect(() => {
        console.log("defaultLanguage", defaultLanguage)
    }, [defaultLanguage])

    useEffect(() => {
        console.log("inputTranslations", inputTranslations)
    }, [inputTranslations])

    return (

        <>
            {showCreateMenu &&
                <Modal handleSubmit={handleSubmit}
                    closeFunction={closeCreateMenu}
                    title="Crea Menu"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                >

                    <Input description={menuDetails.menu.name || ''}
                        name='menu.name'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Nome</Input>

                    <Input description={menuDetails.menu.description || ''}
                        name='menu.description'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Descrizione</Input>

                    <hr className="border-gray-400 mt-8"></hr>


                    <TranslationsNew
                        languages={languages}
                        defaultLanguage={defaultLanguage}
                        handleLanguageChange={handleLanguageChange}
                        inputTranslations={inputTranslations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></TranslationsNew>

                    <hr className="border-gray-400 mt-8"></hr>



                    <div className='flex flex-col items-start mt-6'>
                        <div className='flex flex-row items-center justify-center gap-2 my-1'>
                            <span className='font-bold'>Attivo sempre</span>

                            <div >
                                <Toggle
                                    isOn={menuDetails.settings.alwaysActive}
                                    handleToggle={handleToggle}
                                />
                            </div>
                        </div>
                        {!menuDetails.settings.alwaysActive &&
                            (
                                // <>
                                //     <div className='flex flex-col items-start gap-2 my-1'>
                                //         <span className='font-bold'>Giorni Attivi</span>
                                //         <div className='flex flex-row items-center flex-wrap'>
                                //             <div className='flex flex-row items-center flex-wrap'>
                                //                 {weekDays.map((day, index) => (
                                //                     <div key={index}>
                                //                         <input
                                //                             type="checkbox"
                                //                             checked={menuDetails.settings.daysActive.includes(index)}
                                //                             className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"
                                //                             value={index}
                                //                             name="settings.daysActive"
                                //                             onChange={handleInputChange}
                                //                         />
                                //                         <span className="me-2 text-gray-700">{day}</span>
                                //                     </div>
                                //                 ))}
                                //             </div>


                                //         </div>
                                //     </div>
                                //     <div className='flex flex-row gap-2 my-1 items-center '>
                                //         <span className='font-bold'>Dalle</span>
                                //         <input
                                //             type="time"
                                //             className='bg-transparent flex-grow'
                                //             value={menuDetails.settings.timeSlot.startTime}
                                //             name="settings.timeSlot.startTime"
                                //             onChange={handleInputChange}
                                //         />
                                //         <span className='font-bold'>Alle</span>
                                //         <input
                                //             type="time"
                                //             className='bg-transparent flex-grow'
                                //             value={menuDetails.settings.timeSlot.endTime}
                                //             name="settings.timeSlot.endTime"
                                //             onChange={handleInputChange}
                                //         />
                                //     </div>

                                // </>


                                <TimeSlots
                                    timeSlots={menuDetails.settings.timeSlots}
                                    handleDayToggle={handleDayToggle}
                                    handleTimeSlotChange={handleTimeSlotChange}
                                    handleRemoveTimeSlot={handleRemoveTimeSlot}
                                    handleAddTimeSlot={handleAddTimeSlot}
                                />
                            )
                        }
                    </div>

                    <hr className="border-gray-400 mt-4 mb-4 w-full"></hr>

                    <ColorSection
                        handleColorChange={handleColorChange}
                        deleteColor={deleteColor}
                        color={color}
                    ></ColorSection>


                    <ImageSection
                        handleRemoveImage={handleRemoveImage}
                        setUploadedFileName={setUploadedFileName}
                        aspect={3 / 1}
                        maxSizeMB={0.3}
                        maxWidthOrHeight={1920}
                    ></ImageSection>


                    <hr className="border-gray-400 mt-4"></hr>

                </Modal>
            }
        </>
    )
}

export default CreateMenu