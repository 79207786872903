import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import imageCompression from 'browser-image-compression';
// import { canvasPreview } from '../../utils/canvasPreview';
// import { useDebounceEffect } from '../../utils/useDebounceEffect';

import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCropper({ imgSrc, onCropComplete, onCancel, aspect, maxSizeMB, maxWidthOrHeight }) {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);


  function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext('2d');
  
    if (!ctx) {
      throw new Error('No 2d context');
    }
  
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;
    const canvasWidth = Math.floor(crop.width * scaleX * pixelRatio);
    const canvasHeight = Math.floor(crop.height * scaleY * pixelRatio);
  
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
  
    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';
  
    ctx.save();
  
    // Move the canvas' origin to the center of the crop
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.scale(scale, scale);
    ctx.rotate((rotate * Math.PI) / 180);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);
  
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height,
    );
  
    ctx.restore();
  }

  function useDebounceEffect(fn, waitTime, deps) {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps);
      }, waitTime);
  
      return () => {
        clearTimeout(t);
      };
    }, deps);
  }
  

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleCropImage = async () => {
    if (!imgRef.current || !completedCrop || !previewCanvasRef.current) {
      return;
    }

    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = completedCrop.width * scaleX;
    canvas.height = completedCrop.height * scaleY;

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Convert the canvas to a Blob
    canvas.toBlob(async (blob) => {
      if (!blob) {
        console.error('Canvas is empty');
        return;
      }

      // Compress the image using browser-image-compression
      const options = {
        maxSizeMB: maxSizeMB,
        maxWidthOrHeight: maxWidthOrHeight,
        useWebWorker: true,
      };

      try {
        const compressedBlob = await imageCompression(blob, options);
        const croppedImageUrl = URL.createObjectURL(compressedBlob);
        onCropComplete(croppedImageUrl);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    }, 'image/jpeg');
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-4 w-11/12 md:w-1/2">
        <h2 className="text-xl font-bold mb-4">Ritaglia l'immagine</h2>
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
        <div className="flex justify-end mt-4">
          <button onClick={handleCropImage} className="green-background text-white py-2 px-4 rounded mr-2">
            Ritaglia
          </button>
        </div>
        <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
      </div>
    </div>
  );
}
