import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIngredients, updateIngredient } from '../redux/ingredientsSlice';
import {getTranslatedIngredients, updateIngredientTranslation, updateIngredientTranslationupdateIngredientTranslation} from '../redux/translatedIngredientsSlice';
import { createIngredient } from '../redux/ingredientsSlice';
import {createIngredientTranslation} from '../redux/translatedIngredientsSlice';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';

const IngredientFormComponent = ({showIngredientCreation, setShowIngredientCreation, menuDefaultLanguage, refreshIngredientMenuDefaultLanguage}) => {
    
    const [translatedIngredientsByLanguage, setTranslatedIngredientsByLanguage] = useState({});
    const [updatingIngredientId, setUpdatingIngredientId] = useState(null);
    console.log('UPDATING ID', updatingIngredientId)
    const [updatingTranslatedIngredientId, setUpdatingTranslatedIngredientId] = useState(null);
    console.log('UPDATING TRANSLATION ID', updatingTranslatedIngredientId)


    const ingredientTypesMapping = [
      { eng: 'meat', cmn: '肉', spa: 'carne', fra: 'viande', deu: 'Fleisch', jpn: '肉', rus: 'мясо', por: 'carne', ara: 'لحم', nld: 'vlees', swe: 'kött', ita: 'carne' },
      { eng: 'vegan', cmn: '纯素', spa: 'vegano', fra: 'végétalien', deu: 'Vegan', jpn: 'ビーガン', rus: 'веган', por: 'vegano', ara: 'نباتي', nld: 'veganistisch', swe: 'vegan', ita: 'vegano' },
      { eng: 'vegetarian', cmn: '素食', spa: 'vegetariano', fra: 'végétarien', deu: 'Vegetarier', jpn: 'ベジタリアン', rus: 'вегетарианец', por: 'vegetariano', ara: 'نباتي', nld: 'vegetarisch', swe: 'vegetarisk', ita: 'vegetariano' },
      { eng: 'fish', cmn: '鱼', spa: 'pescado', fra: 'poisson', deu: 'Fisch', jpn: '魚', rus: 'рыба', por: 'peixe', ara: 'سمك', nld: 'vis', swe: 'fisk', ita: 'pesce' }
  ];

    const loadIngredientsAndTranslations = async () => {
        await dispatch(getIngredients());
        console.log('Ingredienti in inglese dispatchati');
    
        const languages = ['ita', 'fra', 'spa', 'ara', 'cmn', 'jpn', 'rus', 'por', 'nld', 'swe', 'deu'];
    
        languages.forEach(async (language) => {
          const translatedIngredientsResponse = await dispatch(getTranslatedIngredients(language));
          const translatedIngredients = translatedIngredientsResponse.payload; // Assumi che il payload contenga gli ingredienti tradotti
    
          setTranslatedIngredientsByLanguage((prevState) => ({
            ...prevState,
            [language]: translatedIngredients
          }));
        });
      };

    useEffect(() => {
        loadIngredientsAndTranslations();
    }, []);
    
    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loadingUpdateTranslation, setLoadingUpdateTranslation] = useState(false);
    const [error, setError] = useState(false);
    const [errorUpdating, setErrorUpdating] = useState(false);
    const [errorUpdatingTranslation, setErrorUpdatingTranslation] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successUpdating, setSuccessUpdating] = useState(false);
    const [successUpdatingTranslation, setSuccessUpdatingTranslation] = useState(false);
    const isLoading = useSelector((state)=> state.ingredients.ingredientCreationIsLoading);
    const errorMessage = useSelector((state) => state.ingredients.ingredientCreationError);
    const successMessage = useSelector((state)=> state.ingredients.ingredientCreationSuccess);
    const isLoadingTranslation = useSelector((state)=> state.translatedIngredients.translatedIngredientCreationIsLoading);
    const errorMessageTranslation = useSelector((state) => state.translatedIngredients.translatedIngredientCreationError);
    const successMessageTranslation = useSelector((state)=> state.translatedIngredients.translatedIngredientCreationSuccess);
    const successUpdate = useSelector((state)=> state.ingredients.ingredientUpdateSuccess);
    const isLoadingUpdate = useSelector((state)=> state.ingredients.ingredientUpdateIsLoading);
    const errorUpdate = useSelector((state) => state.ingredients.ingredientUpdateError);
    const successUpdateTranslation = useSelector((state)=> state.translatedIngredients.ingredientTranslatedUpdateSuccess);
    const isLoadingUpdateTranslation = useSelector((state)=> state.translatedIngredients.ingredientTranslatedUpdateIsLoading);
    const errorUpdateTranslation = useSelector((state)=> state.translatedIngredients.ingredientTranslatedUpdateError);
    const [ingredientData, setIngredientData] = useState({
        name: '',
        //type: '',
        //kcal: ''
    });
    

    console.log('MESAGGIO SUCCESSO UPDATE ING TRADOTTO',successUpdateTranslation)

    const ingredients = useSelector((state)=> state.ingredients?.ingredientsOwner?.ingredients);
    //const translatedIngredients = useSelector((state)=> state.translatedIngredients?.translatedIngredientsOwner?.ingredientTranslations);
    //console.log('INGREDIENTI TRADOTTI', translatedIngredients)

    const flagImages = {
        ita: itaFlag,
        fra: fraFlag,
        spa: spaFlag,
        eng: engFlag,
        ara: araFlag,
        cmn: cmnFlag,
        jpn: jpnFlag,
        rus: rusFlag,
        por: porFlag,
        nld: nldFlag,
        swe: sweFlag,
        deu: deuFlag
    };
  
    console.log(ingredientData)
    console.log('INGREDIENTI TRADOTTI PER LINGUA', translatedIngredientsByLanguage)
  
    const dispatch = useDispatch();
  
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setIngredientData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
  
    const handleCloseCreateIngredient = () => {
        setShowIngredientCreation(false);
        refreshIngredientMenuDefaultLanguage(menuDefaultLanguage);
    };
    
    const [modifiedIngredients, setModifiedIngredients] = useState({});
    const [modifiedTranslatedIngredients, setModifiedTranslatedIngredients] = useState({});

    console.log('INGREDIENTI MODIFICATO',modifiedIngredients)
    console.log('INGREDIENTI TRADOTTO',modifiedTranslatedIngredients)

    const handleInputUpdateChange = (event, ingredientId) => {
      const { name, value } = event.target;
      setModifiedIngredients(prevState => ({
          ...prevState,
          [ingredientId]: {
              ...prevState[ingredientId] ,
              [name]: value
          }
      }));
    };

    const handleInputUpdateTranslationChange = (event, ingredientTranslatedId) => {
      const { name, value } = event.target;
      setModifiedTranslatedIngredients(prevState => ({
          ...prevState,
          [ingredientTranslatedId]: {
              ...prevState[ingredientTranslatedId] ,
              [name]: value
          }
      }));
    };
  

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("handleSubmit chiamata");
    
        try {
            setLoading(true);
            const response = await dispatch(createIngredient(ingredientData));
            console.log('Response from createIngredient:', response);
    
            // Assicurati che questa verifica sia corretta per il tuo caso d'uso
            if (createIngredient.fulfilled.match(response)) {
                const ingredientId = response.payload.savedIngredient._id; // Usa `response.payload` se necessario
    
                if (ingredientId && menuDefaultLanguage !== 'eng') {
                    console.log('SONO QUI')
                    const languages = ['ita', 'fra', 'spa', 'ara', 'cmn', 'jpn', 'rus', 'por', 'nld', 'swe', 'deu'];

                    const createTranslationForLanguage = async (language) => {
                      const newPayload = {
                          ingredientId: ingredientId,
                          language: language,
                          translatedName: ingredientData.name 
                      };
                      const translationResponse = await dispatch(createIngredientTranslation(newPayload));
                      console.log(`Traduzione per ${language} creata con successo`, translationResponse);
                      setSuccess(true);
                      await loadIngredientsAndTranslations();
                    };

                    for (const language of languages) {
                      await createTranslationForLanguage(language);
                    }
                    
                } else {
                    console.log('INGREDIENTE CREATO SENZA TRADUZIONE');
                    setSuccess(true);
                    await loadIngredientsAndTranslations();
                }
            } else {
                console.log('Errore nella creazione dell\'ingrediente');
                setError(true);
            }
        } catch (error) {
            console.error('Error creating ingredient:', error);
            setError(true);
        } finally {
            setLoading(false); 
            setTimeout(() => {
              setSuccess(false);
            }, 3000);
            setIngredientData({
              name: '',
              //type: '',
              //kcal: ''
            })
        }
    };

    const handleUpdate = async (event, ingredientId, updatedData) =>{
      event.preventDefault();

      try {
        setErrorUpdating(false)
        setSuccessUpdating(false)
        setLoadingUpdate(true)
        setUpdatingIngredientId(ingredientId); 
        const response = await dispatch(updateIngredient({ ingredientId, dataToUpdate: updatedData }));
        if (updateIngredient.fulfilled.match(response)){
          setLoadingUpdate(false)
          setSuccessUpdating(true)
          console.log('AGGIORNATO INGREDIENTE ENG')
          await loadIngredientsAndTranslations();
          setTimeout(() => {
            setUpdatingIngredientId(null);
          }, 3000);
        } else {
          setLoadingUpdate(false)
          setErrorUpdating(true)
        }
        console.log('Response from updateIngredient:', response);
      } catch (error) {
        setLoadingUpdate(false)
        setErrorUpdating(true)
        console.error('Error creating ingredient:', error);
      }
    }

    const handleUpdateTranslation = async (event, ingredientTranslatedId, updatedData) =>{
      event.preventDefault();

      try {
        setUpdatingTranslatedIngredientId(ingredientTranslatedId);
        setErrorUpdatingTranslation(false)
        setSuccessUpdatingTranslation(false)
        setLoadingUpdateTranslation(true)
        const response = await dispatch(updateIngredientTranslation({ ingredientTranslatedId, dataToUpdate: updatedData }));
        if (updateIngredientTranslation.fulfilled.match(response)){
          setLoadingUpdateTranslation(false)
          setSuccessUpdatingTranslation(true)
          console.log('AGGIORNATO INGREDIENTE TRADOTTO')
          await loadIngredientsAndTranslations();
          console.log('Response from updateIngredientTranslation:', response);
          setTimeout(() => {
            setUpdatingTranslatedIngredientId(null);
          }, 3000);
        } else {
          setLoadingUpdateTranslation(false)
          setErrorUpdatingTranslation(true)
        }
      } catch (error) {
        console.error('Error creating ingredient:', error);
        setLoadingUpdateTranslation(false)
        setErrorUpdatingTranslation(true)
      }
    }
    
    return (
<>
  {showIngredientCreation && (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-90 flex items-center justify-center z-10 overflow-auto">
      <div className='font-primary form text-black text-center bg-gray-300 border lg:h-5/6 max-h-[80vh] rounded-lg w-full lg:w-11/12 xl:w-10/12 2xl:w-9/12 overflow-auto'>
        <div className='text-black font-primary font-bold p-4'>Creazione Ingrediente</div>
          <div className='flex flex-col items-center p-2'>
            <img src={flagImages[menuDefaultLanguage]} alt={`${menuDefaultLanguage} flag`} className="w-20 rounded-lg" />
            <input
              type="text"
              placeholder="Nome"
              className="border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
              name='name'
              value={ingredientData.name}
              onChange={handleInputChange}
            />
            {/*<select
              className='border rounded-lg border-gray-400 p-2 bg-transparent mt-2'
              name='type'
              value={ingredientData.type}
              onChange={handleInputChange}
            >
              <option value="" disabled>Seleziona una tipologia</option>
              <option value="meat">Carne</option>
              <option value="fish">Pesce</option>
              <option value="vegetarian">Vegetariano</option>
              <option value="vegan">Vegano</option>
            </select>
            <input
              type="text"
              placeholder="kcal"
              name="kcal"
              className="border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
              value={ingredientData.kcal}
              onChange={handleInputChange}
  />*/}
            <div className='flex flex-row justify-center gap-2 mt-4 p-4'>
            {!loading &&
            <>
            <button className="mt-4 p-2 font-primary w-40 rounded-lg black-background text-center text-white text-sm" onClick={handleCloseCreateIngredient}>Chiudi</button>
            <span onClick={handleSubmit} className="cursor-pointer mt-4 p-2 font-primary w-40 rounded-lg green-background text-center text-white text-sm">Salva</span>
            </>
            }
            </div>
            {isLoading && loading &&
            <div className='flex flex-row justify-center gap-2 pt-2'><div className='custom-loader'></div></div>
            }
             {isLoadingTranslation && loading &&
            <div className='flex flex-row justify-center gap-2 pt-2'><div className='custom-loader'></div></div>
            }
             {success && successMessage &&
            <div className='flex flex-row justify-center gap-2 font-light text-emerald-400 p-2 text-xs'>{successMessage}</div>
            }
              {success && successMessageTranslation &&
            <div className='flex flex-row justify-center gap-2 font-light text-emerald-400 p-2 text-xs'>{successMessageTranslation}</div>
            }
            {error && errorMessage &&
            <div className='flex flex-row justify-center gap-2 font-light text-red-600 p-2 text-xs'>{errorMessage}</div>
            }
            {error && errorMessageTranslation &&
            <div className='flex flex-row justify-center gap-2 font-light text-red-600 p-2 text-xs'>{errorMessageTranslation}</div>
          }
          </div>
          <div className='flex flex-col items-center justify-center lg:flex-row lg:justify-around lg:items-start lg:flex-wrap gap-4 p-4'>
          <div className='w-full lg:w-auto overflow-auto p-2'>
            {ingredients?.filter(ingredient => !ingredient.verified).map(ingredient => (
              <div key={ingredient._id} className='flex flex-row items-center p-2 gap-2'>
                <div className='flex flex-col items-center p-2 gap-3 m-2'>
                  <img src={flagImages['eng']} alt={`eng`} className="w-10 rounded-lg" />
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      name="name"
                      className="w-20 border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                      value={modifiedIngredients[ingredient._id]?.name || ingredient.name}
                      onChange={(event) => handleInputUpdateChange(event, ingredient._id)}
                    />
                     {/*   <select
                          name="type"
                          className="w-20 border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                          value={modifiedIngredients[ingredient._id]?.type || ingredient.type}
                          onChange={(event) => handleInputUpdateChange(event, ingredient._id)}
                        >
                          {ingredientTypesMapping.map(type => (
                            <option value={type.eng}>{type.eng}</option> // Usa il valore in inglese o adatta in base alla lingua
                          ))}
                        </select>
                    <input
                      type="text"
                      name="kcal"
                      className="w-20 border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                      value={modifiedIngredients[ingredient._id]?.kcal || ingredient.kcal}
                      onChange={(event) => handleInputUpdateChange(event, ingredient._id)}
                          />*/}
                      {
                        ingredient._id === updatingIngredientId && (
                          isLoadingUpdate && loadingUpdate ? (
                            <div className='flex justify-center items-center'><div className='custom-loader'></div></div>
                          ) : successUpdating && successUpdate ? (
                            <div className="font-light text-emerald-400 p-2 text-xs">{successUpdate}</div>
                          ) : errorUpdating && errorUpdate ? (
                            <div className="font-light text-red-600 p-2 text-xs">{errorUpdate}</div>
                          ) : null
                      )
                      }
                      {!updatingIngredientId && (
                      <span className="cursor-pointer mt-4 p-2 font-primary w-20 rounded-lg black-background text-center text-white text-sm" onClick={(event) => handleUpdate(event, ingredient._id, modifiedIngredients[ingredient._id])} >Aggiorna</span>
                      )}
                  </div>
                </div>
                {Object.keys(flagImages).map(language => (
                  language !== 'eng' &&
                  <div 
                  //key={`${ingredient._id}-${language}`} 
                  key={translatedIngredientsByLanguage[language]?.ingredientTranslations?.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id }
                  className="flex flex-col items-center p-2 gap-1 m-2">
                    <div className='flex flex-col items-center p-2 gap-3 m-2'> 
                    <img src={flagImages[language]} alt={language} className="w-10 rounded-lg" />
                    <div className="flex flex-col items-center">
                      <input
                        type="text"
                        name="translatedName"
                        className="w-20 border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                        value={ modifiedTranslatedIngredients[translatedIngredientsByLanguage[language]?.ingredientTranslations.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id]?.translatedName || translatedIngredientsByLanguage[language]?.ingredientTranslations?.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?.translatedName }
                        onChange={(event) => handleInputUpdateTranslationChange(event, translatedIngredientsByLanguage[language]?.ingredientTranslations?.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id )}
                        
                      />
                       {/* <select
                          name="translatedType"
                          className="w-20 border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                          value={ modifiedTranslatedIngredients[translatedIngredientsByLanguage[language]?.ingredientTranslations.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id]?.translatedType || translatedIngredientsByLanguage[language]?.ingredientTranslations?.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?.translatedType }
                          onChange={(event) => handleInputUpdateTranslationChange(event, translatedIngredientsByLanguage[language]?.ingredientTranslations?.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id)} // L'ID corretto per il caso tradotto
                        >
                          {ingredientTypesMapping.map(type => (
                            <option value={type[language]}>{type[language]}</option> // Mappa usando la lingua selezionata
                          ))}
                        </select>
                       <input
                        type="text"
                        name="kcal"
                        className="w-20 border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                        value={modifiedTranslatedIngredients[translatedIngredientsByLanguage[language]?.ingredientTranslations.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id]?.kcal || translatedIngredientsByLanguage[language]?.ingredientTranslations?.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?.kcal}
                        onChange={(event) => handleInputUpdateTranslationChange(event, translatedIngredientsByLanguage[language]?.ingredientTranslations?.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id )}
                          />*/}
                      {
                        translatedIngredientsByLanguage[language]?.ingredientTranslations.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id === updatingTranslatedIngredientId && (
                          isLoadingUpdateTranslation && loadingUpdateTranslation ? (
                            <div className='flex justify-center items-center'><div className='custom-loader'></div></div>
                          ) : successUpdatingTranslation && successUpdateTranslation ? (
                            <div className="font-light text-emerald-400 p-2 text-xs">{successUpdateTranslation}</div>
                          ) : errorUpdatingTranslation && errorUpdateTranslation ? (
                            <div className="font-light text-red-600 p-2 text-xs">{errorUpdateTranslation}</div>
                          ) : null
                        )
                      }
                          {!(updatingTranslatedIngredientId === translatedIngredientsByLanguage[language]?.ingredientTranslations.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id) &&  (
                             <span className="cursor-pointer mt-4 p-2 font-primary w-20 rounded-lg black-background text-center text-white text-sm" 
                             onClick={(event) => 
                               handleUpdateTranslation(
                               event, 
                               translatedIngredientsByLanguage[language]?.ingredientTranslations?.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id, 
                               modifiedTranslatedIngredients[translatedIngredientsByLanguage[language]?.ingredientTranslations?.find(translated => translated.ingredientId === ingredient._id && translated.language === language)?._id])
                             }>Aggiorna</span>
                          )}
                    </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )}
</>

      )
}

export default IngredientFormComponent