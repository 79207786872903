import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImageCropper from './ImageCropper';

const ImageSection = ({ children, inputImage, handleRemoveImage, setUploadedFileName, aspect, maxSizeMB, maxWidthOrHeight }) => {
  const url = process.env.REACT_APP_SERVER_BASE_URL;
  const [uploadMessage, setUploadMessage] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const [isCropperOpen, setIsCropperOpen] = useState(false);
//   const [aspect, setAspect] = useState(1 / 1);
//   const [maxSizeMB, setMaxSizeMB] = useState(0.2)
//   const [maxWidthOrHeight, setMaxWidthOrHeight] = useState(200)

  const handleAddImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setIsCropperOpen(true);
    }
  };

  const handleCropComplete = async (croppedImageUrl) => {
    try {
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const formData = new FormData();
      formData.append('menu', blob);

      let uploadResponse;
      if (!inputImage) {
        uploadResponse = await axios.post(`${url}/upload-menu`, formData);
      } else {
        uploadResponse = await axios.post(`${url}/update-menu/${inputImage}`, formData);
      }

      if (uploadResponse.status === 200) {
        const uploadedFileName = uploadResponse.data.url.split('/').pop();
        setUploadedFileName(uploadedFileName);
        setUploadMessage(true);
        console.log('uploadedFileName', uploadedFileName);
        setImagePreview(croppedImageUrl); // Update the image preview with the cropped image
        setIsCropperOpen(false);
      } else {
        setUploadError(true);
        console.error('Error uploading file:', uploadResponse);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadError(true);
    }
  };

  useEffect(() => {
    if (inputImage) {
      axios
        .get(`${url}/imageurl/${inputImage}`)
        .then((response) => {
          if (response.status === 200 && response.data.url) {
            setImagePreview(response.data.url);
          } else {
            console.error('Failed to retrieve the signed URL:', response);
          }
        })
        .catch((error) => {
          console.error('Error fetching the signed image URL:', error);
        });
    }
  }, [inputImage]);

  const handleRemoveImageAndPreview = () => {
    setImagePreview('');
    handleRemoveImage();
  };

  return (
    <>
      <div className="text-left w-full mt-4">
        <span className="font-bold">{children || 'Immagine'}</span>
      </div>

      <input
        id="fileInput"
        type="file"
        className="w-full input rounded-lg border-gray-200 bg-white border p-2 text-black text-sm"
        onChange={handleAddImage}
      />

      {imagePreview && !isCropperOpen && (
        <div className="relative flex mt-6">
          <div className="relative">
            <img src={imagePreview} alt="Anteprima" className="w-96 rounded-lg" />
            <button
              onClick={handleRemoveImageAndPreview}
              className="absolute top-0 right-0 font-primary bg-red-500/70 text-white rounded-full w-6 h-6 p-1 text-xs transform translate-x-1/2 -translate-y-1/2"
            >
              X
            </button>
          </div>
        </div>
      )}

      {isCropperOpen && (
        <ImageCropper
          imgSrc={imagePreview}
          onCropComplete={handleCropComplete}
          onCancel={() => setIsCropperOpen(false)}
          aspect={aspect || 2 / 1}
          maxSizeMB={maxSizeMB || 0.2}
          maxWidthOrHeight={maxWidthOrHeight || 1920}
        />
      )}

      {uploadMessage && (
        <span className="font-light text-sm text-emerald-400 ">Upload Success</span>
      )}
      {uploadError && (
        <span className="font-light text-sm text-red-600 ">Upload Error</span>
      )}
    </>
  );
};

export default ImageSection;
