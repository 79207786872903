import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { createMenuItem } from '../redux/menusSlice';
import { FiEdit } from 'react-icons/fi';
import CreateMenuItemTranslations from './CreateMenuItemTranslations';
import { getIngredients } from '../redux/ingredientsSlice';
import { getTranslatedIngredients } from '../redux/translatedIngredientsSlice'
import CreateIngredient from './CreateIngredient';
import { SketchPicker } from 'react-color';
import AWS from 'aws-sdk';
import axios from 'axios';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import TranslationsNew from './TranslationsNew'
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';


const CreateMenuItem = ({ owner, menu, menuId, categoryId, showCreateMenuItem, setShowCreateMenuItem, refreshAllMenusOwner, refreshMenuOwner, menuDefaultLanguage }) => {

    const url = process.env.REACT_APP_SERVER_BASE_URL
    const [showCreateMenuItemTranslations, setShowCreateMenuItemTranslations] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [translations, setTranslations] = useState([])
    const isLoading = useSelector((state) => state.menus.menuItemCreationIsLoading);
    const errorMessage = useSelector((state) => state.menus.menuItemCreationError);
    const successMessage = useSelector((state) => state.menus.menuItemCreationSuccess);
    const ingredients = useSelector((state) => state.ingredients?.ingredientsOwner?.ingredients);
    const translatedIngredients = useSelector((state) => state.translatedIngredients?.translatedIngredientsOwner?.ingredientTranslations);
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [showCreateIngredient, setShowCreateIngredient] = useState(false);
    const [forceHideSaveButton, setForceHideSaveButton] = useState(false)
    const categoryIsComplete = menu.menu.menuCategory.find((category) => category._id === categoryId).isComplete

    const [outputTranslations, setOutputTranslations] = useState([])
    const defaultLanguage = menu.defaultLanguage;
    const [formattedTranslations, setFormattedTranslations] = useState([]);

    const inputTranslations = menu.menu.translations.length > 0 ?
        menu.menu.translations.map(lang => ({
            language: lang.language,
            translatedName: '',
            translatedDescription: ''
        })) : [];


    useEffect(() => {

        const formattedTranslations = outputTranslations.map(lang => ({
            language: lang.language,
            translatedName: lang.translatedName,
            translatedDescription: lang.translatedDescription
        }))

        setItemDetails(prevItemDetails => ({
            ...prevItemDetails,
            translations: formattedTranslations || [],
        }
        ));

    }, [outputTranslations])



    const handleShowCreateIngredient = () => {
        setShowCreateIngredient(true)
    }



    const allergensMapping = [
        { eng: 'gluten', cmn: '麸质', spa: 'gluten', fra: 'gluten', deu: 'Gluten', jpn: 'グルテン', rus: 'глютен', por: 'glúten', ara: 'غلوتين', nld: 'gluten', swe: 'gluten', ita: 'glutine' },
        { eng: 'shellfish', cmn: '壳fish', spa: 'mariscos', fra: 'crustacés', deu: 'Schalentiere', jpn: '甲殻類', rus: 'ракообразные', por: 'crustáceos', ara: 'قشريات', nld: 'schaaldieren', swe: 'skaldjur', ita: 'crostacei' },
        { eng: 'eggs', cmn: '蛋', spa: 'huevos', fra: 'œufs', deu: 'Eier', jpn: '卵', rus: 'яйца', por: 'ovos', ara: 'بيض', nld: 'eieren', swe: 'ägg', ita: 'uova' },
        { eng: 'fish', cmn: '鱼', spa: 'pescado', fra: 'poisson', deu: 'Fisch', jpn: '魚', rus: 'рыба', por: 'peixe', ara: 'سمك', nld: 'vis', swe: 'fisk', ita: 'pesce' },
        { eng: 'peanuts', cmn: '花生', spa: 'cacahuetes', fra: 'arachides', deu: 'Erdnüsse', jpn: 'ピーナッツ', rus: 'арахис', por: 'amendoins', ara: 'فول سوداني', nld: 'pinda', swe: 'jordnötter', ita: 'arachidi' },
        { eng: 'soy', cmn: '大豆', spa: 'soja', fra: 'soja', deu: 'Soja', jpn: '大豆', rus: 'соя', por: 'soja', ara: 'صويا', nld: 'soja', swe: 'soja', ita: 'soia' },
        { eng: 'milk', cmn: '牛奶', spa: 'leche', fra: 'lait', deu: 'Milch', jpn: '牛乳', rus: 'молоко', por: 'leite', ara: 'حليب', nld: 'melk', swe: 'mjölk', ita: 'latte' },
        { eng: 'nuts', cmn: '坚果', spa: 'frutos secos', fra: 'noix', deu: 'Nüsse', jpn: 'ナッツ', rus: 'орехи', por: 'nozes', ara: 'مكسرات', nld: 'noten', swe: 'nötter', ita: 'frutta a guscio' },
        { eng: 'celery', cmn: '芹菜', spa: 'apio', fra: 'céleri', deu: 'Sellerie', jpn: 'セロリ', rus: 'сельдерей', por: 'aipo', ara: 'كرفس', nld: 'selderij', swe: 'selleri', ita: 'sedano' },
        { eng: 'mustard', cmn: '芥末', spa: 'mostaza', fra: 'moutarde', deu: 'Senf', jpn: 'マスタード', rus: 'горчица', por: 'mostarda', ara: 'خردل', nld: 'mosterd', swe: 'senap', ita: 'senape' },
        { eng: 'sesame', cmn: '芝麻', spa: 'sésamo', fra: 'sésame', deu: 'Sesam', jpn: 'ゴマ', rus: 'кунжут', por: 'sésamo', ara: 'سمسم', nld: 'sesam', swe: 'sesam', ita: 'sesamo' },
        { eng: 'sulfur dioxide', cmn: '二氧化硫', spa: 'dióxido de azufre', fra: 'dioxyde de soufre', deu: 'Schwefeldioxid', jpn: '二酸化硫黄', rus: 'диоксид серы', por: 'dióxido de enxofre', ara: 'ثاني أكسيد الكبريت', nld: 'zwaveldioxide', swe: 'svaveldioxid', ita: 'anidride solforosa' },
        { eng: 'lupins', cmn: '羽扇豆', spa: 'lupins', fra: 'lupins', deu: 'Lupinen', jpn: 'ルピナス', rus: 'люпины', por: 'tremoços', ara: 'ترمس', nld: 'lupine', swe: 'lupiner', ita: 'lupini' },
        { eng: 'molluscs', cmn: '软体动物', spa: 'moluscos', fra: 'mollusques', deu: 'Weichtiere', jpn: '軟体動物', rus: 'моллюски', por: 'moluscos', ara: 'رخويات', nld: 'weekdieren', swe: 'blötdjur', ita: 'molluschi' },
        { eng: 'no allergens', cmn: '无过敏原', spa: 'sin alérgenos', fra: 'sans allergènes', deu: 'keine Allergene', jpn: 'アレルゲンなし', rus: 'без аллергенов', por: 'sem alérgenos', ara: 'بدون مسببات الحساسية', nld: 'geen allergenen', swe: 'inga allergener', ita: 'nessun allergene' }
    ];


    const [itemDetails, setItemDetails] = useState({
        menuItem: {
            foodOrBeverage: 'food',
            name: '',
            description: '',
            color: '',
            image: '',
            price: '',
            isActive: true,
            isVegetarian: false,
            isVegan: false,
            isMeat: false,
            isFish: false,
            translatedIngredients: [],
            ingredients: [],
            allergens: []
        }
    });


    const [uploadedFileName, setUploadedFileName] = useState();


    useEffect(() => {

        setItemDetails(prevState => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                image: uploadedFileName
            }
        }));

    }, [uploadedFileName])



    const handleRemoveImage = () => {

        setItemDetails(prevState => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                image: ''
            }
        }));
    }


    useEffect(() => {
        if (menuDefaultLanguage === 'eng') {
            dispatch(getIngredients())
        } else {
            dispatch(getTranslatedIngredients(menuDefaultLanguage))
        }
    }, []);

    const refreshIngredientMenuDefaultLanguage = () => {
        dispatch(getTranslatedIngredients(menuDefaultLanguage))
    }


    // Trova ingredienti basati sull'input dell'utente
    const findIngredients = (inputValue) => {
        // setInputValue non è necessario se stai usando solo react-select
        // setInputValue(inputValue);

        let filteredOptions;
        if (menuDefaultLanguage === "eng") {
            filteredOptions = ingredients.filter(ingredient =>
                ingredient.name.toLowerCase().includes(inputValue.toLowerCase())
            );
        } else {
            filteredOptions = translatedIngredients.filter(ingredient =>
                ingredient.translatedName.toLowerCase().includes(inputValue.toLowerCase())
            );
        }

        // Converti i risultati filtrati in opzioni per react-select
        return filteredOptions?.map(ingredient => ({
            value: ingredient._id,
            label: menuDefaultLanguage === "eng" ? ingredient.name : ingredient.translatedName
        }));
    };


    const ingredientOptions = menuDefaultLanguage === 'eng' ?
        ingredients?.map(ingredient => ({ value: ingredient._id, label: ingredient.name })) :
        translatedIngredients?.map(ingredient => ({ value: ingredient._id, label: ingredient.translatedName }));




    const selectIngredient = (selectedOptions) => {
        // selectedOptions è un array di oggetti con { value, label }
        const selectedIds = selectedOptions?.map(option => option.value);

        setItemDetails(prev => ({
            ...prev,
            menuItem: {
                ...prev.menuItem,
                [menuDefaultLanguage === 'eng' ? 'ingredients' : 'translatedIngredients']: selectedIds
            }
        }));
    };



    const allergenOptions = allergensMapping.map(allergen => ({
        value: allergen[menuDefaultLanguage],
        label: allergen[menuDefaultLanguage]
    }));



    const handleAllergenChange = (selectedOptions) => {
        // selectedOptions è un array di oggetti con { value, label }
        const selectedAllergenValues = selectedOptions.map(option => option.value);

        setItemDetails(prev => ({
            ...prev,
            menuItem: {
                ...prev.menuItem,
                allergens: selectedAllergenValues
            }
        }));
    };



    const handleShowCreateMenuItemTranslations = () => {
        setShowCreateMenuItemTranslations(true)
    }

    const [imageFileName, setImageFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadMessage, setUploadMessage] = useState(false)
    const [uploadError, setUploadError] = useState(false)


    const handleFileInput = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }


        const formData = new FormData();
        formData.append('menuItem', file);

        for (let pair of formData.entries()) {
        }

        try {

            const response = await axios.post(`${url}/upload-menuitem`, formData);

            const uploadedFileName = response.data.url.split('/').pop();
            setItemDetails(prevState => ({
                ...prevState,
                menuItem: {
                    ...prevState.menuItem,
                    image: uploadedFileName
                }
            }));
            //    setImagePreview(URL.createObjectURL(file));
            setUploadMessage(true);
        } catch (error) {
            setUploadError(true);
        }
    };

    const [showPicker, setShowPicker] = useState(false)
    const handleColorPickerOpen = () => setShowPicker(true);
    const handleColorPickerClose = () => setShowPicker(false);
    const [color, setColor] = useState('');


    const handleColorChange = (color) => {
        setColor(color.hex);
        setItemDetails(prevState => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                color: color.hex
            }
        }));
    };

    const deleteColor = () => {
        setColor('');
        setItemDetails(prevState => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                color: ''
            }
        }));
    }


    const closeCreateMenuItem = () => {
        setShowCreateMenuItem(false)
    }

    const dispatch = useDispatch();

    const flagImages = {
        ita: itaFlag,
        fra: fraFlag,
        spa: spaFlag,
        eng: engFlag,
        ara: araFlag,
        cmn: cmnFlag,
        jpn: jpnFlag,
        rus: rusFlag,
        por: porFlag,
        nld: nldFlag,
        swe: sweFlag,
        deu: deuFlag
    };


    /*const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const [category, key] = name.split('.'); // Ad esempio, "menuCategory.name"
    
        const inputValue = type === 'checkbox' ? checked : value;
    
        setItemDetails(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [key]: inputValue
            }
        }));
    };*/

    //modifica handleInputChange per gestire anche i campi checkbox delle impostazioni
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        // Dividi il nome per ottenere il percorso dell'oggetto nello stato
        const [category, key] = name.split('.'); // Ad esempio, "menuItem.isActive"

        // Per gli input checkbox, usa `checked`, altrimenti `value`
        const inputValue = type === 'checkbox' ? checked : value;

        setItemDetails(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [key]: inputValue
            }
        }));
    };





    const handleSubmit = async (event) => {
        setLoading(true)
        setError(false)
        setSuccess(false)
        event.preventDefault();
        try {
            if (itemDetails.menuItem.foodOrBeverage === 'beverage') {
                let itemDetailsToEdit = { ...itemDetails };
                delete itemDetailsToEdit.menuItem.ingredients;
                delete itemDetailsToEdit.menuItem.translatedIngredients;
                delete itemDetailsToEdit.menuItem.allergens;

                if (formattedTranslations) {
                    const completeItemDetails = { menuId, categoryId, ...itemDetailsToEdit, formattedTranslations }
                    const response = await dispatch(createMenuItem(completeItemDetails))
                    //setLoading(true);
                    if (createMenuItem.fulfilled.match(response)) {
                        setLoading(false);
                        setSuccess(true);
                        if (error) {
                            setError(false)
                        }
                        setForceHideSaveButton(true)
                        setTimeout(() => {
                            refreshMenuOwner(menuId)
                            refreshAllMenusOwner(); // Se necessario, aggiorna i menu
                            closeCreateMenuItem(); // Chiudi il form di modifica
                        }, 2000);
                    } else {
                        setLoading(false)
                        setError(true)
                    }
                } else {
                    const response = await dispatch(createMenuItem(menuId, categoryId, ...itemDetailsToEdit))
                    //setLoading(true);
                    if (createMenuItem.fulfilled.match(response)) {
                        setLoading(false);
                        setSuccess(true);
                        if (error) {
                            setError(false)
                        }
                        setForceHideSaveButton(true)
                        setTimeout(() => {
                            refreshMenuOwner(menuId)
                            refreshAllMenusOwner(); // Se necessario, aggiorna i menu
                            closeCreateMenuItem(); // Chiudi il form di modifica
                        }, 2000);
                    } else {
                        setLoading(false)
                        setError(true)
                    }
                }
            } else {
                if (formattedTranslations) {
                    const completeItemDetails = { menuId, categoryId, ...itemDetails, formattedTranslations }
                    const response = await dispatch(createMenuItem(completeItemDetails))
                    //setLoading(true);
                    if (createMenuItem.fulfilled.match(response)) {
                        setLoading(false);
                        setSuccess(true);
                        if (error) {
                            setError(false)
                        }
                        setForceHideSaveButton(true)
                        setTimeout(() => {
                            refreshMenuOwner(menuId)
                            refreshAllMenusOwner(); // Se necessario, aggiorna i menu
                            closeCreateMenuItem(); // Chiudi il form di modifica
                        }, 2000);
                    } else {
                        setLoading(false)
                        setError(true)
                    }
                } else {
                    const response = await dispatch(createMenuItem(menuId, categoryId, ...itemDetails))
                    //setLoading(true);
                    if (createMenuItem.fulfilled.match(response)) {
                        setLoading(false);
                        setSuccess(true);
                        if (error) {
                            setError(false)
                        }
                        setForceHideSaveButton(true)
                        setTimeout(() => {
                            refreshAllMenusOwner(); // Se necessario, aggiorna i menu
                            closeCreateMenuItem(); // Chiudi il form di modifica
                        }, 2000);
                    } else {
                        setLoading(false)
                        setError(true)
                    }
                }
            }
        } catch (error) {
        }
    };



    return (

        <>

            {showCreateMenuItem &&

                <Modal handleSubmit={handleSubmit}
                    closeFunction={closeCreateMenuItem}
                    title="Crea Piatto"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                >

                    <Input description={itemDetails.menuItem.name || ''}
                        name='menuItem.name'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Nome</Input>

                    <Input description={itemDetails.menuItem.description || ''}
                        name='menuItem.description'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Descrizione</Input>

                    <hr className="border-gray-400 mt-8"></hr>

                    <TranslationsNew
                        defaultLanguage={defaultLanguage}
                        inputTranslations={inputTranslations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></TranslationsNew>

                    <hr className="border-gray-400 mt-8"></hr>

                    <div className='mt-10 flex flex-col items-center gap-2 my-1'>
                        <div className='flex flex-row items-center flex-wrap text-xl '>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isActive}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"

                                name="menuItem.isActive"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Attivo</span>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isVegetarian}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"

                                name="menuItem.isVegetarian"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Vegetariano</span>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isVegan}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"

                                name="menuItem.isVegan"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Vegano</span>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isMeat}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"

                                name="menuItem.isMeat"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Carne</span>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isFish}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"

                                name="menuItem.isFish"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Pesce</span>
                        </div>
                    </div>

                    <hr className="border-gray-400 mt-8"></hr>

                    {/* <div className='flex flex-col lg:flex lg:flex-row lg:justify-around lg:items-center gap-5 mt-5'> */}
                    <div className="flex flex-row item-center mt-8">
                        <div className='flex flex-col items-start'>
                            {!categoryIsComplete && (
                                <div className='flex flex-col md:flex-row justify-between gap-2 items-center my-1'>
                                    <h3 className='font-bold'>Prezzo</h3>
                                    <div className=' p-2 w-40 flex flex-row justify-between items-center'>
                                        <input
                                            className='border rounded-lg border-gray-400 p-2 bg-transparent'
                                            value={itemDetails.menuItem.price}
                                            name='menuItem.price'
                                            onChange={handleInputChange}
                                            type='number'
                                            min="0"
                                            step="any"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <hr className="border-gray-400 mt-8"></hr>

                    {/* <div className='flex flex-col items-start'> */}
                    
                    <div className="z-40">

                    <ColorSection 
                        handleColorChange={handleColorChange}
                        deleteColor={deleteColor}
                        color={color}
                    ></ColorSection>

                    <ImageSection
                        handleRemoveImage={handleRemoveImage}
                        setUploadedFileName={setUploadedFileName}
                        aspect={2/1}
                        maxSizeMB={0.1}
                        maxWidthOrHeight={1200}
                    ></ImageSection>
                    </div>

                    <hr className="border-gray-400 mt-8"></hr>

                    <div className='flex flex-col items-start'>

                        <div className='flex flex-col md:flex-row items-center justify-center gap-2 my-1 mt-8'>
                            <span className='font-bold'>Food or beverage</span>
                            <select
                                className='border rounded-lg border-gray-400 p-2 bg-transparent'
                                name="menuItem.foodOrBeverage"
                                value={itemDetails.menuItem.foodOrBeverage}
                                onChange={handleInputChange}
                            >
                                <option value="food">Food</option>
                                <option value="beverage">Beverage</option>
                            </select>
                        </div>
                    </div>

                    {itemDetails.menuItem.foodOrBeverage === 'food' &&
                        <>
                            <div className='flex flex-col mt-4 '>
                                <div className='grid grid-cols-12 gap-2 items-center'>
                                    <h3 className='font-bold col-span-2 text-start'>Ingredienti</h3>
                                    <Select
                                        isMulti
                                        name="ingredients"
                                        options={ingredientOptions}
                                        className="basic-multi-select col-span-9"
                                        classNamePrefix="select"
                                        value={ingredientOptions?.filter(option =>
                                            itemDetails.menuItem[menuDefaultLanguage === 'eng' ? 'ingredients' : 'translatedIngredients'].includes(option.value)
                                        )}
                                        onChange={selectIngredient}
                                        placeholder="Cerca ingredienti..."
                                    />
                                    <button className=" font-primary w-8 h-8 rounded-lg green-background text-center text-white text-xl cursor-pointer col-span-1" onClick={(e) => {
                                        e.stopPropagation();
                                        handleShowCreateIngredient();
                                    }}>+</button>
                                </div>
                            </div>
                        </>
                    }
                    <div className='grid grid-cols-12 md:flex-row justify-between gap-2 items-center my-1 mt-4 w-full'>
                        <h3 className='font-bold col-span-2 text-start'>Allergeni</h3>
                        <Select
                            isMulti
                            name="allergens"
                            options={allergenOptions}
                            className="basic-multi-select w-full col-span-9"
                            classNamePrefix="select"
                            value={allergenOptions?.filter(option => itemDetails.menuItem.allergens.includes(option.value))}
                            onChange={handleAllergenChange}
                            placeholder="Seleziona allergeni..."
                        />

                    </div>

                    {showCreateIngredient && (
                        <CreateIngredient
                            showIngredientCreation={showCreateIngredient}
                            setShowIngredientCreation={setShowCreateIngredient}
                            menuDefaultLanguage={menuDefaultLanguage}
                            ingredients={ingredients}
                            translatedIngredients={translatedIngredients}
                            refreshIngredientMenuDefaultLanguage={refreshIngredientMenuDefaultLanguage}
                        />
                    )}
                    <div className='flex flex-row justify-center gap-2'>

                    </div>
                    {isLoading && loading &&
                        <div className='flex flex-row justify-center gap-2 pt-2'><div className='custom-loader'></div></div>
                    }
                    {success && successMessage &&
                        <div className='flex flex-row justify-center gap-2 font-light text-emerald-400 p-2 text-xs'>{successMessage}</div>
                    }
                    {error && errorMessage &&
                        <div className='flex flex-row justify-center gap-2 font-light text-red-600 p-2 text-xs'>{errorMessage}</div>
                    }
                </Modal>
            }
        </>
    )
}

export default CreateMenuItem