import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOwnerById } from '../redux/ownersSlice';
import { jwtDecode } from 'jwt-decode';
import MenuConfiguratorContainer from '../components/MenuConfiguratorContainer';

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userLoggedIn"));
    if (token) {
      const decodedToken = jwtDecode(token);
      dispatch(getOwnerById(decodedToken.owner_id));
    }
  }, [dispatch]);

  const owner = useSelector((state) => state.owners.ownerLogged?.owner);

  return (
    <>
      <MenuConfiguratorContainer/>
    </>
  );
};

export default Dashboard;
