import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateInitialization, deleteMenu, getAllMenusOwner } from '../redux/menusSlice';
import { FiEdit } from 'react-icons/fi';
import EditMenuTranslations from './EditMenuTranslations';
import { SketchPicker } from 'react-color';
import AWS from 'aws-sdk';
import axios from 'axios';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';
import { XMarkIcon } from "@heroicons/react/24/outline";
import Buttons from './modal/Buttons';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import Language from './modal/Language';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import TranslationsNew from './TranslationsNew'
import TimeSlots from './modal/TimeSlots';



const EditMenu = ({ menu, showEditMenu, setShowEditMenu, refreshMenuOwner, setSelectedMenuId }) => {

    const url = process.env.REACT_APP_SERVER_BASE_URL
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const isLoading = useSelector((state) => state.menus.updateMenuInitializationIsLoading);
    const errorMessage = useSelector((state) => state.menus.updateMenuInitializationError);
    const successMessage = useSelector((state) => state.menus.updateMenuInitializationSuccess);
    const [selectedMenuDetails, setSelectedMenuDetails] = useState(null);
    const [translations, setTranslations] = useState([])
    const [outputTranslations, setOutputTranslations] = useState([])


    const defaultLanguage = menu.defaultLanguage;

    const inputTranslations = menu.menu.translations.length > 0 ?
        menu.menu.translations.map(lang => ({
            language: lang.language,
            translatedName: lang.translatedMenu.translatedName || '',
            translatedDescription: lang.translatedMenu.translatedDescription || ''
        })) : [];


    const [formattedTranslations, setFormattedTranslations] = useState([]);



    console.log("menu: ", menu)
    useEffect(() => {

        const formattedTranslations = outputTranslations.map(lang => ({
            language: lang.language,
            translatedMenu: {
                translatedName: lang.translatedName,
                translatedDescription: lang.translatedDescription
            }
        }))
            .filter(lang => lang.translatedMenu.translatedName);

        setMenuDetails(prevMenuDetails => ({
            ...prevMenuDetails,
            menu: {
                ...prevMenuDetails.menu
            },
            translations: formattedTranslations || [],
        }
        ));



    }, [outputTranslations])


    const [imageFileName, setImageFileName] = useState(menu.menu?.image || '');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadMessage, setUploadMessage] = useState(false)
    const [uploadError, setUploadError] = useState(false)
    const [uploadedFileName, setUploadedFileName] = useState(false);

    useEffect(() => {

        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                image: uploadedFileName // URL to the uploaded file
            }
        }));

    }, [uploadedFileName])


    useEffect(() => {
        if (imageFileName) {
            axios.get(`${url}/imageurl/${imageFileName}`)
                .then(response => {
                    if (response.status === 200 && response.data.url) {
                        //     setImagePreview(response.data.url);
                    } else {
                        console.error('Failed to retrieve the signed URL:', response);
                    }
                })
                .catch(error => {
                    console.error('Error fetching the signed image URL:', error);
                });
        }
    }, [imageFileName]);


    const handleFileInput = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            console.log('No file selected');
            return;
        }


        const formData = new FormData();
        formData.append('menu', file);

        for (let pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        if (!menu.menu.image) {
            try {

                const response = await axios.post(`${url}/upload-menu`, formData);
                console.log('response', response)

                console.log('File uploaded successfully:', response.data.url);
                const uploadedFileName = response.data.url.split('/').pop();
                setMenuDetails(prevState => ({
                    ...prevState,
                    menu: {
                        ...prevState.menu,
                        image: uploadedFileName // URL to the uploaded file
                    }
                }));
                //      setImagePreview(URL.createObjectURL(file));
                setUploadMessage(true);
            } catch (error) {
                console.error('Error uploading file:', error);
                setUploadError(true);
            }
        } else {
            console.log('SONO QUA MENU.MENU.IMAGE ESISTE!!')
            try {

                const response = await axios.post(`${url}/update-menu/${menu.menu.image}`, formData);
                console.log('response', response)

                console.log('File uploaded successfully:', response.data.url);
                const uploadedFileName = response.data.url.split('/').pop();
                setMenuDetails(prevState => ({
                    ...prevState,
                    menu: {
                        ...prevState.menu,
                        image: uploadedFileName // URL to the uploaded file
                    }
                }));
                //   setImagePreview(URL.createObjectURL(file));
                setUploadMessage(true);
            } catch (error) {
                console.error('Error uploading file:', error);
                setUploadError(true);
            }
        }
    };

    const [inputImage, setInputImage] = useState('')

    useEffect(() => {
        if (menu.menu.image) {
            setInputImage(menu.menu.image)
        }
        console.log("inputImage", inputImage)
    }, []);



    const handleRemoveImage = () => {
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                image: ''
            }
        }));
        setUploadMessage(false)
        setUploadError(false);
        document.getElementById('fileInput').value = '';
    }


    const [showPicker, setShowPicker] = useState(false)
    const handleColorPickerOpen = () => setShowPicker(true);
    const handleColorPickerClose = () => setShowPicker(false);
    const [color, setColor] = useState(menu.menu?.color || '');

    console.log(color)

    const handleColorChange = (color) => {
        setColor(color.hex);
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                color: color.hex
            }
        }));
    };

    const resetPreviousColor = () => {
        setColor(menu.menu?.color);
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                color: menu.menu?.color
            }
        }));
    }

    const deleteColor = () => {
        setColor('');
        setMenuDetails(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                color: ''
            }
        }));
    }

    const [menuDetails, setMenuDetails] = useState({
        defaultLanguage: menu.defaultLanguage || '',
        settings: {
            alwaysActive: menu.settings?.alwaysActive || false,
            daysActive: menu.settings?.daysActive || [],
            timeSlot: {
                startTime: menu.settings?.timeSlot?.startTime || '',
                endTime: menu.settings?.timeSlot?.endTime || ''
            },
            timeSlots: menu.settings?.timeSlots || [] // Aggiungi questo
        },
        menu: {
            name: menu.menu?.name || '',
            description: menu.menu?.description || '',
            translations: formattedTranslations || [],
        }
    });

    useEffect(() => {
        setMenuDetails({
            defaultLanguage: menu.defaultLanguage || '',
            settings: {
                alwaysActive: menu.settings?.alwaysActive || false,
                daysActive: menu.settings?.daysActive || [],
                timeSlot: {
                    startTime: menu.settings?.timeSlot?.startTime || '',
                    endTime: menu.settings?.timeSlot?.endTime || ''
                },
                timeSlots: menu.settings?.timeSlots || [] // Aggiungi questo
            },
            menu: {
                name: menu.menu?.name || '',
                description: menu.menu?.description || '',
                translations: formattedTranslations || []
            }
        });
    }, [menu]);

    const handleAddTimeSlot = (dayIndex) => {
        setMenuDetails(prevState => ({
            ...prevState,
            settings: {
                ...prevState.settings,
                timeSlots: prevState.settings.timeSlots.map(slot => {
                    if (slot.day === dayIndex) {
                        return {
                            ...slot,
                            slots: [...slot.slots, { startTime: '', endTime: '' }]
                        };
                    }
                    return slot;
                })
            }
        }));
    };

    const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
        setMenuDetails(prevState => {
            const updatedTimeSlots = prevState.settings.timeSlots.map(slot => {
                if (slot.day === dayIndex) {
                    return {
                        ...slot,
                        slots: slot.slots.filter((_, idx) => idx !== slotIndex)
                    };
                }
                return slot;
            });
    
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };
    

    const handleTimeSlotChange = (dayIndex, slotIndex, field, value) => {
        setMenuDetails(prevState => {
            const updatedTimeSlots = prevState.settings.timeSlots.map(slot => {
                if (slot.day === dayIndex) {
                    return {
                        ...slot,
                        slots: slot.slots.map((s, idx) => {
                            if (idx === slotIndex) {
                                return {
                                    ...s,
                                    [field]: value
                                };
                            }
                            return s;
                        })
                    };
                }
                return slot;
            });
    
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };
    
    const handleDayToggle = (dayIndex) => {
        setMenuDetails(prevState => {
            const existingSlot = prevState.settings.timeSlots.find(slot => slot.day === dayIndex);

            let updatedTimeSlots;
            if (existingSlot) {
                // Se esiste già, lo rimuoviamo
                updatedTimeSlots = prevState.settings.timeSlots.filter(slot => slot.day !== dayIndex);
            } else {
                // Se non esiste, lo aggiungiamo con un slot vuoto
                updatedTimeSlots = [
                    ...prevState.settings.timeSlots,
                    { day: dayIndex, slots: [{ startTime: '', endTime: '' }] }
                ];
            }

            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    timeSlots: updatedTimeSlots
                }
            };
        });
    };


    const closeEditMenu = () => {
        setShowEditMenu(false)
    }

    const dispatch = useDispatch();

    const flagImages = {
        ita: itaFlag,
        fra: fraFlag,
        spa: spaFlag,
        eng: engFlag,
        ara: araFlag,
        cmn: cmnFlag,
        jpn: jpnFlag,
        rus: rusFlag,
        por: porFlag,
        nld: nldFlag,
        swe: sweFlag,
        deu: deuFlag
    };

    /*const handleToggle = (e) => {
        const newActiveState = e.target.checked;
      };*/

    // Array dei giorni della settimana per riferimento
    const weekDays = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

    // Funzione per controllare se il giorno è attivo
    const isDayActive = (dayIndex) => {
        // Controlla se l'indice del giorno è presente nell'array daysActive
        return menuDetails.settings.daysActive.includes(dayIndex);
    };

    // Questa funzione restituisce una funzione che può essere usata come handler per onClick
    const getHandleDeleteMenu = (menuId) => async () => {
        const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo menu?");
        if (confirmDelete) {
            await handleDeleteMenu(menuId);
            setShowEditMenu(false)
            setSelectedMenuId(false)
        }
    };

    // Questa funzione si occupa effettivamente del dispatch e dell'aggiornamento
    const handleDeleteMenu = async (menuId) => {
        setLoading(true);
        const response = await dispatch(deleteMenu({ menuId }));
        if (deleteMenu.fulfilled.match(response)) {
            setSelectedMenuId('')
            setSelectedMenuDetails(null);
            await refreshAllMenusOwner();
            setLoading(false)
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');

        setMenuDetails(prevState => {
            // Per aggiornamenti profondi (ad es. menu.name, menu.description)
            if (nameParts[0] === 'menu') {
                return {
                    ...prevState,
                    menu: {
                        ...prevState.menu,
                        [nameParts[1]]: value,
                    }
                };
            }
            // Per aggiornamenti a livello di settings (ad es. settings.timeSlot.startTime)
            else if (nameParts[0] === 'settings') {
                return {
                    ...prevState,
                    settings: {
                        ...prevState.settings,
                        [nameParts[1]]: nameParts.length > 2
                            ? {
                                ...prevState.settings[nameParts[1]],
                                [nameParts[2]]: value,
                            }
                            : value,
                    }
                };
            }
            // Per aggiornamenti di proprietà di primo livello
            else {
                return {
                    ...prevState,
                    [name]: value,
                };
            }
        });
    };

    const handleSubmit = async (event) => {
        setLoading(true)
        setError(false)
        setSuccess(false)
        event.preventDefault();
        try {
            const menuId = menu._id;
            const response = await dispatch(updateInitialization({
                menuId,
                ...menuDetails,
                settings: {
                    ...menuDetails.settings,
                    timeSlots: menuDetails.settings.timeSlots  // Usa menuDetails.settings.timeSlots
                }
            }));
            if (updateInitialization.fulfilled.match(response)) {
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    refreshMenuOwner(menu._id);
                    closeEditMenu();
                }, 2000);
            } else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            console.error('Errore nell\'invio dei dati del menu:', error);
        }
    };


    if (!menu || !menu.menu || !menu.settings || !menu.settings.timeSlot) {
        return <div>Caricamento...</div>;
    }

    const refreshAllMenusOwner = () => {
        dispatch(getAllMenusOwner())
    };

    const handleToggle = () => {
        const alwaysActive = !menuDetails.settings.alwaysActive;
        setMenuDetails({
            ...menuDetails,
            settings: {
                ...menuDetails.settings,
                alwaysActive: alwaysActive,
                daysActive: alwaysActive ? [] : menuDetails.settings.daysActive,
                timeSlot: {
                    startTime: alwaysActive ? '' : menuDetails.settings.timeSlot.startTime,
                    endTime: alwaysActive ? '' : menuDetails.settings.timeSlot.endTime,
                }
            }
        });
    };



    return (

        <>

            {showEditMenu && menu && menu.menu && menu.settings && menu.settings.timeSlot &&
                <Modal handleSubmit={handleSubmit}
                    closeFunction={closeEditMenu}
                    title="Modifca Menu"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                    deleteFunction={getHandleDeleteMenu(menu._id)}>

                    <Input description={menuDetails.menu.name || ''}
                        name='menu.name'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Nome</Input>

                    <Input description={menuDetails.menu.description || ''}
                        name='menu.description'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Descrizione</Input>

                    <hr className="border-gray-400 mt-8"></hr>

                    <TranslationsNew
                        defaultLanguage={defaultLanguage}
                        inputTranslations={inputTranslations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></TranslationsNew>


                    <hr className="border-gray-400 mt-8"></hr>

                    <div className='flex flex-col items-start mt-6'>
                        <div className='flex flex-row items-center justify-center gap-2 my-1'>
                            <span className='font-bold'>Attivo sempre</span>

                            <div >
                                <Toggle
                                    isOn={menuDetails.settings.alwaysActive}
                                    handleToggle={handleToggle}
                                />
                            </div>
                        </div>
                        {!menuDetails.settings.alwaysActive &&
                            (
                                // <>
                                //     <div className='flex flex-col items-start gap-2 my-1'>
                                //         <span className='font-bold'>Giorni Attivi</span>
                                //         <div className='flex flex-row items-center flex-wrap'>
                                //             {weekDays.map((day, index) => (

                                //                 <div key={index}>

                                //                     <input

                                //                         type="checkbox"
                                //                         checked={menuDetails.settings.daysActive.includes(index)}
                                //                         className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"
                                //                         value={index}
                                //                         name="settings.daysActive" // Aggiornato per corrispondere al percorso nell'oggetto
                                //                         onChange={handleInputChange}
                                //                     />
                                //                     <span className="me-2 text-gray-700">{day}</span>

                                //                 </div>
                                //             ))}

                                //         </div>
                                //     </div>
                                //     <div className='flex flex-row gap-2 my-1 items-center '>
                                //         <span className='font-bold'>Dalle</span>
                                //         <input
                                //             type="time"
                                //             className='bg-transparent flex-grow'
                                //             value={menuDetails.settings.timeSlot.startTime}
                                //             name="settings.timeSlot.startTime"
                                //             onChange={handleInputChange}
                                //         />
                                //         <span className='font-bold'>Alle</span>
                                //         <input
                                //             type="time"
                                //             className='bg-transparent flex-grow'
                                //             value={menuDetails.settings.timeSlot.endTime}
                                //             name="settings.timeSlot.endTime"
                                //             onChange={handleInputChange}
                                //         />
                                //     </div>

                                // </>

                                <TimeSlots
                                timeSlots={menuDetails.settings.timeSlots}
                                handleDayToggle={handleDayToggle}
                                handleTimeSlotChange={handleTimeSlotChange}
                                handleRemoveTimeSlot={handleRemoveTimeSlot}
                                handleAddTimeSlot={handleAddTimeSlot}
                            />
                            )
                        }
                    </div>
                    

                    <hr className="border-gray-400 mt-4 mb-4 w-full"></hr>

                    <ColorSection
                        handleColorChange={handleColorChange}
                        menu={menu}
                        deleteColor={deleteColor}
                        color={color}
                    ></ColorSection>

                    <ImageSection
                        inputImage={inputImage}
                        handleRemoveImage={handleRemoveImage}
                        setUploadedFileName={setUploadedFileName}
                        aspect={3 / 1}
                        maxSizeMB={0.3}
                        maxWidthOrHeight={1920}
                    ></ImageSection>

                    <hr className="border-gray-400 mt-4"></hr>

                </Modal>

            }

        </>
    )
}

export default EditMenu