import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { verifyOwner, resendVerifyOwner } from '../redux/ownersSlice';
import { useDispatch, useSelector } from 'react-redux';

const VerifyPageOwner = () => {

    const dispatch = useDispatch();

    const verified = useSelector((state) => state.owners.ownerVerify);
    const verifying = useSelector((state) => state.owners.isVerifing);
    const errorVerify = useSelector((state) => state.owners.errorVerify);
    const expiredEmail = useSelector((state) => state.owners.expiredEmail);
    console.log(expiredEmail)

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const cleanToken = token.replace(/\"/g, '');

    useEffect(() =>{
        dispatch(verifyOwner(cleanToken))
    }, [])  
  

  const handleResendClick = () => {
    if (expiredEmail) {
        dispatch(resendVerifyOwner(expiredEmail));
    }
};

  return (
    <>
    {verifying ? (
      <div className='flex flex-row justify-center'>
        <div className='font-primary text-xs'>Verifing your account..</div>
      </div>
    ) : verified ? (
        <>
        <div className='flex flex-col align-middle items-center'>
          <div className='font-primary font-light text-emerald-400 p-2 text-xs'>{verified.message}</div>
          <Link to="/" className='no-underline'>
            <button className='font- primary w-60 mb-1 input rounded-lg green-background p-2 text-center text-black text-sm'>Go to Login</button>
          </Link>
        </div>
      </>
    ) : errorVerify && (
      <>
      <div className='flex flex-col align-middle items-center'>
        <div className='font-primary text-xs'>Verification failed</div>
        <div className='font-light text-red-600 p-2 text-xs'>{errorVerify}</div>
        {expiredEmail && (
          <button onClick={handleResendClick} className='font- primary w-60 mb-1 input rounded-lg green-background p-2 text-center text-black text-sm'>
            Resend Verification Email
          </button>
        )}
      </div>
      </>
    ) }
    </>
  )
}

export default VerifyPageOwner