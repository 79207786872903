import React, { useState, useEffect } from 'react';
import { editOwner, deleteOwner, editEmailOwner } from '../redux/ownersSlice';
import { useDispatch, useSelector } from 'react-redux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import axios from 'axios';
import Modal from './modal/Modal';
import Input from './modal/Input';
import ImageSection from './modal/ImageSection';
import TimeSlots from './modal/TimeSlots'; // Import del componente TimeSlots

const EditOwner = ({ owner, ownerId, showEditOwner, setShowEditOwner, refreshOwner }) => {
  const dispatch = useDispatch();
  const [successMessageContainer, setSuccessMessageContainer] = useState(false);
  const [errorMessageContainer, setErrorMessageContainer] = useState(false);
  const isLoading = useSelector((state) => state.owners.isEditingOwner);
  const error = useSelector((state) => state.owners.editOwnerError);
  const successMessage = useSelector((state) => state.owners.editOwnerSuccessMessage);
  const [imagePreview, setImagePreview] = useState('');
  const [uploadMessage, setUploadMessage] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(owner.avatar || '');
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);

  const apiKeyMaps = process.env.REACT_APP_GOOGLE_MAPS;
  const url = process.env.REACT_APP_SERVER_BASE_URL;

  const [ownerData, setOwnerData] = useState({
    name: owner.name,
    phone: owner.phone ? owner.phone : '',
    address: {
      type: owner.address.type ? owner.address.type : '',
      coordinates: owner.address.coordinates ? owner.address.coordinates : '',
      description: owner.address.description ? owner.address.description : ''
    },
    vat: owner.vat ? owner.vat : '',
    avatar: owner.avatar ? owner.avatar : '',
    restaurantDetails: {
      facilities: {
        childrenArea: owner.restaurantDetails?.facilities?.childrenArea || false,
        outdoorGarden: owner.restaurantDetails?.facilities?.outdoorGarden || false,
        disabledAccess: owner.restaurantDetails?.facilities?.disabledAccess || false,
      },
      cuisine: {
        type: owner.restaurantDetails?.cuisine?.type || '',
        environment: owner.restaurantDetails?.cuisine?.environment || '',
      },
      socialMedia: {
        facebook: owner.restaurantDetails?.socialMedia?.facebook || '',
        instagram: owner.restaurantDetails?.socialMedia?.instagram || '',
        twitter: owner.restaurantDetails?.socialMedia?.twitter || '',
        whatsapp: owner.restaurantDetails?.socialMedia?.whatsapp || '',
        website: owner.restaurantDetails?.socialMedia?.website || '',
      },
      timeSlots: owner.restaurantDetails?.timeSlots || [],
    }
  });
  
  const [ownerEmail, setOwnerEmail] = useState({
    email: owner.email,
  });

  const handleAddressSelect = async (address) => {
    if (address && address.value && address.value.place_id) {
      try {
        const response = await axios.get(`${url}/place-details?place_id=${address.value.place_id}`);
        const { location } = response.data.result.geometry;
        const lat = location.lat;
        const lng = location.lng;

        setOwnerData(prev => ({
          ...prev,
          address: {
            type: "Point",
            coordinates: [lng, lat], // ordine lng-lat per GeoJSON
            description: address.value.description
          }
        }));
      } catch (error) {
        console.error('Error fetching place details:', error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOwnerData({
      ...ownerData,
      [name]: value,
    });
  };

  const handleFacilityChange = (e) => {
    const { name, checked } = e.target;
    setOwnerData(prev => ({
      ...prev,
      restaurantDetails: {
        ...prev.restaurantDetails,
        facilities: {
          ...prev.restaurantDetails.facilities,
          [name]: checked,
        },
      },
    }));
  };

  const handleCuisineChange = (e) => {
    const { name, value } = e.target;
    setOwnerData(prev => ({
      ...prev,
      restaurantDetails: {
        ...prev.restaurantDetails,
        cuisine: {
          ...prev.restaurantDetails.cuisine,
          [name]: value,
        },
      },
    }));
  };

  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    setOwnerData(prev => ({
      ...prev,
      restaurantDetails: {
        ...prev.restaurantDetails,
        socialMedia: {
          ...prev.restaurantDetails.socialMedia,
          [name]: value,
        },
      },
    }));
  };
  

  const handleTimeSlotChange = (dayIndex, slotIndex, key, value) => {
    setOwnerData(prev => {
        const updatedTimeSlots = [...prev.restaurantDetails.timeSlots];
        const daySlot = updatedTimeSlots.find(slot => slot.day === dayIndex);

        if (daySlot) {
            // Creiamo una copia dell'oggetto slot
            const updatedSlot = { ...daySlot.slots[slotIndex], [key]: value };

            // Creiamo una copia dell'array slots e aggiorniamo lo specifico slot
            const updatedSlots = [...daySlot.slots];
            updatedSlots[slotIndex] = updatedSlot;

            // Sostituiamo l'array slots aggiornato nel daySlot
            const updatedDaySlot = { ...daySlot, slots: updatedSlots };

            // Aggiorniamo l'array timeSlots con il nuovo daySlot
            const updatedTimeSlotsFinal = updatedTimeSlots.map(slot =>
                slot.day === dayIndex ? updatedDaySlot : slot
            );

            return {
                ...prev,
                restaurantDetails: {
                    ...prev.restaurantDetails,
                    timeSlots: updatedTimeSlotsFinal,
                },
            };
        } else {
            return prev;
        }
    });
};

  const handleAddTimeSlot = (dayIndex) => {
    setOwnerData(prev => {
        const updatedTimeSlots = [...prev.restaurantDetails.timeSlots];
        let daySlot = updatedTimeSlots.find(slot => slot.day === dayIndex);

        if (daySlot) {
            // Creiamo una copia modificabile dei 'slots'
            daySlot = { ...daySlot, slots: [...daySlot.slots, { startTime: '', endTime: '' }] };
            // Trova l'indice del giorno esistente e lo sostituisce
            const dayIndexToReplace = updatedTimeSlots.findIndex(slot => slot.day === dayIndex);
            updatedTimeSlots[dayIndexToReplace] = daySlot;
        } else {
            // Aggiungi un nuovo oggetto per il giorno
            updatedTimeSlots.push({ day: dayIndex, slots: [{ startTime: '', endTime: '' }] });
        }

        // Ritorna il nuovo stato
        return {
            ...prev,
            restaurantDetails: {
                ...prev.restaurantDetails,
                timeSlots: updatedTimeSlots,
            },
        };
    });
};

const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
  setOwnerData(prev => {
      const updatedTimeSlots = [...prev.restaurantDetails.timeSlots];
      const daySlot = updatedTimeSlots.find(slot => slot.day === dayIndex);

      if (daySlot) {
          // Creiamo una copia dell'array slots e rimuoviamo l'elemento
          const updatedSlots = [...daySlot.slots];
          updatedSlots.splice(slotIndex, 1);

          // Sostituiamo l'array slots aggiornato nel daySlot
          const updatedDaySlot = { ...daySlot, slots: updatedSlots };

          // Aggiorniamo l'array timeSlots con il nuovo daySlot
          const updatedTimeSlotsFinal = updatedTimeSlots.map(slot =>
              slot.day === dayIndex ? updatedDaySlot : slot
          );

          return {
              ...prev,
              restaurantDetails: {
                  ...prev.restaurantDetails,
                  timeSlots: updatedTimeSlotsFinal,
              },
          };
      } else {
          return prev;
      }
  });
};

const handleDayToggle = (dayIndex) => {
  setOwnerData(prev => {
      const updatedTimeSlots = [...prev.restaurantDetails.timeSlots];
      const daySlotIndex = updatedTimeSlots.findIndex(slot => slot.day === dayIndex);

      if (daySlotIndex > -1) {
          // Se il giorno esiste già, lo rimuoviamo (toglie il flag)
          updatedTimeSlots.splice(daySlotIndex, 1);
      } else {
          // Se il giorno non esiste, lo aggiungiamo con uno slot vuoto (accende il flag)
          updatedTimeSlots.push({ day: dayIndex, slots: [{ startTime: '', endTime: '' }] });
      }

      return {
          ...prev,
          restaurantDetails: {
              ...prev.restaurantDetails,
              timeSlots: updatedTimeSlots,
          },
      };
  });
};


  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setOwnerEmail({
      ...ownerEmail,
      [name]: value,
    });
  };

  useEffect(() => {
    console.log("ownerEmail", ownerEmail);
  }, [ownerEmail]);

  const closeEditOwner = () => {
    setShowEditOwner(false);
  };

  useEffect(() => {
    setOwnerData(prev => ({
      ...prev,
      avatar: uploadedFileName
    }));
  }, [uploadedFileName]);

  const handleRemoveImage = () => {
    setImagePreview('');
    setOwnerData(prev => ({
      ...prev,
      avatar: ''
    }));
    setUploadMessage(false);
    setUploadError(false);
    document.getElementById('fileInput').value = '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingContainer(true);
    setSuccessMessageContainer(false);
    setErrorMessageContainer(false);

    try {
      const responseOwner = await dispatch(editOwner({ ownerId, ownerData }));

      if (editOwner.fulfilled.match(responseOwner)) {
        if (owner.email !== ownerEmail.email) {
          const responseEmail = await dispatch(editEmailOwner({ ownerId, email: ownerEmail.email }));

          if (editEmailOwner.fulfilled.match(responseEmail)) {
            document.getElementById('fileInput').value = '';
            setSuccessMessageContainer(true);
            refreshOwner();
          } else {
            setErrorMessageContainer(true);
          }
        } else {
          setSuccessMessageContainer(true);
          refreshOwner();
        }
      } else {
        setErrorMessageContainer(true);
      }
    } catch (error) {
      console.error('Owner patch failed:', error);
      setErrorMessageContainer(true);
    } finally {
      setIsLoadingContainer(false);
      setShowEditOwner(false);
    }
  };

  useEffect(() => {
    if (successMessageContainer) {
      refreshOwner();
    }
  }, [successMessageContainer, refreshOwner]);

  const handleDeleteOwner = (ownerId) => async () => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare il tuo account per sempre?");
    if (confirmDelete) {
      await getDeletOwner(ownerId);
    }
  }

  const getDeletOwner = async (ownerId) => {
    setIsLoadingContainer(true)
    const response = await dispatch(deleteOwner({ ownerId }));
    if (deleteOwner.fulfilled.match(response)) {
      setIsLoadingContainer(false)
      setSuccessMessageContainer(true)
      localStorage.removeItem('userLoggedIn')
    } else {
      setIsLoadingContainer(false)
      setErrorMessageContainer(true)
    }
  }

  return (
    <>
      {showEditOwner &&
        <Modal
          handleSubmit={handleSubmit}
          closeFunction={closeEditOwner}
          deleteFunction={handleDeleteOwner(ownerId)}
          title="Modifica Utente"
          isLoading={isLoading}
          loading={isLoadingContainer}
          success={successMessageContainer}
          successMessage={successMessage}
          error={errorMessageContainer}
          errorMessage={error}
        >
          <Input
            description={ownerData.name}
            name='name'
            onChange={handleInputChange}
            tipo="InputText"
          >Nome Ristorante</Input>

          <Input
            description={ownerData.phone}
            name='phone'
            onChange={handleInputChange}
            tipo="InputText"
          >Telefono Ristorante</Input>

          <Input
            description={ownerEmail.email}
            name='email'
            onChange={handleEmailChange}
            tipo="InputText"
          >Email</Input>

          <div className="mt-4">
  <div className="text-start font-bold">Social Media</div>
  <Input
    description={ownerData.restaurantDetails.socialMedia.facebook}
    name="facebook"
    onChange={handleSocialMediaChange}
    tipo="InputText"
  >Facebook</Input>
  <Input
    description={ownerData.restaurantDetails.socialMedia.instagram}
    name="instagram"
    onChange={handleSocialMediaChange}
    tipo="InputText"
  >Instagram</Input>
  <Input
    description={ownerData.restaurantDetails.socialMedia.twitter}
    name="twitter"
    onChange={handleSocialMediaChange}
    tipo="InputText"
  >Twitter</Input>
  <Input
    description={ownerData.restaurantDetails.socialMedia.whatsapp}
    name="whatsapp"
    onChange={handleSocialMediaChange}
    tipo="InputText"
  >WhatsApp</Input>
  <Input
    description={ownerData.restaurantDetails.socialMedia.website}
    name="website"
    onChange={handleSocialMediaChange}
    tipo="InputText"
  >Website</Input>
</div>


          <div className="mt-4">
            <div className="text-start font-bold">Indirizzo</div>
            <GooglePlacesAutocomplete
              apiKey={apiKeyMaps}
              selectProps={{
                onChange: handleAddressSelect,
                value: {
                  label: ownerData.address.description || owner.address.description || '',
                  value: {
                    description: ownerData.address.description || owner.address.description || '',
                  },
                },
                styles: {
                  input: (base) => ({
                    ...base,
                    color: '#343b41',
                    textAlign: 'left'
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? 'white' : '#343b41',
                    backgroundColor: state.isFocused ? '#5ccfa9' : 'white'
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#343b41',
                    textAlign: 'left'
                  }),
                }
              }}
            />
          </div>

          <div className="mt-4">
            <div className="text-start font-bold">Facilities</div>
            <label>
              <input
                type="checkbox"
                name="childrenArea"
                checked={ownerData.restaurantDetails.facilities.childrenArea}
                onChange={handleFacilityChange}
              />
              Area bambini
            </label>
            <label>
              <input
                type="checkbox"
                name="outdoorGarden"
                checked={ownerData.restaurantDetails.facilities.outdoorGarden}
                onChange={handleFacilityChange}
              />
              Giardino esterno
            </label>
            <label>
              <input
                type="checkbox"
                name="disabledAccess"
                checked={ownerData.restaurantDetails.facilities.disabledAccess}
                onChange={handleFacilityChange}
              />
              Accesso disabili
            </label>
          </div>

          <div className="mt-4">
            <div className="text-start font-bold">Cucina</div>
            <Input
              description={ownerData.restaurantDetails.cuisine.type}
              name='type'
              onChange={handleCuisineChange}
              tipo="InputText"
            >Tipo di cucina</Input>
            <Input
              description={ownerData.restaurantDetails.cuisine.environment}
              name='environment'
              onChange={handleCuisineChange}
              tipo="InputText"
            >Ambiente</Input>
          </div>

          <div className="mt-4">
            <div className="text-start font-bold">Orari</div>
            <TimeSlots
              timeSlots={ownerData.restaurantDetails.timeSlots}
              handleDayToggle={handleDayToggle}
              handleTimeSlotChange={handleTimeSlotChange}
              handleRemoveTimeSlot={handleRemoveTimeSlot}
              handleAddTimeSlot={handleAddTimeSlot}
            />
          </div>

          {owner && owner.method === 'local' &&
            <ImageSection
              inputImage={owner.avatar}
              handleRemoveImage={handleRemoveImage}
              uploadedFileName={uploadedFileName}
              setUploadedFileName={setUploadedFileName}
              aspect={75 / 100}
              maxSizeMB={0.1}
              maxWidthOrHeight={1200}
            >
              Immagine di copertina
            </ImageSection>
          }
        </Modal>
      }
    </>
  );
};

export default EditOwner;
