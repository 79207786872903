import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIngredients } from '../redux/ingredientsSlice';
import {getTranslatedIngredients} from '../redux/translatedIngredientsSlice';
import { createIngredient } from '../redux/ingredientsSlice';
import {createIngredientTranslation} from '../redux/translatedIngredientsSlice';
import IngredientFormComponent from './IngredientFormComponent';
import IngredientFormComponent2 from './IngredientFormComponent2';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';

const CreateIngredient = ({showIngredientCreation, setShowIngredientCreation, menuDefaultLanguage, ingredients, translatedIngredients, refreshIngredientMenuDefaultLanguage}) => {


  return (
      <>
        <IngredientFormComponent
        showIngredientCreation={showIngredientCreation}
        setShowIngredientCreation={setShowIngredientCreation}
        menuDefaultLanguage={menuDefaultLanguage}
        ingredients={ingredients}
        translatedIngredients={translatedIngredients}
        refreshIngredientMenuDefaultLanguage={refreshIngredientMenuDefaultLanguage}
        />
      </>
  );
};

export default CreateIngredient;
