import React from 'react';

const Buttons = ({ children, type, onClick }) => {
  return (
    type == "green" ? 
    <button className="green-background hover:bg-green-600 text-white px-3 py-2  rounded-full shadow-md transition duration-300 transform hover:scale-105 text-base " onClick={onClick}> {children}</button> 
    : 
    <button className="bg-red-700 hover:bg-red-500 text-white px-3 py-2  rounded-full shadow-md transition duration-300 transform hover:scale-105" onClick={onClick}> {children}</button> 
     );
    }

export default Buttons;
