import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editMenuCategory, deleteMenuCategory } from '../redux/menusSlice';
import EditMenuCategoryTranslations from './EditMenuCategoryTranslations';
import ToggleSwitch from './ToggleSwitch';
import { FiEdit } from 'react-icons/fi';
import { SketchPicker } from 'react-color';
import AWS from 'aws-sdk';
import axios from 'axios';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import Language from './modal/Language';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import Translations from './modal/Translations'
import TranslationsNew from './TranslationsNew'


const EditMenuCategory = ({ owner, menu, menuId, categoryId, showEditMenuCategory, setShowEditMenuCategory, refreshAllMenusOwner, refreshMenuOwner, menuDefaultLanguage }) => {

    const url = process.env.REACT_APP_SERVER_BASE_URL
    const [showEditMenuCategoryTranslations, setShowEditMenuCategoryTranslations] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [translations, setTranslations] = useState([])
    const isLoading = useSelector((state) => state.menus.menuCategoryEditingIsLoading);
    const errorMessage = useSelector((state) => state.menus.menuCategoryEditingError);
    const successMessage = useSelector((state) => state.menus.menuCategoryEditingSuccess);
    const menuCategoryTarget = menu.menu?.menuCategory?.find(cat => cat._id === categoryId) || {};
    const [showNewMenuItemPrice, setShowNewMenuItemPrice] = useState(false);
    const [outputTranslations, setOutputTranslations] = useState([])


    const defaultLanguage = menu.defaultLanguage;
    const [formattedTranslations, setFormattedTranslations] = useState({
        language: '',
        translatedMenu: {
            translatedMenuCategory: {
                translatedName: '',
                translatedDescription: ''
            }
        }
    });


    const inputTranslations = menu.menu.translations.length > 0
        ? menu.menu.translations.map(lang => {
            // Trova la categoria specifica all'interno dell'array translatedMenuCategory
            const category = lang.translatedMenu.translatedMenuCategory.find(cat => cat.id === categoryId);

            // Se la categoria è trovata, restituisci i dettagli tradotti, altrimenti restituisci un oggetto di default
            return category ? {
                language: lang.language,
                translatedName: category.translatedName || '',
                translatedDescription: category.translatedDescription || ''
            } : null;
        }).filter(item => item !== null)  // Filtra via gli elementi null dove la categoria non è stata trovata
        : [];

    useEffect(() => {

        const formattedTranslations = outputTranslations.map(lang => ({
            language: lang.language,
            translatedMenuCategory: {
                translatedName: lang.translatedName,
                translatedDescription: lang.translatedDescription
            }
        }))

        setCategoryDetails(prevCategoryDetails => ({
            ...prevCategoryDetails,
            translations: formattedTranslations || [],
        }
        ));


    }, [outputTranslations])

    const [inputImage, setInputImage] = useState('')
    const [uploadedFileName, setUploadedFileName] = useState(false);


    useEffect(() => {
        const category = menu.menu.menuCategory.find(cat => cat._id === categoryId);
        const categoryImage = category ? category.image : null;
        setInputImage(categoryImage)
    }, []);

    useEffect(() => {

        setCategoryDetails(prevState => ({
            ...prevState,
            image: uploadedFileName
        }));

    }, [uploadedFileName])



    const handleRemoveImage = () => {

        setCategoryDetails(prevState => ({
            ...prevState,
            image: ''
        }));
    }



    const handleShowEditMenuCategoryTranslations = () => {
        setShowEditMenuCategoryTranslations(true)
    }

    const [imageFileName, setImageFileName] = useState(menuCategoryTarget.image || '');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadMessage, setUploadMessage] = useState(false)
    const [uploadError, setUploadError] = useState(false)


    useEffect(() => {
        if (imageFileName) {
            axios.get(`${url}/imageurl/${imageFileName}`)
                .then(response => {
                    if (response.status === 200 && response.data.url) {
                        //     setImagePreview(response.data.url);
                    } else {
                        console.error('Failed to retrieve the signed URL:', response);
                    }
                })
                .catch(error => {
                    console.error('Error fetching the signed image URL:', error);
                });
        }
    }, [imageFileName]);


    const handleFileInput = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            console.log('No file selected');
            return;
        }


        const formData = new FormData();
        formData.append('menuCategory', file);

        for (let pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        if (!menuCategoryTarget.image) {
            try {
                const response = await axios.post(`${url}/upload-menucategory`, formData);
                console.log('response', response)

                console.log('File uploaded successfully:', response.data.url);
                const uploadedFileName = response.data.url.split('/').pop();
                setCategoryDetails(prevState => ({
                    ...prevState,
                    image: uploadedFileName
                }));
                //   setImagePreview(URL.createObjectURL(file));
                setUploadMessage(true);
            } catch (error) {
                console.error('Error uploading file:', error);
                setUploadError(true);
            }
        } else {
            try {
                const response = await axios.post(`${url}/update-menucategory/${menuCategoryTarget.image}`, formData);
                console.log('response', response)

                console.log('File uploaded successfully:', response.data.url);
                const uploadedFileName = response.data.url.split('/').pop();
                setCategoryDetails(prevState => ({
                    ...prevState,
                    image: uploadedFileName
                }));
                //      setImagePreview(URL.createObjectURL(file));
                setUploadMessage(true);
            } catch (error) {
                console.error('Error uploading file:', error);
                setUploadError(true);
            }
        }
    };



    const [showPicker, setShowPicker] = useState(false)
    const handleColorPickerOpen = () => setShowPicker(true);
    const handleColorPickerClose = () => setShowPicker(false);
    const [color, setColor] = useState(menuCategoryTarget.color || '');

    const handleColorChange = (color) => {
        setColor(color.hex);
        setCategoryDetails(prevState => ({
            ...prevState,
            color: color.hex // Direttamente aggiorniamo la proprietà 'color'
        }));
    };

    const resetPreviousColor = () => {
        setColor(menuCategoryTarget.color);
        setCategoryDetails(prevState => ({
            ...prevState,
            color: menuCategoryTarget.color
        }));
    }

    const deleteColor = () => {
        setColor('');
        setCategoryDetails(prevState => ({
            ...prevState,
            color: ''
        }));
    }

    const [categoryDetails, setCategoryDetails] = useState({
        name: menuCategoryTarget.name || '',
        description: menuCategoryTarget.description || '',
        color: menuCategoryTarget.color || '',
        image: menuCategoryTarget.image || '',
        price: menuCategoryTarget.price || '',
        isComplete: menuCategoryTarget.isComplete || false,
        newMenuItemPrice: menuCategoryTarget.newMenuItemPrice || ''
    });


    useEffect(() => {
        setCategoryDetails({
            name: menuCategoryTarget.name || '',
            description: menuCategoryTarget.description || '',
            color: menuCategoryTarget.color || '',
            image: menuCategoryTarget.image || '',
            price: menuCategoryTarget.price || '',
            isComplete: menuCategoryTarget.isComplete || false,
            newMenuItemPrice: menuCategoryTarget.newMenuItemPrice || ''
        })
    }, [menuCategoryTarget])

    const closeEditMenuCategory = () => {
        setShowEditMenuCategory(false)
    }

    const dispatch = useDispatch();

    const flagImages = {
        ita: itaFlag,
        fra: fraFlag,
        spa: spaFlag,
        eng: engFlag,
        ara: araFlag,
        cmn: cmnFlag,
        jpn: jpnFlag,
        rus: rusFlag,
        por: porFlag,
        nld: nldFlag,
        swe: sweFlag,
        deu: deuFlag
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;


        /*  if (categoryDetails.isComplete) {
           console.log('NEW ITEM PRICE FALSOOO')
               setShowNewMenuItemPrice(false);
               console.log('NEW ITEM PRICE!!!',showNewMenuItemPrice)
               console.log('CATEGORY COMPLETE?',categoryDetails.isComplete)
           } else  {
               console.log('NEW ITEM PRICE FALSOOO')
               setShowNewMenuItemPrice(true);
               console.log('NEW ITEM PRICE!!!',showNewMenuItemPrice)
               console.log('CATEGORY COMPLETE?',categoryDetails.isComplete)
           }*/

        setCategoryDetails(prevState => ({
            ...prevState,
            [name]: inputValue // Aggiorniamo direttamente la proprietà usando il nome dell'input come chiave
        }));
    };

    // useEffect(() => {
    //     setCategoryDetails(prevState => ({
    //         ...prevState,
    //         isComplete: categoryDetails.isComplete
    //     }));
    // }, [categoryDetails.isComplete]);

    const handleToggle = () => {
        setCategoryDetails(prevState => {
            const newIsComplete = !prevState.isComplete;
            return {
                ...prevState,
                    isComplete: newIsComplete,
                    price: newIsComplete ? prevState.price : '' // Clear price if not complete
            };
        });
        console.log("categoryDetails", categoryDetails);
    };
    


    const handleSubmit = async (event) => {
        setLoading(true)
        setError(false)
        setSuccess(false)
        event.preventDefault();
        try {
            const response = await dispatch(editMenuCategory({ menuId, categoryId, ...categoryDetails }))
            //setLoading(true);
            if (editMenuCategory.fulfilled.match(response)) {
                setLoading(false);
                setSuccess(true);
                if (error) {
                    setError(false)
                }
                setTimeout(() => {
                    refreshAllMenusOwner(); // Se necessario, aggiorna i menu
                    closeEditMenuCategory(); // Chiudi il form di modifica
                    refreshMenuOwner(menuId);
                }, 2000);
            } else {
                setLoading(false)
                setError(true)
            }

        } catch (error) {
            console.error('Errore nell\'invio dei dati del menu:', error);
        }
    };


    // Questa funzione restituisce una funzione che può essere usata come handler per onClick
    const getHandleDeleteMenuCategory = (menuId, categoryId) => async () => {
        const confirmDelete = window.confirm("Sei sicuro di voler eliminare questa categoria?");
        if (confirmDelete) {
        await handleDeleteMenuCategory(menuId, categoryId);
        };
    };
        
    const handleDeleteMenuCategory = async (menuId, categoryId) => {
        setLoading(true)
        const response = await dispatch(deleteMenuCategory({ menuId, categoryId }));
        if (deleteMenuCategory.fulfilled.match(response)) {
          await refreshMenuOwner();
          setLoading(false);
        }
        setShowEditMenuCategory(false)
      };
    


    return (

        <>

            {showEditMenuCategory &&
                <Modal handleSubmit={handleSubmit}
                    closeFunction={closeEditMenuCategory}
                    title="Modifica Categoria Menu"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                    deleteFunction={getHandleDeleteMenuCategory(menuId, categoryId)}
                    >

                    <Input description={categoryDetails.name || ''}
                        name='name'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Nome</Input>

                    <Input description={categoryDetails.description || ''}
                        name='description'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Descrizione</Input>

                    <hr className="border-gray-400 mt-8"></hr>

                    <TranslationsNew
                        defaultLanguage={defaultLanguage}
                        inputTranslations={inputTranslations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></TranslationsNew>

                    <hr className="border-gray-400 mt-8"></hr>

                    <div className='flex flex-col items-start mt-6'>
                        <div className='flex flex-row items-center justify-center gap-2 my-1'>
                            <span className='font-bold'>Menu Completo</span>

                            <div >
                                <Toggle
                                    isOn={categoryDetails.isComplete}
                                    handleToggle={handleToggle}
                                />
                            </div>
                        </div>
                        {categoryDetails.isComplete &&
                            <div className='flex flex-row justify-between gap-2 items-center my-1'>
                                <h3 className='font-bold'>Prezzo</h3>
                                <div className=' p-2 w-40 flex flex-row justify-between items-center'>
                                    <input
                                        className='border rounded-lg border-gray-400 p-2 bg-transparent'
                                        value={categoryDetails.price}
                                        name='price'
                                        onChange={handleInputChange}
                                        type='number'
                                        min="0"
                                    />
                                </div>
                            </div>}
                    </div>
                    <hr className="border-gray-400 mt-8"></hr>

                    <ColorSection
                        handleColorChange={handleColorChange}
                        deleteColor={deleteColor}
                        color={color}
                    ></ColorSection>

                    <ImageSection
                        inputImage={inputImage}
                        handleRemoveImage={handleRemoveImage}
                        setUploadedFileName={setUploadedFileName}
                        aspect={3/1}
                        maxSizeMB={0.3}
                        maxWidthOrHeight={1920}
                    ></ImageSection>

                    <hr className="border-gray-400 mt-4"></hr>

                </Modal >
            }

        </>
    )
}

export default EditMenuCategory