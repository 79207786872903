import React, { useState, useEffect } from 'react';
import { registerOwner } from '../redux/ownersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import axios from 'axios';
import Modal from './modal/Modal';
import Input from './modal/Input';
import ImageSection from './modal/ImageSection';
import TimeSlots from './modal/TimeSlots';


const SignUpModal = ({ showSignUpModal, setShowSignUpModal }) => {
  const [successMessageContainer, setSuccessMessageContainer] = useState(false);
  const [errorMessageContainer, setErrorMessageContainer] = useState(false);
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);

  const isLoading = useSelector((state) => state.owners.newOwnerIsLoading);
  const error = useSelector((state) => state.owners.errorNewOwner);
  const successMessage = useSelector((state) => state.owners.successNewOwner);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadMessage, setUploadMessage] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');


  const apiKeyMaps = process.env.REACT_APP_GOOGLE_MAPS
  const url = process.env.REACT_APP_SERVER_BASE_URL
  const urlFrontEnd = process.env.REACT_APP_AMBIENTE || ''

  const closeSignUpModal = () => {
    setShowSignUpModal(false)
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [ownerData, setOwnerData] = useState({
    name: '',
    email: '',
    phone: '',
    //address: '',
    address: {
      type: "",
      coordinates: []
    },
    vat: '',
    password: '',
    method: 'local',
    restaurantDetails: {
      facilities: {
        childrenArea: false,
        outdoorGarden: false,
        disabledAccess: false,
      },
      cuisine: {
        type: '',
        environment: '',
      },
      socialMedia: {
        facebook: '',
        instagram: '',
        twitter: '',
        whatsapp: '',
        website: '',
      },
      timeSlots: [],
    }
  })

  const handleAddressSelect = async (address) => {
    if (address && address.value && address.value.place_id) {
      try {
        //const response = await axios.get(`https://maps.googleapis.com/maps/api/place/details/json?place_id=${address.value.place_id}&fields=geometry&key=${apiKeyMaps}`);
        const response = await axios.get(`${url}/place-details?place_id=${address.value.place_id}`);
        console.log(address)
        const { location } = response.data.result.geometry;
        const lat = location.lat;
        const lng = location.lng;
        console.log('lat', lat)
        console.log('lng', lng)
        setOwnerData(prev => ({
          ...prev,
          address: {
            type: "Point",
            coordinates: [lng, lat], // ordine lng-lat per GeoJSON
            description: address.value.description
          }
        }));
        console.log('OWNER DATA', ownerData)
      } catch (error) {
        console.error('Error fetching place details:', error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOwnerData({
      ...ownerData,
      [name]: value,
    });
    console.log(ownerData)
  }

  const handleFacilityChange = (e) => {
    const { name, checked } = e.target;
    setOwnerData((prev) => ({
      ...prev,
      restaurantDetails: {
        ...prev.restaurantDetails,
        facilities: {
          ...prev.restaurantDetails.facilities,
          [name]: checked,
        },
      },
    }));
  };

  const handleCuisineChange = (e) => {
    const { name, value } = e.target;
    setOwnerData((prev) => ({
      ...prev,
      restaurantDetails: {
        ...prev.restaurantDetails,
        cuisine: {
          ...prev.restaurantDetails.cuisine,
          [name]: value,
        },
      },
    }));
  };

  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    setOwnerData((prev) => ({
      ...prev,
      restaurantDetails: {
        ...prev.restaurantDetails,
        socialMedia: {
          ...prev.restaurantDetails.socialMedia,
          [name]: value,
        },
      },
    }));
  };

  const handleTimeSlotChange = (dayIndex, slotIndex, key, value) => {
    setOwnerData((prev) => {
      const updatedTimeSlots = [...prev.restaurantDetails.timeSlots];
      const daySlot = updatedTimeSlots.find((slot) => slot.day === dayIndex);

      if (daySlot) {
        const updatedSlot = { ...daySlot.slots[slotIndex], [key]: value };
        const updatedSlots = [...daySlot.slots];
        updatedSlots[slotIndex] = updatedSlot;
        const updatedDaySlot = { ...daySlot, slots: updatedSlots };
        const updatedTimeSlotsFinal = updatedTimeSlots.map((slot) =>
          slot.day === dayIndex ? updatedDaySlot : slot
        );

        return {
          ...prev,
          restaurantDetails: {
            ...prev.restaurantDetails,
            timeSlots: updatedTimeSlotsFinal,
          },
        };
      } else {
        return prev;
      }
    });
  };

  const handleAddTimeSlot = (dayIndex) => {
    setOwnerData((prev) => {
      const updatedTimeSlots = [...prev.restaurantDetails.timeSlots];
      let daySlot = updatedTimeSlots.find((slot) => slot.day === dayIndex);

      if (daySlot) {
        daySlot = { ...daySlot, slots: [...daySlot.slots, { startTime: '', endTime: '' }] };
        const dayIndexToReplace = updatedTimeSlots.findIndex((slot) => slot.day === dayIndex);
        updatedTimeSlots[dayIndexToReplace] = daySlot;
      } else {
        updatedTimeSlots.push({ day: dayIndex, slots: [{ startTime: '', endTime: '' }] });
      }

      return {
        ...prev,
        restaurantDetails: {
          ...prev.restaurantDetails,
          timeSlots: updatedTimeSlots,
        },
      };
    });
  };

  const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
    setOwnerData((prev) => {
      const updatedTimeSlots = [...prev.restaurantDetails.timeSlots];
      const daySlot = updatedTimeSlots.find((slot) => slot.day === dayIndex);

      if (daySlot) {
        const updatedSlots = [...daySlot.slots];
        updatedSlots.splice(slotIndex, 1);
        const updatedDaySlot = { ...daySlot, slots: updatedSlots };
        const updatedTimeSlotsFinal = updatedTimeSlots.map((slot) =>
          slot.day === dayIndex ? updatedDaySlot : slot
        );

        return {
          ...prev,
          restaurantDetails: {
            ...prev.restaurantDetails,
            timeSlots: updatedTimeSlotsFinal,
          },
        };
      } else {
        return prev;
      }
    });
  };

  const handleDayToggle = (dayIndex) => {
    setOwnerData((prev) => {
      const updatedTimeSlots = [...prev.restaurantDetails.timeSlots];
      const daySlotIndex = updatedTimeSlots.findIndex((slot) => slot.day === dayIndex);

      if (daySlotIndex > -1) {
        updatedTimeSlots.splice(daySlotIndex, 1);
      } else {
        updatedTimeSlots.push({ day: dayIndex, slots: [{ startTime: '', endTime: '' }] });
      }

      return {
        ...prev,
        restaurantDetails: {
          ...prev.restaurantDetails,
          timeSlots: updatedTimeSlots,
        },
      };
    });
  };


  const [imagePreview, setImagePreview] = useState('');

  const handleFileInput = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      console.log('No file selected');
      return;
    }


    const formData = new FormData();
    formData.append('avatar', file);

    for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    try {

      const response = await axios.post(`${url}/upload-avatar`, formData);
      console.log('response', response)

      console.log('File uploaded successfully:', response.data.url);
      const uploadedFileName = response.data.url.split('/').pop();
      setOwnerData(prev => ({
        ...prev,
        avatar: uploadedFileName
      }));
      setImagePreview(URL.createObjectURL(file));
      setUploadMessage(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadError(true);
    }
  };

  useEffect(() => {
    setOwnerData(prev => ({
      ...prev,
      avatar: uploadedFileName
    }));
  }, [uploadedFileName]);

  const handleRemoveImage = () => {
    setImagePreview('');
    setOwnerData(prev => ({
      ...prev,
      avatar: ''
    }));
    setUploadMessage(false)
    setUploadError(false);
    document.getElementById('fileInput').value = '';
  }


  useEffect(() => {
    console.log("ownerData", ownerData)
  }, [ownerData])

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log('OWNER DATA:', ownerData); // Questo ti aiuterà a verificare il contenuto di ownerData
  
    try {
      setIsLoadingContainer(true); // Mostra un indicatore di caricamento
      setErrorMessageContainer(false); // Nasconde eventuali errori precedenti
      setSuccessMessageContainer(false); // Nasconde eventuali messaggi di successo precedenti
  
      // Invia i dati dell'owner
      const response = await dispatch(registerOwner(ownerData));
  
      if (registerOwner.fulfilled.match(response)) {
        // Se la registrazione ha successo, resetta lo stato e mostra un messaggio di successo
        setOwnerData({
          name: '',
          email: '',
          phone: '',
          address: {
            type: "",
            coordinates: []
          },
          vat: '',
          password: '',
          method: 'local',
          restaurantDetails: {
            facilities: {
              childrenArea: false,
              outdoorGarden: false,
              disabledAccess: false,
            },
            cuisine: {
              type: '',
              environment: '',
            },
            socialMedia: {
              facebook: '',
              instagram: '',
              twitter: '',
              whatsapp: '',
              website: '',
            },
            timeSlots: [],
          }
        });
        setSuccessMessageContainer(true);
        setTimeout(() => {
          setShowSignUpModal(false);
        }, 2000);
      } else {
        // Se la registrazione fallisce, mostra un messaggio di errore
        setErrorMessageContainer(true);
      }
    } catch (error) {
      console.error('User registration failed:', error);
      setErrorMessageContainer(true); // Mostra un messaggio di errore in caso di eccezione
    } finally {
      setIsLoadingContainer(false); // Nascondi l'indicatore di caricamento
    }
  };
  
  console.log("urlFrontEnd", urlFrontEnd)
  return (
    <>

      {/* {showSignUpModal && urlFrontEnd == 'SVILUPPO' && */}
      {showSignUpModal &&

        <>

          <Modal
            handleSubmit={handleSubmit}
            closeFunction={closeSignUpModal}
            deleteFunction={''}
            title="Registrazione Utente"
            isLoading={''}
            loading={''}
            success={''}
            successMessage={''}
            error={''}
            errorMessage={''}
          >

            <Input
              description={ownerData.name}
              name='name'
              onChange={handleInputChange}
              tipo="InputText"
            >Nome Ristorante</Input>

            <Input
              description={ownerData.phone}
              name='phone'
              onChange={handleInputChange}
              tipo="InputText"
            >Telefono Ristorante</Input>

            <Input
              description={ownerData.email}
              name='email'
              onChange={handleInputChange}
              tipo="InputText"
            >Email</Input>

            <Input
              description={ownerData.password}
              name='password'
              onChange={handleInputChange}
              tipo="InputText"
            >Password</Input>

            <div className="mt-4">
              <div className="text-start font-bold">Indirizzo</div>
              <GooglePlacesAutocomplete
                apiKey={apiKeyMaps}
                selectProps={{
                  onChange: handleAddressSelect,
                  value: {
                    label: ownerData.address.description || '',
                    value: {
                      description: ownerData.address.description || '',
                    },
                  },
                  styles: {
                    input: (base) => ({
                      ...base,
                      color: '#343b41',
                      textAlign: 'left'
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isFocused ? 'white' : '#343b41',
                      backgroundColor: state.isFocused ? '#5ccfa9' : 'white'
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#343b41',
                      textAlign: 'left'
                    }),
                  }
                }}
              />
            </div>

            <div className="mt-4">
              <div className="text-start font-bold">Social Media</div>
              <Input
                description={ownerData.restaurantDetails.socialMedia.facebook}
                name="facebook"
                onChange={handleSocialMediaChange}
                tipo="InputText"
              >
                Facebook
              </Input>
              <Input
                description={ownerData.restaurantDetails.socialMedia.instagram}
                name="instagram"
                onChange={handleSocialMediaChange}
                tipo="InputText"
              >
                Instagram
              </Input>
              <Input
                description={ownerData.restaurantDetails.socialMedia.twitter}
                name="twitter"
                onChange={handleSocialMediaChange}
                tipo="InputText"
              >
                Twitter
              </Input>
              <Input
                description={ownerData.restaurantDetails.socialMedia.whatsapp}
                name="whatsapp"
                onChange={handleSocialMediaChange}
                tipo="InputText"
              >
                WhatsApp
              </Input>
              <Input
                description={ownerData.restaurantDetails.socialMedia.website}
                name="website"
                onChange={handleSocialMediaChange}
                tipo="InputText"
              >
                Website
              </Input>
            </div>

            <div className="mt-4">
              <div className="text-start font-bold">Facilities</div>
              <label>
                <input
                  type="checkbox"
                  name="childrenArea"
                  checked={ownerData.restaurantDetails.facilities.childrenArea}
                  onChange={handleFacilityChange}
                />
                Area bambini
              </label>
              <label>
                <input
                  type="checkbox"
                  name="outdoorGarden"
                  checked={ownerData.restaurantDetails.facilities.outdoorGarden}
                  onChange={handleFacilityChange}
                />
                Giardino esterno
              </label>
              <label>
                <input
                  type="checkbox"
                  name="disabledAccess"
                  checked={ownerData.restaurantDetails.facilities.disabledAccess}
                  onChange={handleFacilityChange}
                />
                Accesso disabili
              </label>
            </div>

            <div className="mt-4">
              <div className="text-start font-bold">Cucina</div>
              <Input
                description={ownerData.restaurantDetails.cuisine.type}
                name="type"
                onChange={handleCuisineChange}
                tipo="InputText"
              >
                Tipo di cucina
              </Input>
              <Input
                description={ownerData.restaurantDetails.cuisine.environment}
                name="environment"
                onChange={handleCuisineChange}
                tipo="InputText"
              >
                Ambiente
              </Input>
            </div>

            <div className="mt-4">
              <div className="text-start font-bold">Orari</div>
              <TimeSlots
                timeSlots={ownerData.restaurantDetails.timeSlots}
                handleDayToggle={handleDayToggle}
                handleTimeSlotChange={handleTimeSlotChange}
                handleRemoveTimeSlot={handleRemoveTimeSlot}
                handleAddTimeSlot={handleAddTimeSlot}
              />
            </div>


            <ImageSection
              handleRemoveImage={handleRemoveImage}
              uploadedFileName={uploadedFileName}
              setUploadedFileName={setUploadedFileName}
              aspect={75 / 100}
              maxSizeMB={0.1}
              maxWidthOrHeight={1200}
            >
              Immagine di copertina
            </ImageSection>

          </Modal>
        </>
      }

      {/* {showSignUpModal && urlFrontEnd != 'SVILUPPO' &&
        <>
          <div className="text-red-500 mt-6"> Sito in allestimento </div>
          <div className="text-red-500 "> Registrazione momentaneamente non disponibile </div>
        </>
      } */}
      {!showSignUpModal && urlFrontEnd === 'SVILUPPO' &&
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-90 flex items-center justify-center z-10 overflow-y-auto">
          <div className='rounded-lg text-white'></div>
          <form className='font-primary form text-white text-center' onSubmit={handleSubmit} enctype="multipart/form-data">
            <div className='space-y-4'>
              <div>
                <input
                  className='w-60 input rounded-lg border-zinc-300 border p-2 text-left text-black text-sm'
                  type='text'
                  placeholder='Your Name'
                  name='name'
                  value={ownerData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <input
                  className='w-60 input rounded-lg border-zinc-300 border p-2 text-left text-black text-sm'
                  type='text'
                  placeholder='Your Phone'
                  name='phone'
                  value={ownerData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div>x
                <GooglePlacesAutocomplete
                  required
                  apiKey={apiKeyMaps}
                  selectProps={{
                    onChange: handleAddressSelect,
                    placeholder: 'Digita indirizzo',
                    styles: {
                      input: (base) => ({
                        ...base,
                        color: '#343b41', // Colore del testo nell'input
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: state.isFocused ? 'white' : '#343b41', // Colore del testo delle opzioni
                        backgroundColor: state.isFocused ? '#5ccfa9' : 'white' // Colore di sfondo delle opzioni
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#343b41', // Colore del testo dell'opzione selezionata
                      }),
                    }
                  }}
                />
              </div>
              <div>
                <input
                  className='w-60 input rounded-lg border-zinc-300 border p-2 text-left text-black text-sm'
                  type='text'
                  placeholder='Your Email'
                  name='email'
                  value={ownerData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <input
                  className='w-60 input rounded-lg border-zinc-300 border p-2 text-left text-black text-sm'
                  type='text'
                  placeholder='Your VAT'
                  name='vat'
                  value={ownerData.vat}
                  onChange={handleInputChange}
                />
              </div>
              <div className='flex flex-col items-center gap-2'>
                <input
                  id='fileInput'
                  className='w-60  input rounded-lg border-zinc-300 bg-white border p-2 text-left text-black text-sm'
                  type='file'
                  placeholder='Your Avatar'
                  name='avatar'
                  onChange={handleFileInput}
                />
                {imagePreview && (
                  <div className="relative">
                    <img src={imagePreview} alt="Anteprima" className='w-10 rounded-lg' />
                    <button onClick={handleRemoveImage} className="font-primary absolute top-0 right-0 bg-red-500/70 text-white rounded-full w-6 h-6 p-1 text-xs">X</button>
                  </div>
                )}
                {imagePreview && uploadMessage && (
                  <span className='font-light text-sm text-emerald-400 '>Upload Success</span>
                )}
                {imagePreview && uploadError && (
                  <span className='font-light text-sm text-red-600 '>Upload Error</span>
                )}

              </div>
              <div>
                <input
                  className='w-60 input rounded-lg border-zinc-300 border p-2 text-left text-black text-sm'
                  type='password'
                  placeholder='Your Password'
                  name='password'
                  value={ownerData.password}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className='flex flex-col items-center mt-3'>
              <button className='w-60 mb-1 input rounded-lg green-background p-2 text-center text-black text-sm' type='submit'>
                SignUp
              </button>
              <button className='w-60 mt-1 input border bg-zinc-300 rounded-lg p-2 text-center text-black text-sm' onClick={closeSignUpModal}>
                Close
              </button>
              {isLoadingContainer && isLoading && <div className='custom-loader my-2'></div>}
              {errorMessageContainer && error && <div className='font-light text-red-600 p-2 text-xs'>{error}</div>}
              {successMessageContainer && successMessage && <div className='font-light text-emerald-400 p-2 text-xs'>{successMessage + " Check your email to verify your account"}</div>}
            </div>
          </form>
        </div>
      }
    </>
  )
}

export default SignUpModal