import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMenuCategory } from '../redux/menusSlice';
import { FiEdit } from 'react-icons/fi';
import Translations from './modal/Translations';
import { SketchPicker } from 'react-color';
import AWS from 'aws-sdk';
import axios from 'axios';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';
import { XMarkIcon } from "@heroicons/react/24/outline";
import Buttons from './modal/Buttons';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import Language from './modal/Language';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import CreateMenuCategoryTranslations from './CreateMenuCategoryTranslations';
import EditMenuCategoryTranslations from './EditMenuCategoryTranslations';
import TranslationsNew from './TranslationsNew'


const CreateMenuCategory = ({ owner, menu, menuId, showCreateMenuCategory, setShowCreateMenuCategory, refreshAllMenusOwner, refreshMenuOwner, menuDefaultLanguage }) => {

    const url = process.env.REACT_APP_SERVER_BASE_URL
    const [showCreateMenuCategoryTranslations, setShowCreateMenuCategoryTranslations] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [translations, setTranslations] = useState([])
    const isLoading = useSelector((state) => state.menus.menuCategoryCreationIsLoading);
    const errorMessage = useSelector((state) => state.menus.menuCategoryCreationError);
    const successMessage = useSelector((state) => state.menus.menuCategoryCreationSuccess);
    const [forceHideSaveButton, setForceHideSaveButton] = useState(false)
    const [outputTranslations, setOutputTranslations] = useState([])

    const defaultLanguage = menu.defaultLanguage;
    const [formattedTranslations, setFormattedTranslations] = useState({
        language: '',
        translatedMenu: {
            translatedMenuCategory: {
                translatedName: '',
                translatedDescription: ''
            }
        }
    });

    const inputTranslations = menu.menu.translations.length > 0 ?
        menu.menu.translations.map(lang => ({
            language: lang.language,
            translatedName: '',
            translatedDescription: ''
        })) : [];

    console.log("inputTranslations", inputTranslations)



    console.log("menu: ", menu)
    useEffect(() => {

        const formattedTranslations = outputTranslations.map(lang => ({
            language: lang.language,
            translatedName: lang.translatedName,
            translatedDescription: lang.translatedDescription
        }))

        console.log("formattedTranslations", formattedTranslations)
        setCategoryDetails(prevCategoryDetails => ({
            ...prevCategoryDetails,
            translations: formattedTranslations || [],
        }
        ));
        console.log("categoryDetails IN", categoryDetails)


    }, [outputTranslations])



    const [uploadedFileName, setUploadedFileName] = useState();


    useEffect(() => {

        setCategoryDetails(prevState => ({
            ...prevState,
            menuCategory: {
                ...prevState.menuCategory,
                image: uploadedFileName
            }
        }));

    }, [uploadedFileName])



    const handleRemoveImage = () => {

        setCategoryDetails(prevState => ({
            ...prevState,
            menuCategory: {
                ...prevState.menuCategory,
                image: ''
            }
        }));
    }




    const handleShowCreateMenuCategoryTranslations = () => {
        setShowCreateMenuCategoryTranslations(true)
    }



    const [showPicker, setShowPicker] = useState(false)
    const handleColorPickerOpen = () => setShowPicker(true);
    const handleColorPickerClose = () => setShowPicker(false);
    const [color, setColor] = useState('');


    console.log(color)

    const handleColorChange = (color) => {

        setColor(color.hex);
        setCategoryDetails(prevState => ({
            ...prevState,
            menuCategory: {
                ...prevState.menuCategory,
                color: color.hex
            }
        }));
    };

    const deleteColor = () => {
        setColor('');
        setCategoryDetails(prevState => ({
            ...prevState,
            menuCategory: {
                ...prevState.menuCategory,
                color: ''
            }
        }));
    }

    const [categoryDetails, setCategoryDetails] = useState({
        menuCategory: {
            name: '',
            description: '',
            color: '',
            image: '',
            price: '',
            isComplete: false
        },
        translations: []

    })
        ;


    const dispatch = useDispatch();

    const flagImages = {
        ita: itaFlag,
        fra: fraFlag,
        spa: spaFlag,
        eng: engFlag,
        ara: araFlag,
        cmn: cmnFlag,
        jpn: jpnFlag,
        rus: rusFlag,
        por: porFlag,
        nld: nldFlag,
        swe: sweFlag,
        deu: deuFlag
    };


    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const [category, key] = name.split('.'); // Ad esempio, "menuCategory.name"

        const inputValue = type === 'checkbox' ? checked : value;
        console.log('CHECKED', checked)

        setCategoryDetails(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [key]: inputValue
            }
        }));
    };

    console.log(categoryDetails)



    const handleSubmit = async (event) => {
        setLoading(true)
        setError(false)
        setSuccess(false)
        event.preventDefault();
        try {
            if (translations) {
                const completeCategoryDetails = { menuId, ...categoryDetails }
                const response = await dispatch(createMenuCategory(completeCategoryDetails))
                //setLoading(true);
                if (createMenuCategory.fulfilled.match(response)) {
                    setLoading(false);
                    setSuccess(true);
                    if (error) {
                        setError(false)
                    }
                    setForceHideSaveButton(true)
                    setTimeout(() => {
                        refreshMenuOwner(menuId)
                        refreshAllMenusOwner(); // Se necessario, aggiorna i menu
                        closeCreateMenuCategory(); // Chiudi il form di modifica
                    }, 2000);
                } else {
                    setLoading(false)
                    setError(true)
                }
            } else {
                const response = await dispatch(createMenuCategory(menuId, ...categoryDetails))
                //setLoading(true);
                if (createMenuCategory.fulfilled.match(response)) {
                    setLoading(false);
                    setSuccess(true);
                    if (error) {
                        setError(false)
                    }
                    setForceHideSaveButton(true)
                    setTimeout(() => {
                        refreshAllMenusOwner(); // Se necessario, aggiorna i menu
                        closeCreateMenuCategory(); // Chiudi il form di modifica
                    }, 2000);
                } else {
                    setLoading(false)
                    setError(true)
                }
            }

        } catch (error) {
            console.error('Errore nell\'invio dei dati del menu:', error);
        }
    };

    const closeCreateMenuCategory = () => {
        setShowCreateMenuCategory(false)
    }

    const handleToggle = () => {
        setCategoryDetails(prevState => {
            const newIsComplete = !prevState.menuCategory.isComplete;
            return {
                ...prevState,
                menuCategory: {
                    ...prevState.menuCategory,
                    isComplete: newIsComplete,
                    price: newIsComplete ? prevState.menuCategory.price : '' // Clear price if not complete
                }
            };
        });
    };


    return (

        <>

            {showCreateMenuCategory &&
                <Modal handleSubmit={handleSubmit}
                    closeFunction={closeCreateMenuCategory}
                    title="Crea Categoria Menu"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}>


                    <Input description={categoryDetails.menuCategory.name || ''}
                        name='menuCategory.name'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Nome</Input>

                    <Input description={categoryDetails.menuCategory.description || ''}
                        name='menuCategory.description'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Descrizione</Input>

                    <hr className="border-gray-400 mt-8"></hr>

                    <TranslationsNew
                        defaultLanguage={defaultLanguage}
                        inputTranslations={inputTranslations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></TranslationsNew>

                    <hr className="border-gray-400 mt-8"></hr>

                    <div className='flex flex-col items-start mt-6'>
                        <div className='flex flex-row items-center justify-center gap-2 my-1'>
                            <span className='font-bold'>Menu Completo</span>

                            <div >
                                <Toggle
                                    isOn={categoryDetails.menuCategory.isComplete}
                                    handleToggle={handleToggle}
                                />
                            </div>
                        </div>
                        {categoryDetails.menuCategory.isComplete &&
                            <div className='flex flex-row justify-between gap-2 items-center my-1'>
                                <h3 className='font-bold'>Prezzo</h3>
                                <div className=' p-2 w-40 flex flex-row justify-between items-center'>
                                    <input
                                        className='border rounded-lg border-gray-400 p-2 bg-transparent'
                                        value={categoryDetails.menuCategory.price}
                                        name='menuCategory.price'
                                        onChange={handleInputChange}
                                        type='number'
                                        min="0"
                                    />
                                </div>
                            </div>}
                    </div>
                    <hr className="border-gray-400 mt-8"></hr>

                    <ColorSection
                        handleColorChange={handleColorChange}
                        deleteColor={deleteColor}
                        color={color}
                    ></ColorSection>

                    <ImageSection
                        handleRemoveImage={handleRemoveImage}
                        setUploadedFileName={setUploadedFileName}
                        aspect={3/1}
                        maxSizeMB={0.3}
                        maxWidthOrHeight={1920}
                    ></ImageSection>

                    <hr className="border-gray-400 mt-4"></hr>

                </Modal>
            }
        </>
    )
}

export default CreateMenuCategory