import React from 'react';

const Toggle = ({ isOn, handleToggle }) => {
    
    console.log("isOn", isOn)
    return (
        
        
        <div className="flex items-center justify-center">
            <div
                onClick={handleToggle}
                className={`w-10 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${isOn ? 'green-background' : 'bg-gray-300'}`}>
                <div
                    className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${isOn ? 'translate-x-4' : ''}`}></div>
            </div>
        </div>
    )
}

export default Toggle;
