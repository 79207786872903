import React, { useState } from 'react';
import { Footer } from "../components/Footer";
import Button from '../components/modal/Buttons'
import SendMail from '../components/SendMail';
import { useNavigate } from 'react-router-dom';

const italianProvinces = [
    "Agrigento", "Alessandria", "Ancona", "Aosta", "Arezzo", "Ascoli Piceno", "Asti",
    "Avellino", "Bari", "Barletta-Andria-Trani", "Belluno", "Benevento", "Bergamo",
    "Biella", "Bologna", "Bolzano", "Brescia", "Brindisi", "Cagliari", "Caltanissetta",
    "Campobasso", "Caserta", "Catania", "Catanzaro", "Chieti", "Como", "Cosenza",
    "Cremona", "Crotone", "Cuneo", "Enna", "Fermo", "Ferrara", "Firenze", "Foggia",
    "Forlì-Cesena", "Frosinone", "Genova", "Gorizia", "Grosseto", "Imperia", "Isernia",
    "L'Aquila", "La Spezia", "Latina", "Lecce", "Lecco", "Livorno", "Lodi", "Lucca",
    "Macerata", "Mantova", "Massa-Carrara", "Matera", "Messina", "Milano", "Modena",
    "Monza e Brianza", "Napoli", "Novara", "Nuoro", "Oristano", "Padova", "Palermo",
    "Parma", "Pavia", "Perugia", "Pesaro e Urbino", "Pescara", "Piacenza", "Pisa",
    "Pistoia", "Pordenone", "Potenza", "Prato", "Ragusa", "Ravenna", "Reggio Calabria",
    "Reggio Emilia", "Rieti", "Rimini", "Roma", "Rovigo", "Salerno", "Sassari", "Savona",
    "Siena", "Siracusa", "Sondrio", "Sud Sardegna", "Taranto", "Teramo", "Terni", "Torino",
    "Trapani", "Trento", "Treviso", "Trieste", "Udine", "Varese", "Venezia", "Verbano-Cusio-Ossola",
    "Vercelli", "Verona", "Vibo Valentia", "Vicenza", "Viterbo"
];

const HomePage = () => {
    const [isEligible, setIsEligible] = useState(false);
    const [selectedProvince, setSelectedProvince] = useState('');
    const [signUp, setSignUp] = useState(false);
    const navigate = useNavigate();


    const scrollToPromo = () => {
        const promoSection = document.getElementById('promo');
        if (promoSection) {
            promoSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleVerifyClick = () => {
        if (selectedProvince) {
            setIsEligible(true);
        }
    };

    const handleProvinceChange = (e) => {
        setSelectedProvince(e.target.value);
        setIsEligible(false);
    };

    const handleSignUp = () => {
        setSignUp(!signUp)
    };

    const handleMenuDigitale = () => {
        navigate('/menu-digitale');
        window.scrollTo(0, 0); 

    };

    return (
        <div className="container mx-auto p-4 md:p-2 mt-8 max-w-5xl green-background-text">
            <main>
                {/* Prima sezione */}
                <section className="bg-green-50 p-10 rounded-2xl shadow-xl flex flex-col justify-center items-center gap-8 mb-16 mt-12">
                    <div className=" ">
                        <h2 className="text-3xl font-extrabold mb-6">Trova il menu perfetto per te</h2>
                        <p className="text-lg  mb-4">Cerca tra i menu della nostra piattaforma, filtra per ingredienti e allergeni, e scopri il ristorante migliore per te!</p>
                        <p className="text-lg ">L'applicazione Android e iOS sarà disponibile nella <strong>Primavera 2025</strong>, torna a trovarci per restare aggiornato.</p>
                    </div>
                    <div className="">
                        <video  className="w-full rounded-2xl shadow-lg transform hover:scale-105 transition duration-300" 
                                autoPlay 
                                muted 
                                controls
                                >
                            <source src={'https://images.nemu-app.com/nemu_intro.mp4'} type="video/mp4" />
                            Il tuo browser non supporta il tag video.
                        </video>
                    </div>
                </section>

                {/* Sezione per ristoranti */}
                <section className="bg-green-50 p-10 rounded-2xl shadow-xl max-w-5xl mx-auto mb-16">
                    <h3 className="text-3xl font-extrabold mb-6">Sei un ristorante?</h3>

                    <ul className="list-disc pl-6 text-lg  mb-6">
                        <li className="mb-3">Entra nel nostro network caricando il tuo menu e massimizza la tua visibilità</li>

                        <li className="mb-3 items-center list-item">
                            <span className="flex-1 mr-4">Ottieni il QR Code per rendere il tuo menu raggiungibile anche dai tuoi clienti</span>
                            <Button onClick={handleMenuDigitale} type="green" className="ml-4">Menu Digitale</Button>
                        </li>

                        <li className="mb-3 items-center list-item">
                            <span className="flex-1 mr-4">Potresti avere diritto di usufruire dei nostri servizi <strong>gratuitamente e per sempre</strong></span>
                            <Button onClick={scrollToPromo} type="green" className="ml-4">Vai alla promo</Button>
                        </li>
                    </ul>

                </section>


                {/* Sezione Promo */}
                <section id="promo" className="bg-gray-50 p-10 rounded-2xl shadow-xl max-w-5xl mx-auto">
                    <h3 className="text-3xl font-extrabold mb-6">PROMO</h3>
                    <p className="text-lg text-gray-600 mb-4">La nostra piattaforma ha lo scopo di fornire ai suoi utenti un ampio numero di menu di ristoranti per aiutarli a scegliere il miglior posto dove mangiare.</p>
                    <p className="text-lg text-gray-600 mb-4">Proprio per questo motivo, la nostra volontà è di incentivare i ristoranti che aderiranno all'iniziativa per primi, offrendo dei vantaggi esclusivi e per sempre!</p>
                    <p className="text-lg text-gray-600 mb-4">Abbiamo definito tre tipologie di Ristorante:</p>
                    <ul className="list-disc pl-6 text-lg text-gray-600 mb-8">
                        <li className="mb-3"><strong>GOLD:</strong> I primi 3.000 ristoranti che entreranno a far parte del nostro network potranno usufruire dei nostri servizi a costo zero e per sempre!</li>
                        <li className="mb-3"><strong>SILVER:</strong> I 17.000 ristoranti successivi avranno lo status di “Silver” a vita. Tale status comporta uno sconto permanente sui nostri servizi del 70%!</li>
                        <li><strong>BRONZE:</strong> I ristoranti che si iscriveranno dopo i primi 20.000 avranno accesso gratuito per il primo mese, e poi passeranno al prezzo pieno del servizio.</li>
                    </ul>
                    <p className="text-sm text-gray-500 mb-6">(*) Questi numeri sono stati ripartiti su tutte le città italiane, per cui esiste un limite di utenti GOLD e SILVER per ciascuna città.</p>
                    <div className="mt-6">
                        <label htmlFor="province" className="block text-sm font-medium mb-2">Provincia</label>
                        <select
                            id="province"
                            value={selectedProvince}
                            onChange={handleProvinceChange}
                            className={`mt-1 mb-2 p-3 border rounded-lg w-full ${selectedProvince === '' ? 'text-gray-400' : 'text-gray-800'}`}
                        >
                            <option value="">Seleziona la tua provincia</option>
                            {italianProvinces.map((province) => (
                                <option key={province} value={province}>
                                    {province}
                                </option>
                            ))}
                        </select>
                        <Button onClick={handleVerifyClick} type="green">Verifica</Button>

                    </div>
                    {isEligible && (
                        <div className='mt-6 p-4 bg-green-100 rounded-lg text-green-700'>
                            <p className="text-lg font-semibold">Congratulazioni!</p>
                            <p className="mb-2">Hai diritto a diventare Ristorante GOLD a vita! Registrati subito per non perdere questa opportunità.</p>
                            <Button onClick={handleSignUp} type="green">Registrati</Button>
                            {signUp &&
                                <SendMail closeSignUplModal={handleSignUp} />

                            }

                        </div>
                    )}
                </section>
            </main>
        </div>
    );
};

export default HomePage;
