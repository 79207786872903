import React, { useState } from 'react';
import SignUpModal from './SignUpModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { login } from '../redux/loginsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from './modal/Modal';
import SendMail from './SendMail';
import Button from './modal/Buttons'


const Login = () => {
  const googleRoute = process.env.REACT_APP_SERVER_BASE_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [successMessageContainer, setSuccessMessageContainer] = useState(false);
  const [errorMessageContainer, setErrorMessageContainer] = useState(false);
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);

  const isLoading = useSelector((state) => state.logins.isLoading);
  const error = useSelector((state) => state.logins.error);
  const successMessage = useSelector((state) => state.logins.successMessage);

  const [formData, setFormData] = useState({ name: '', subject: '', message: '', userType: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === 'email' ? value.toLowerCase() : value;
    setLoginData({ ...loginData, [name]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(login(loginData));
      if (login.fulfilled.match(response)) {
        setLoginData({ email: '', password: '' });
        setSuccessMessageContainer(true);
        if (errorMessageContainer) {
          setErrorMessageContainer(false);
        }
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      } else {
        setErrorMessageContainer(true);
      }
    } catch (error) {
      console.error('User login failed:', error);
      setErrorMessageContainer(true);
    }
  };

  const handleSignupGoogle = () => {
    // window.location.href = `${googleRoute}/auth/google`;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSendEmail = () => {
    const { name, subject, message } = formData;
    const mailtoLink = `mailto:info@nemu-app.com?subject=${subject} (${name})&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  const openSignUpModal = () => setShowSignUpModal(true);
  const closeSignUplModal = () => setShowSignUpModal(false);

  return (
    <>
      <div className='flex flex-col justify-center items-center h-screen bg-gray-100 p-4'>
        <form
          className='w-full max-w-sm bg-white text-center rounded-lg shadow-md p-6 space-y-4'
        >
          <div className='text-center text-lg font-semibold text-gray-800 mb-4'>Login</div>
          <div>
            <input
              className='w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-green-200 text-sm'
              type='email'
              placeholder='Your Email'
              name='email'
              value={loginData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <input
              className='w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-green-200 text-sm'
              type='password'
              placeholder='Your Password'
              name='password'
              value={loginData.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <Button
            onClick={handleSubmit}
            type='green'
          >
            Login
          </Button>
          <div className='text-center mt-4'>
            <span className='text-sm text-gray-600'>
              Non hai un account?{' '}
              <a
                className='green-background-text cursor-pointer'
                onClick={openSignUpModal}
              >
                Registrati
              </a>
            </span>
          </div>

          {isLoadingContainer && isLoading && <div className='custom-loader my-2'></div>}
          {errorMessageContainer && error && (
            <div className='text-xs text-red-600 p-2'>{error}</div>
          )}
          {successMessageContainer && successMessage && (
            <div className='text-xs text-emerald-400 p-2'>{successMessage}</div>
          )}
        </form>
        {/* 
        <button className='w-full max-w-sm p-3 mt-4 border border-gray-300 rounded-lg flex items-center justify-center text-sm text-gray-600'>
          <FontAwesomeIcon icon={faGoogle} className='mr-2' />
          Continue with Google
        </button>
        */}
        {showSignUpModal && (
          <SendMail closeSignUplModal={closeSignUplModal} />
        )}

      </div>
    </>
  );
};

export default Login;
