import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
const apiUrlMenus = `${process.env.REACT_APP_SERVER_BASE_URL}/menus/`;

export const getAllMenusOwner = createAsyncThunk('menus/owner', async () => {
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.get(`${apiUrlMenus}owner`, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error getting owner menus');
    }
})

export const getMenuOwner = createAsyncThunk('menus/menuId/owner', async (menuId) => {
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.get(`${apiUrlMenus}${menuId}/owner`, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {

        throw new Error('Error getting owner menu');
    }
})

export const updateInitialization = createAsyncThunk('menus/update/initialization/menuId', async ({ menuId, ...menuDetails }, { rejectWithValue }) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        console.log("menuDetails x update: ", menuDetails)
        const response = await axios.patch(`${apiUrlMenus}update/initialization/${menuId}`, menuDetails, {
          headers: { 'Authorization': `${token}` }
          
        });
        console.log("Response:", response.data )

        return response.data;
    } catch (error) {
        if (error.response) {
            // Se c'è un errore di validazione con dettagli specifici
            if (error.response.data && error.response.data.error && error.response.data.error.errors) {
                let errorMessage = error.response.data.error.message; // Accedi al messaggio di errore dettagliato
    
                // Se vuoi aggiungere ulteriori dettagli dagli errori specifici
                for (const key in error.response.data.error.errors) {
                    errorMessage += error.response.data.error.errors[key].message + '\n';
                }
    
                return rejectWithValue(errorMessage);
            }
            // Se c'è un messaggio di errore generico
            else if (error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            }
        }
        // Se non c'è una risposta dall'API, potrebbe essere un errore di rete o altro
        return rejectWithValue(error.message || 'An unknown error occurred');
    }
})

export const initialization = createAsyncThunk('menus/initialization', async ({...menuDetails }, { rejectWithValue }) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.post(`${apiUrlMenus}initialization`, menuDetails, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // Se c'è un errore di validazione con dettagli specifici
            if (error.response.data && error.response.data.error && error.response.data.error.errors) {
                let errorMessage = error.response.data.error.message; // Accedi al messaggio di errore dettagliato
    
                // Se vuoi aggiungere ulteriori dettagli dagli errori specifici
                for (const key in error.response.data.error.errors) {
                    errorMessage += error.response.data.error.errors[key].message + '\n';
                }
    
                return rejectWithValue(errorMessage);
            }
            // Se c'è un messaggio di errore generico
            else if (error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            }
        }
        // Se non c'è una risposta dall'API, potrebbe essere un errore di rete o altro
        return rejectWithValue(error.message || 'An unknown error occurred');
    }
})

export const createMenuCategory = createAsyncThunk('menus/update/menu/category/:menuId', async ({menuId, ...categoryDetails}, { rejectWithValue }) =>{
    try {
        console.log("categoryDetails OUT", categoryDetails)
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.patch(`${apiUrlMenus}update/menu/category/${menuId}`, categoryDetails, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // Se c'è un errore di validazione con dettagli specifici
            if (error.response.data && error.response.data.error && error.response.data.error.errors) {
                let errorMessage = error.response.data.error.message; // Accedi al messaggio di errore dettagliato
    
                // Se vuoi aggiungere ulteriori dettagli dagli errori specifici
                for (const key in error.response.data.error.errors) {
                    errorMessage += error.response.data.error.errors[key].message + '\n';
                }
    
                return rejectWithValue(errorMessage);
            }
            // Se c'è un messaggio di errore generico
            else if (error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            }
        }
        // Se non c'è una risposta dall'API, potrebbe essere un errore di rete o altro
        return rejectWithValue(error.message || 'An unknown error occurred');
    }
})

export const deleteMenuCategory = createAsyncThunk('menus/delete/menuId/categoryId', async ({menuId, categoryId}) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.patch(`${apiUrlMenus}delete/${menuId}/${categoryId}`, {}, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error getting owner menus');
    }
})

export const createMenuItem = createAsyncThunk('menus/update/menu/item/:menuId/:categoryId', async ({menuId, categoryId, ...itemDetails}, { rejectWithValue }) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.patch(`${apiUrlMenus}update/menu/item/${menuId}/${categoryId}`, itemDetails, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // Se c'è un errore di validazione con dettagli specifici
            if (error.response.data && error.response.data.error && error.response.data.error.errors) {
                let errorMessage = error.response.data.error.message; // Accedi al messaggio di errore dettagliato
    
                // Se vuoi aggiungere ulteriori dettagli dagli errori specifici
                for (const key in error.response.data.error.errors) {
                    errorMessage += error.response.data.error.errors[key].message + '\n';
                }
    
                return rejectWithValue(errorMessage);
            }
            // Se c'è un messaggio di errore generico
            else if (error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            }
        }
        // Se non c'è una risposta dall'API, potrebbe essere un errore di rete o altro
        return rejectWithValue(error.message || 'An unknown error occurred');
    }
})

export const deleteMenuItem = createAsyncThunk('menus/delete/menuId/categoryId/itemId', async ({menuId, categoryId, itemId}) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.patch(`${apiUrlMenus}delete/${menuId}/${categoryId}/${itemId}`, {}, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error getting owner menus');
    }
})

export const deleteMenu = createAsyncThunk('menus/delete/menuId', async ({menuId}) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.delete(`${apiUrlMenus}delete/${menuId}`, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error getting owner menus');
    }
})

export const editMenuCategory = createAsyncThunk('menus/update/menuId/categoryId', async ({menuId, categoryId, ...categoryDetails}, { rejectWithValue }) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.patch(`${apiUrlMenus}update/${menuId}/${categoryId}`, categoryDetails, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // Se c'è un errore di validazione con dettagli specifici
            if (error.response.data && error.response.data.error && error.response.data.error.errors) {
                let errorMessage = error.response.data.error.message; // Accedi al messaggio di errore dettagliato
    
                // Se vuoi aggiungere ulteriori dettagli dagli errori specifici
                for (const key in error.response.data.error.errors) {
                    errorMessage += error.response.data.error.errors[key].message + '\n';
                }
    
                return rejectWithValue(errorMessage);
            }
            // Se c'è un messaggio di errore generico
            else if (error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            }
        }
        // Se non c'è una risposta dall'API, potrebbe essere un errore di rete o altro
        return rejectWithValue(error.message || 'An unknown error occurred');
    }
})

export const editMenuItem = createAsyncThunk('menus/update/menuId/categoryId/itemId', async ({menuId, categoryId, itemId, ...itemDetails}, { rejectWithValue }) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response = await axios.patch(`${apiUrlMenus}update/${menuId}/${categoryId}/${itemId}`, itemDetails, {
          headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // Se c'è un errore di validazione con dettagli specifici
            if (error.response.data && error.response.data.error && error.response.data.error.errors) {
                let errorMessage = error.response.data.error.message; // Accedi al messaggio di errore dettagliato
    
                // Se vuoi aggiungere ulteriori dettagli dagli errori specifici
                for (const key in error.response.data.error.errors) {
                    errorMessage += error.response.data.error.errors[key].message + '\n';
                }
    
                return rejectWithValue(errorMessage);
            }
            // Se c'è un messaggio di errore generico
            else if (error.response.data && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            }
        }
        // Se non c'è una risposta dall'API, potrebbe essere un errore di rete o altro
        return rejectWithValue(error.message || 'An unknown error occurred');
    }
})

const menusSlice = createSlice({
    name: 'menus',
    initialState: {
        ownerMenus: null,
        ownerMenusIsLoading: true,
        ownerMenusError: null,
        ownerMenusSuccess: null,
        ownerMenu: null,
        ownerMenuIsLoading: true,
        ownerMenuError: null,
        ownerMenuSuccess: null,
        updateMenuInitialization: null,
        updateMenuInitializationIsLoading: true,
        updateMenuInitializationError: null,
        updateMenuInitializationSuccess: null,
        initialization: null,
        errorInitialization: null,
        successInitialization: null,
        isLoadingInitialization: true,
        menuCategorydelete: null,
        isLoadingMenuCategorydelete: true,
        successMenuCategoryDelete: null,
        errorMenuCategoryDelete: null,
        menuItemdelete: null,
        isLoadingMenuItemdelete: true,
        successMenuItemDelete: null,
        errorMenuItemDelete: null,
        menuDelete: null,
        isLoadingMenuDelete: true,
        successMenuDelete: null,
        errorMenuDelete: null,
        menuCategoryCreation: null,
        menuCategoryCreationIsLoading: true,
        menuCategoryCreationError: null,
        menuCategoryCreationSuccess: null,
        menuCategoryEditing: null,
        menuCategoryEditingIsLoading: true,
        menuCategoryEditingError: null,
        menuCategoryEditingSuccess: null,
        menuItemCreation: null,
        menuItemCreationIsLoading: true,
        menuItemCreationSuccess: null,
        menuItemCreationError: null,
        menuItemEditing: null,
        menuItemEditingIsLoading: true,
        menuItemEditingError: null,
        menuItemEditingSuccess: null,
    },
    reducers:{

    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllMenusOwner.pending, (state, action) => {
            state.ownerMenusIsLoading = true
        })
        .addCase(getAllMenusOwner.rejected, (state, action) => {
            state.ownerMenusError = action.payload
            state.ownerMenusIsLoading = false
        })
        .addCase(getAllMenusOwner.fulfilled, (state, action) => {
            state.ownerMenus = action.payload
            state.ownerMenusSuccess = action.payload.message
            state.ownerMenusIsLoading = false
        })
        .addCase(getMenuOwner.pending, (state, action) => {
            state.ownerMenuIsLoading = true
        })
        .addCase(getMenuOwner.rejected, (state, action) => {
            state.ownerMenuError = action.payload
            state.ownerMenuIsLoading = false
        })
        .addCase(getMenuOwner.fulfilled, (state, action) => {
            state.ownerMenu = action.payload
            state.ownerMenuSuccess = action.payload.message
            state.ownerMenuIsLoading = false
        })
        .addCase(updateInitialization.pending, (state, action) => {
            state.updateMenuInitializationIsLoading = true
        })
        .addCase(updateInitialization.rejected, (state, action) => {
            state.updateMenuInitializationError = action.payload
            state.updateMenuInitializationIsLoading = false
        })
        .addCase(updateInitialization.fulfilled, (state, action) => {
            state.updateMenuInitialization = action.payload
            state.updateMenuInitializationSuccess = action.payload.message
            state.updateMenuInitializationIsLoading = false
        })
        .addCase(initialization.pending, (state, action) => {
            state.isLoadingInitialization = true
        })
        .addCase(initialization.rejected, (state, action) => {
            state.errorInitialization = action.payload
            state.isLoadingInitialization = false
        })
        .addCase(initialization.fulfilled, (state, action) => {
            state.initialization = action.payload
            state.successInitialization = action.payload.message
            state.isLoadingInitialization = false
        })
        .addCase(deleteMenuCategory.pending, (state, action) => {
            state.isLoadingMenuCategorydelete = true
        })
        .addCase(deleteMenuCategory.rejected, (state, action) => {
            state.errorMenuCategoryDelete = action.payload
            state.isLoadingMenuCategorydelete = false
        })
        .addCase(deleteMenuCategory.fulfilled, (state, action) => {
            state.menuCategorydelete = action.payload
            state.successMenuCategoryDelete = action.payload.message
            state.isLoadingMenuCategorydelete = false
        })
        .addCase(deleteMenuItem.pending, (state, action) => {
            state.isLoadingMenuItemdelete = true
        })
        .addCase(deleteMenuItem.rejected, (state, action) => {
            state.errorMenuItemDelete = action.payload
            state.isLoadingMenuItemdelete = false
        })
        .addCase(deleteMenuItem.fulfilled, (state, action) => {
            state.menuItemdelete = action.payload
            state.successMenuItemDelete = action.payload.message
            state.isLoadingMenuItemdelete = false
        })
        .addCase(deleteMenu.pending, (state, action) => {
            state.isLoadingMenuDelete = true
        })
        .addCase(deleteMenu.rejected, (state, action) => {
            state.errorMenuDelete = action.payload
            state.isLoadingMenuDelete = false
        })
        .addCase(deleteMenu.fulfilled, (state, action) => {
            state.menuDelete = action.payload
            state.successMenuDelete = action.payload.message
            state.isLoadingMenuDelete = false
        })
        .addCase(createMenuCategory.pending, (state, action) => {
            state.menuCategoryCreationIsLoading = true
        })
        .addCase(createMenuCategory.rejected, (state, action) => {
            state.menuCategoryCreationError = action.payload
            state.menuCategoryCreationIsLoading = false
        })
        .addCase(createMenuCategory.fulfilled, (state, action) => {
            state.menuCategoryCreation = action.payload
            state.menuCategoryCreationSuccess = action.payload.message
            state.menuCategoryCreationIsLoading = false
        })
        .addCase(editMenuCategory.pending, (state, action) => {
            state.menuCategoryEditingIsLoading = true
        })
        .addCase(editMenuCategory.rejected, (state, action) => {
            state.menuCategoryEditingError = action.payload
            state.menuCategoryEditingIsLoading = false
        })
        .addCase(editMenuCategory.fulfilled, (state, action) => {
            state.menuCategoryEditing = action.payload
            state.menuCategoryEditingSuccess = action.payload.message
            state.menuCategoryEditingIsLoading = false
        })
        .addCase(createMenuItem.pending, (state, action) => {
            state.menuItemCreationIsLoading = true
        })
        .addCase(createMenuItem.rejected, (state, action) => {
            state.menuItemCreationError = action.payload
            state.menuItemCreationIsLoading = false
        })
        .addCase(createMenuItem.fulfilled, (state, action) => {
            state.menuItemCreation = action.payload
            state.menuItemCreationSuccess = action.payload.message
            state.menuItemCreationIsLoading = false
        })
        .addCase(editMenuItem.pending, (state, action) => {
            state.menuItemEditingIsLoading = true
        })
        .addCase(editMenuItem.rejected, (state, action) => {
            state.menuItemEditingError = action.payload
            state.menuItemEditingIsLoading = false
        })
        .addCase(editMenuItem.fulfilled, (state, action) => {
            state.menuItemEditing = action.payload
            state.menuItemEditingSuccess = action.payload.message
            state.menuItemEditingIsLoading = false
        })
    }
});

export default menusSlice.reducer