import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { editMenuItem, deleteMenuItem } from '../redux/menusSlice';
import { FiEdit } from 'react-icons/fi';
import EditMenuItemTranslations from './EditMenuItemTranslations';
import { getIngredients } from '../redux/ingredientsSlice';
import { getTranslatedIngredients } from '../redux/translatedIngredientsSlice'
import { SketchPicker } from 'react-color';
import CreateIngredient from './CreateIngredient';
import AWS from 'aws-sdk';
import axios from 'axios';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';
import Modal from './modal/Modal';
import Language from './modal/Language';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import Translations from './modal/Translations'
import TranslationsNew from './TranslationsNew'
import Input from './modal/Input';

const EditMenuItem = ({ owner, menu, menuId, categoryId, itemId, showEditMenuItem, setShowEditMenuItem, refreshAllMenusOwner, refreshMenuOwner, menuDefaultLanguage }) => {

    const url = process.env.REACT_APP_SERVER_BASE_URL
    const [showEditMenuItemTranslations, setShowEditMenuItemTranslations] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [translations, setTranslations] = useState([]);
    const isLoading = useSelector((state) => state.menus.menuItemEditingIsLoading);
    const errorMessage = useSelector((state) => state.menus.menuItemEditingError);
    const successMessage = useSelector((state) => state.menus.menuItemEditingSuccess);
    const ingredients = useSelector((state) => state.ingredients?.ingredientsOwner?.ingredients);
    const translatedIngredients = useSelector((state) => state.translatedIngredients?.translatedIngredientsOwner?.ingredientTranslations);
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const menuCategoryTarget = menu.menu?.menuCategory?.find(cat => cat._id === categoryId);
    const menuItemTarget = menuCategoryTarget.menuItems?.find(itm => itm._id === itemId);
    const ingredientsIds = menuItemTarget.ingredients?.map(ingredient => ingredient._id);
    const translatedIngredientsIds = menuItemTarget.translatedIngredients?.map(translation => translation._id);
    const [showCreateIngredient, setShowCreateIngredient] = useState(false);
    const categoryIsComplete = menu.menu.menuCategory.find((category) => category._id === categoryId).isComplete;

    const [outputTranslations, setOutputTranslations] = useState([])
    const defaultLanguage = menu.defaultLanguage;
    const [formattedTranslations, setFormattedTranslations] = useState([]);

    const inputTranslations = menu.menu.translations.length > 0
        ? menu.menu.translations.map(lang => {
            const category = lang.translatedMenu.translatedMenuCategory.find(cat => cat.id === categoryId);
            if (category) {
                const item = category.translatedMenuItems.find(itm => itm.id === itemId);
                if (item) {
                    return {
                        language: lang.language,
                        translatedName: item.translatedName || '',
                        translatedDescription: item.translatedDescription || ''
                    };
                }
            }
            return null;
        }).filter(item => item !== null)
        : [];

    useEffect(() => {
        const formattedTranslations = outputTranslations.map(lang => ({
            language: lang.language,
            translatedName: lang.translatedName,
            translatedDescription: lang.translatedDescription
        }));

        setItemDetails(prevItemDetails => ({
            ...prevItemDetails,
            translations: formattedTranslations || [],
        }));
    }, [outputTranslations]);

    const [inputImage, setInputImage] = useState(menuItemTarget.image || '');
    const [uploadedFileName, setUploadedFileName] = useState(menuItemTarget.image || '');

    useEffect(() => {
        const category = menu.menu.menuCategory.find(cat => cat._id === categoryId);
        if (category) {
            const item = category.menuItems.find(item => item._id === itemId);
            if (item.image !== "false") {
                const itemImage = item ? item.image : null;
                setInputImage(itemImage);
                setUploadedFileName(itemImage);
            }
        }
    }, [menu, categoryId, itemId]);

    useEffect(() => {
        setItemDetails((prevState) => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                image: uploadedFileName
            }
        }));
    }, [uploadedFileName]);

    const handleRemoveImage = () => {
        setInputImage('');
        setUploadedFileName('');
        setItemDetails((prevState) => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                image: ''
            }
        }));
    };

    const handleShowCreateIngredient = () => {
        setShowCreateIngredient(true)
    };

    const refreshIngredientMenuDefaultLanguage = () => {
        dispatch(getTranslatedIngredients(menuDefaultLanguage))
    };

    const allergensMapping = [
        { eng: 'gluten', cmn: '麸质', spa: 'gluten', fra: 'gluten', deu: 'Gluten', jpn: 'グルテン', rus: 'глютен', por: 'glúten', ara: 'غلوتين', nld: 'gluten', swe: 'gluten', ita: 'glutine' },
        { eng: 'shellfish', cmn: '壳fish', spa: 'mariscos', fra: 'crustacés', deu: 'Schalentiere', jpn: '甲殻類', rus: 'ракообразные', por: 'crustáceos', ara: 'قشريات', nld: 'schaaldieren', swe: 'skaldjur', ita: 'crostacei' },
        { eng: 'eggs', cmn: '蛋', spa: 'huevos', fra: 'œufs', deu: 'Eier', jpn: '卵', rus: 'яйца', por: 'ovos', ara: 'بيض', nld: 'eieren', swe: 'ägg', ita: 'uova' },
        { eng: 'fish', cmn: '鱼', spa: 'pescado', fra: 'poisson', deu: 'Fisch', jpn: '魚', rus: 'рыба', por: 'peixe', ara: 'سمك', nld: 'vis', swe: 'fisk', ita: 'pesce' },
        { eng: 'peanuts', cmn: '花生', spa: 'cacahuetes', fra: 'arachides', deu: 'Erdnüsse', jpn: 'ピーナッツ', rus: 'арахис', por: 'amendoins', ara: 'فول سوداني', nld: 'pinda', swe: 'jordnötter', ita: 'arachidi' },
        { eng: 'soy', cmn: '大豆', spa: 'soja', fra: 'soja', deu: 'Soja', jpn: '大豆', rus: 'соя', por: 'soja', ara: 'صويا', nld: 'soja', swe: 'soja', ita: 'soia' },
        { eng: 'milk', cmn: '牛奶', spa: 'leche', fra: 'lait', deu: 'Milch', jpn: '牛乳', rus: 'молоко', por: 'leite', ara: 'حليب', nld: 'melk', swe: 'mjölk', ita: 'latte' },
        { eng: 'nuts', cmn: '坚果', spa: 'frutos secos', fra: 'noix', deu: 'Nüsse', jpn: 'ナッツ', rus: 'орехи', por: 'nozes', ara: 'مكسرات', nld: 'noten', swe: 'nötter', ita: 'frutta a guscio' },
        { eng: 'celery', cmn: '芹菜', spa: 'apio', fra: 'céleri', deu: 'Sellerie', jpn: 'セロリ', rus: 'сельдерей', por: 'aipo', ara: 'كرفس', nld: 'selderij', swe: 'selleri', ita: 'sedano' },
        { eng: 'mustard', cmn: '芥末', spa: 'mostaza', fra: 'moutarde', deu: 'Senf', jpn: 'マスタード', rus: 'горчица', por: 'mostarda', ara: 'خردل', nld: 'mosterd', swe: 'senap', ita: 'senape' },
        { eng: 'sesame', cmn: '芝麻', spa: 'sésamo', fra: 'sésame', deu: 'Sesam', jpn: 'ゴマ', rus: 'кунжут', por: 'sésamo', ara: 'سمسم', nld: 'sesam', swe: 'sesam', ita: 'sesamo' },
        { eng: 'sulfur dioxide', cmn: '二氧化硫', spa: 'dióxido de azufre', fra: 'dioxyde de soufre', deu: 'Schwefeldioxid', jpn: '二酸化硫黄', rus: 'диоксид серы', por: 'dióxido de enxofre', ara: 'ثاني أكسيد الكبريت', nld: 'zwaveldioxide', swe: 'svaveldioxid', ita: 'anidride solforosa' },
        { eng: 'lupins', cmn: '羽扇豆', spa: 'lupins', fra: 'lupins', deu: 'Lupinen', jpn: 'ルピナス', rus: 'люпины', por: 'tremoços', ara: 'ترمس', nld: 'lupine', swe: 'lupiner', ita: 'lupini' },
        { eng: 'molluscs', cmn: '软体动物', spa: 'moluscos', fra: 'mollusques', deu: 'Weichtiere', jpn: '軟体動物', rus: 'моллюски', por: 'moluscos', ara: 'رخويات', nld: 'weekdieren', swe: 'blötdjur', ita: 'molluschi' },
        { eng: 'no allergens', cmn: '无过敏原', spa: 'sin alérgenos', fra: 'sans allergènes', deu: 'keine Allergene', jpn: 'アレルゲンなし', rus: 'без аллергенов', por: 'sem alérgenos', ara: 'بدون مسببات الحساسية', nld: 'geen allergenen', swe: 'inga allergener', ita: 'nessun allergene' }
    ];

    const [itemDetails, setItemDetails] = useState({
        menuItem: {
            foodOrBeverage: menuItemTarget.foodOrBeverage,
            name: menuItemTarget.name || '',
            description: menuItemTarget.description || '',
            color: menuItemTarget.color || '',
            image: menuItemTarget.image || '',
            price: menuItemTarget.price || '',
            isActive: menuItemTarget.isActive || false,
            isVegetarian: menuItemTarget.isVegetarian || false,
            isVegan: menuItemTarget.isVegan || false,
            isMeat: menuItemTarget.isMeat || false,
            isFish: menuItemTarget.isFish || false,
            translatedIngredients: translatedIngredientsIds || '',
            ingredients: ingredientsIds || '',
            allergens: menuItemTarget.allergens || '',
        }
    });

    useEffect(() => {
        if (menuItemTarget) {
            if (ingredients && translatedIngredients) {
                setItemDetails({
                    menuItem: {
                        foodOrBeverage: menuItemTarget.foodOrBeverage,
                        name: menuItemTarget.name || '',
                        description: menuItemTarget.description || '',
                        color: menuItemTarget.color || '',
                        image: menuItemTarget.image || '',
                        price: menuItemTarget.price || '',
                        isActive: menuItemTarget.isActive || false,
                        isVegetarian: menuItemTarget.isVegetarian || false,
                        isVegan: menuItemTarget.isVegan || false,
                        isMeat: menuItemTarget.isMeat || false,
                        isFish: menuItemTarget.isFish || false,
                        translatedIngredients: translatedIngredientsIds || '',
                        ingredients: ingredientsIds || '',
                        allergens: menuItemTarget.allergens || [],
                    }
                });
            }
        }
    }, [menuItemTarget, ingredients, translatedIngredients]);

    useEffect(() => {
        if (menuDefaultLanguage === 'eng') {
            dispatch(getIngredients())
        } else {
            dispatch(getTranslatedIngredients(menuDefaultLanguage))
        }
    }, []);

    const allergenOptions = allergensMapping?.map(allergen => ({
        value: allergen[menuDefaultLanguage],
        label: allergen[menuDefaultLanguage]
    }));

    const initialAllergens = itemDetails.menuItem.allergens?.map(allergen => ({
        value: allergen,
        label: allergen
    }));

    const handleAllergenChange = (selectedOptions) => {
        const selectedAllergenValues = selectedOptions?.map(option => option.value);

        setItemDetails(prev => ({
            ...prev,
            menuItem: {
                ...prev.menuItem,
                allergens: selectedAllergenValues
            }
        }));
    };

    const ingredientOptions = (menuDefaultLanguage === 'eng' ? ingredients : translatedIngredients)?.map(ingredient => ({
        value: ingredient._id,
        label: menuDefaultLanguage === 'eng' ? ingredient.name : ingredient.translatedName
    }));

    const currentIngredientsValue = menuDefaultLanguage === 'eng' ?
        itemDetails.menuItem.ingredients?.map(id => ({
            value: id,
            label: (ingredients?.find(ingredient => ingredient._id === id) || {}).name
        })) :
        itemDetails.menuItem.translatedIngredients?.map(id => ({
            value: id,
            label: (translatedIngredients?.find(ingredient => ingredient._id === id) || {}).translatedName
        }));

    const selectIngredient = (selectedOptions) => {
        const selectedIds = selectedOptions?.map(option => option.value);
        setItemDetails(prev => ({
            ...prev,
            menuItem: {
                ...prev.menuItem,
                [menuDefaultLanguage === 'eng' ? 'ingredients' : 'translatedIngredients']: selectedIds
            }
        }));
    };

    const handleShowEditMenuItemTranslations = () => {
        setShowEditMenuItemTranslations(true)
    };

    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadMessage, setUploadMessage] = useState(false)
    const [uploadError, setUploadError] = useState(false);

    useEffect(() => {
        if (uploadedFileName) {
            axios.get(`${url}/imageurl/${uploadedFileName}`)
                .then(response => {
                    if (response.status === 200 && response.data.url) {
                    }
                })
                .catch(error => {
                });
        }
    }, [uploadedFileName]);

    const handleFileInput = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const formData = new FormData();
        formData.append('menuItem', file);

        if (!menuItemTarget.image) {
            try {
                const response = await axios.post(`${url}/upload-menuitem`, formData);
                const uploadedFileName = response.data.url.split('/').pop();
                setUploadedFileName(uploadedFileName);
                setUploadMessage(true);
            } catch (error) {
                setUploadError(true);
            }
        } else {
            try {
                const response = await axios.post(`${url}/update-menuitem/${menuItemTarget.image}`, formData);
                const uploadedFileName = response.data.url.split('/').pop();
                setUploadedFileName(uploadedFileName);
                setUploadMessage(true);
            } catch (error) {
                setUploadError(true);
            }
        }
    };

    const [showPicker, setShowPicker] = useState(false);
    const handleColorPickerOpen = () => setShowPicker(true);
    const handleColorPickerClose = () => setShowPicker(false);
    const [color, setColor] = useState(menuItemTarget.color || '');

    const handleColorChange = (color) => {
        setColor(color.hex);
        setItemDetails(prevState => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                color: color.hex
            }
        }));
    };

    const resetPreviousColor = () => {
        setColor(menuItemTarget.color);
        setItemDetails(prevState => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                color: menuItemTarget.color
            }
        }));
    };

    const deleteColor = () => {
        setColor('');
        setItemDetails(prevState => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                color: ''
            }
        }));
    };

    const closeEditMenuItem = () => {
        setShowEditMenuItem(false)
    };

    const dispatch = useDispatch();

    const flagImages = {
        ita: itaFlag,
        fra: fraFlag,
        spa: spaFlag,
        eng: engFlag,
        ara: araFlag,
        cmn: cmnFlag,
        jpn: jpnFlag,
        rus: rusFlag,
        por: porFlag,
        nld: nldFlag,
        swe: sweFlag,
        deu: deuFlag
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        setItemDetails(prevState => ({
            ...prevState,
            menuItem: {
                ...prevState.menuItem,
                [name.split('.').pop()]: inputValue
            }
        }));
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        setError(false);
        setSuccess(false);
        event.preventDefault();
        try {
            if (itemDetails.menuItem.foodOrBeverage === 'beverage') {
                let itemDetailsToEdit = { ...itemDetails };
                delete itemDetailsToEdit.menuItem.ingredients;
                delete itemDetailsToEdit.menuItem.translatedIngredients;
                delete itemDetailsToEdit.menuItem.allergens;

                const response = await dispatch(editMenuItem({ menuId, categoryId, itemId, ...itemDetailsToEdit }));
                if (editMenuItem.fulfilled.match(response)) {
                    setLoading(false);
                    setSuccess(true);
                    if (error) {
                        setError(false)
                    }
                    setTimeout(() => {
                        refreshMenuOwner(menuId);
                        refreshAllMenusOwner();
                        closeEditMenuItem();
                    }, 2000);
                } else {
                    setLoading(false);
                    setError(true);
                }
            } else {
                const response = await dispatch(editMenuItem({ menuId, categoryId, itemId, ...itemDetails }));
                if (editMenuItem.fulfilled.match(response)) {
                    setLoading(false);
                    setSuccess(true);
                    if (error) {
                        setError(false)
                    }
                    setTimeout(() => {
                        refreshMenuOwner(menuId);
                        refreshAllMenusOwner();
                        closeEditMenuItem();
                    }, 2000);
                } else {
                    setLoading(false);
                    setError(true);
                }
            }
        } catch (error) {
        }
    };

    const getHandleDeleteMenuItem = (menuId, categoryId, itemId) => async () => {
        const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo piatto?");
        if (confirmDelete) {
            await handleDeleteMenuItem(menuId, categoryId, itemId);
        };
    };

    const handleDeleteMenuItem = async (menuId, categoryId, itemId) => {
        setLoading(true);
        const response = await dispatch(deleteMenuItem({ menuId, categoryId, itemId }));
        if (deleteMenuItem.fulfilled.match(response)) {
            await refreshMenuOwner();
            setLoading(false);
        }
        setShowEditMenuItem(false);
    };

    return (
        <>
            {showEditMenuItem &&
                <Modal handleSubmit={handleSubmit}
                    closeFunction={closeEditMenuItem}
                    title="Modifica Piatto"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                    deleteFunction={getHandleDeleteMenuItem(menuId, categoryId, itemId)}
                >
                    <Input description={itemDetails.menuItem.name || ''}
                        name='menuItem.name'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Nome</Input>

                    <Input description={itemDetails.menuItem.description || ''}
                        name='menuItem.description'
                        onChange={handleInputChange}
                        tipo="InputText"
                    >Descrizione</Input>

                    <hr className="border-gray-400 mt-8"></hr>

                    <TranslationsNew
                        defaultLanguage={defaultLanguage}
                        inputTranslations={inputTranslations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></TranslationsNew>

                    <hr className="border-gray-400 mt-8"></hr>

                    <div className='mt-10 flex flex-col items-center gap-2 my-1'>
                        <div className='flex flex-row items-center flex-wrap text-xl '>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isActive}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"
                                name="menuItem.isActive"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Attivo</span>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isVegetarian}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"
                                name="menuItem.isVegetarian"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Vegetariano</span>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isVegan}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"
                                name="menuItem.isVegan"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Vegano</span>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isMeat}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"
                                name="menuItem.isMeat"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Carne</span>
                            <input
                                type="checkbox"
                                checked={itemDetails.menuItem.isFish}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"
                                name="menuItem.isFish"
                                onChange={handleInputChange}
                            />
                            <span className="me-2 text-gray-700">Pesce</span>
                        </div>
                    </div>

                    <hr className="border-gray-400 mt-8"></hr>

                    <div className="flex flex-row item-center mt-8">
                        <div className='flex flex-col items-start'>
                            {!categoryIsComplete && (
                                <div className='flex flex-col md:flex-row justify-between gap-2 items-center my-1'>
                                    <h3 className='font-bold'>Prezzo</h3>
                                    <div className=' p-2 w-40 flex flex-row justify-between items-center'>
                                        <input
                                            className='border rounded-lg border-gray-400 p-2 bg-transparent'
                                            value={itemDetails.menuItem.price}
                                            name='menuItem.price'
                                            onChange={handleInputChange}
                                            type='number'
                                            min="0"
                                            step="any"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <hr className="border-gray-400 mt-8"></hr>

                    <div className='flex flex-col items-start'>
                        <ColorSection
                            handleColorChange={handleColorChange}
                            deleteColor={deleteColor}
                            color={color}
                        ></ColorSection>

                        <ImageSection
                            inputImage={inputImage}
                            handleRemoveImage={handleRemoveImage}
                            uploadedFileName={uploadedFileName}
                            setUploadedFileName={setUploadedFileName}
                            aspect={2 / 1}
                            maxSizeMB={0.1}
                            maxWidthOrHeight={1200}
                        ></ImageSection>
                    </div>

                    <hr className="border-gray-400 mt-8"></hr>

                    <div className='flex flex-col items-start'>
                        <div className='flex flex-col md:flex-row items-center justify-center gap-2 my-1 mt-8'>
                            <span className='font-bold'>Food or beverage</span>
                            <select
                                className='border rounded-lg border-gray-400 p-2 bg-transparent'
                                name="menuItem.foodOrBeverage"
                                value={itemDetails.menuItem.foodOrBeverage}
                                onChange={handleInputChange}
                            >
                                <option value="food">Food</option>
                                <option value="beverage">Beverage</option>
                            </select>
                        </div>
                    </div>

                    {itemDetails.menuItem.foodOrBeverage === 'food' &&
                        <>
                            <div className='flex flex-col mt-4 '>
                                <div className='grid grid-cols-12 gap-2 items-center'>
                                    <h3 className='font-bold col-span-2 text-start'>Ingredienti</h3>
                                    <Select
                                        isMulti
                                        name="ingredients"
                                        options={ingredientOptions}
                                        className="basic-multi-select col-span-9"
                                        classNamePrefix="select"
                                        value={currentIngredientsValue}
                                        onChange={selectIngredient}
                                        placeholder="Cerca ingredienti..."
                                    />
                                    <button className="font-primary w-8 h-8 rounded-lg green-background text-center text-white text-xl cursor-pointer col-span-1"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleShowCreateIngredient();
                                        }}
                                    >+</button>
                                </div>
                            </div>
                        </>
                    }

                    {showCreateIngredient && (
                        <CreateIngredient
                            showIngredientCreation={showCreateIngredient}
                            setShowIngredientCreation={setShowCreateIngredient}
                            menuDefaultLanguage={menuDefaultLanguage}
                            ingredients={ingredients}
                            translatedIngredients={translatedIngredients}
                            refreshIngredientMenuDefaultLanguage={refreshIngredientMenuDefaultLanguage}
                        />
                    )}

                    <div className='grid grid-cols-12 md:flex-row justify-between gap-2 items-center my-1 mt-4 w-full'>
                        <h3 className='font-bold col-span-2 text-start'>Allergeni</h3>
                        <Select
                            isMulti
                            name="allergens"
                            options={allergenOptions}
                            className="basic-multi-select col-span-9"
                            classNamePrefix="select"
                            value={initialAllergens}
                            onChange={handleAllergenChange}
                            placeholder="Seleziona allergeni..."
                        />
                    </div>

                    <div className='flex flex-row justify-center gap-2'></div>

                </Modal>
            }
        </>
    );
}

export default EditMenuItem;
