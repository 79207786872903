import React, { useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import Homepage from "../pages/Homepage";
import {Outlet, useNavigate } from 'react-router-dom';

const auth = () => {
    return JSON.parse(localStorage.getItem('userLoggedIn'));
};

const useSession = () => {
    const session = auth();
    const decodedSession = session ? jwtDecode(session) : null;

    const navigate = useNavigate();

    useEffect(() => {
        if (!session) {
            console.log('session no auth')
            navigate('/', { replace: true });
        } else {
            const currentTime = Date.now() / 1000;
            if (decodedSession && decodedSession.exp < currentTime) {
                console.log('token scaduto')
                navigate('/', { replace: true });
            }
        }
    }, [navigate, session, decodedSession]);

    return decodedSession;
};

const ProtectedRoutes = () => {
    const isAuthorized = auth();
    const session = useSession();

    return isAuthorized ? <Outlet /> : <Homepage />;
}

export default ProtectedRoutes;