import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import nemuPosterMenuVideo from '../asset/Restaurant.jpg';
import nemuPosterConfVideo from '../asset/Cliente.jpg';
import SendMail from '../components/SendMail';
import Button from '../components/modal/Buttons'


const MenuDigitalePage = () => {
    const [showSendMail, setShowSendMail] = useState(false);
    const navigate = useNavigate();


    const handleRegisterClick = () => {
        setShowSendMail(true);
    };

    const handleCloseSendMail = () => {
        setShowSendMail(false);
    };

    const handleGoToPromo = () => {
        navigate('/#promo');

    };

    return (
        <div className="container mx-auto p-4 md:p-2 mt-8 max-w-5xl green-background-text">
            <main>
                {/* Prima sezione */}
                <section className="bg-green-50 p-10 rounded-2xl shadow-xl flex flex-col md:flex-row items-center gap-8 mb-16 mt-12">
                    <div className="md:w-1/3">
                        <h2 className="text-3xl font-extrabold mb-6">Personalizza il tuo menu con un click</h2>
                        <ul className="list-disc pl-5 text-lg text-gray-600">
                            <li className="mb-3">Menu digitale con interfaccia intuitiva e facile da usare</li>
                            <li className="mb-3">Creazione e aggiornamento del menu in tempo reale</li>
                            <li className="mb-3">Gestione multilingue</li>
                            <li className="mb-3">Informazioni su ingredienti e allergeni</li>
                            <li>Gratuito a vita per i primi ristoranti che aderiranno</li>
                        </ul>
                    </div>
                    <div className="md:w-2/3">
                        <video  className="w-full rounded-2xl shadow-lg transform hover:scale-105 transition duration-300" 
                                muted 
                                controls
                                poster={nemuPosterMenuVideo}
                                >
                            <source src={'https://images.nemu-app.com/nemu_demo_configuratore.mp4'} type="video/mp4" />
                            Il tuo browser non supporta il tag video.
                        </video>
                    </div>
                </section>

                {/* Seconda sezione */}
                <section className="bg-gray-50 p-10 rounded-2xl shadow-xl flex flex-col md:flex-row items-center gap-8 mb-16">
                    <div className="md:w-2/3">
                        <video  className="w-full rounded-2xl shadow-lg transform hover:scale-105 transition duration-300"
                                muted 
                                controls
                                poster={nemuPosterConfVideo}
                                >
                            <source src={'https://images.nemu-app.com/nemu_demo_menu.mp4'} type="video/mp4" />
                            Il tuo browser non supporta il tag video.
                        </video>
                    </div>
                    <div className="md:w-1/3">
                        <h3 className="text-3xl font-extrabold mb-6">Il menu interattivo di nemù trasforma l'esperienza del cliente</h3>
                        <ul className="list-disc pl-5 text-lg text-gray-600">
                            <li className="mb-3">Filtri personalizzati per ingredienti e allergeni</li>
                            <li className="mb-3">Interfaccia utente coinvolgente e facile da navigare</li>
                            <li>Accesso immediato a informazioni dettagliate sui piatti</li>
                        </ul>
                    </div>

                </section>

                {/* Terza sezione (senza video) */}
                <section className="bg-white p-10 rounded-2xl shadow-xl mb-16">
                    <h3 className="text-3xl font-extrabold mb-6">Unisciti alla nostra piattaforma e ottieni un menu digitale con QR Code</h3>
                    <p className="text-lg  mb-4">Come funziona?</p>
                    <ul className="list-disc pl-5 text-lg  mb-8">
                        <li className="mb-3">Compila il modulo di registrazione e crea il profilo del tuo ristorante</li>
                        <li className="mb-3">Inserisci i dettagli del tuo menu, inclusi ingredienti e allergeni</li>
                        <li>Genera automaticamente il menu digitale con QR Code da utilizzare nel tuo ristorante</li>
                    </ul>
                    <p className="text-lg  font-semibold mb-6">ATTENZIONE: Potresti avere diritto di usufruire dei nostri servizi <strong>gratuitamente e per sempre</strong>.</p>
                    <div className="flex gap-4">
                        <Button type="green" onClick={handleGoToPromo}>
                            Vai alla promo
                        </Button>
                        <Button type="green" onClick={handleRegisterClick}>
                            Registrati
                        </Button>
                    </div>
                </section>

                {showSendMail && <SendMail closeSignUplModal={handleCloseSendMail} />}
            </main>
        </div>
    );
};

export default MenuDigitalePage;
