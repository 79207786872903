import React from 'react';

const TimeSlots = ({ 
    timeSlots, 
    handleDayToggle, 
    handleTimeSlotChange, 
    handleRemoveTimeSlot, 
    handleAddTimeSlot 
}) => {
    const weekDays = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

    return (
        <>
            {weekDays.map((day, dayIndex) => {
                const timeSlot = timeSlots.find(slot => slot.day === dayIndex) || { day: dayIndex, slots: [] };

                return (
                    <div key={dayIndex} className='flex flex-col items-start gap-2 my-2'>
                        <div className='flex items-center'>
                            <input
                                type="checkbox"
                                checked={timeSlots.some(slot => slot.day === dayIndex)}
                                className="form-checkbox h-2 w-2 text-gray-600 text-xs mr-2"
                                onChange={() => handleDayToggle(dayIndex)}
                            />
                            <span className='font-bold'>{day}</span>
                        </div>

                        {timeSlots.some(slot => slot.day === dayIndex) && (
                            <>
                                {timeSlot.slots.map((slot, slotIndex) => (
                                    <div key={slot._id || `slot-${dayIndex}-${slotIndex}`} className='flex items-center gap-2'>
                                        <input
                                            type="time"
                                            value={slot.startTime || ""}
                                            onChange={(e) => handleTimeSlotChange(dayIndex, slotIndex, 'startTime', e.target.value)}
                                            className='bg-transparent'
                                        />
                                        <span className='font-bold'>-</span>
                                        <input
                                            type="time"
                                            value={slot.endTime || ""}
                                            onChange={(e) => handleTimeSlotChange(dayIndex, slotIndex, 'endTime', e.target.value)}
                                            className='bg-transparent'
                                        />
                                        <button onClick={() => handleRemoveTimeSlot(dayIndex, slotIndex)} className='text-red-500'>
                                            Rimuovi
                                        </button>
                                    </div>
                                ))}
                                <button onClick={() => handleAddTimeSlot(dayIndex)} className='text-blue-500'>
                                    Aggiungi Fascia Oraria
                                </button>
                            </>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default TimeSlots;
