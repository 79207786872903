
import React, {useState, useEffect} from 'react';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';

const CreateMenuItemTranslations = ({menu, itemDetails, translations, setTranslations, showCreateMenuItemTranslations, setShowCreateMenuItemTranslations, menuDefaultLanguage}) => {

    const flagImages = {
        ita: itaFlag,
        fra: fraFlag,
        spa: spaFlag,
        eng: engFlag,
        ara: araFlag,
        cmn: cmnFlag,
        jpn: jpnFlag,
        rus: rusFlag,
        por: porFlag,
        nld: nldFlag,
        swe: sweFlag,
        deu: deuFlag
    };

// Prendi le lingue disponibili per le traduzioni del menu, escludendo la lingua predefinita
const translationLanguages = menu.menu.translations.map(t => t.language);

// Crea un dizionario per traduzioni esistenti, per accedere facilmente
const existingTranslations = translations.reduce((acc, translation) => {
    acc[translation.language] = translation;
    return acc;
}, {});

// Inizializza lo stato per le nuove traduzioni
const [newTranslations, setNewTranslations] = useState(
    translationLanguages.reduce((acc, lang) => {
        acc[lang] = { 
            translatedName: existingTranslations[lang]?.translatedName || '',
            translatedDescription: existingTranslations[lang]?.translatedDescription || '' 
        };
        return acc;
    }, {})
);

const handleTranslationChange = (lang, field) => (e) => {
    setNewTranslations(prevTranslations => ({
        ...prevTranslations,
        [lang]: {
            ...prevTranslations[lang],
            [field]: e.target.value
        }
    }));
};

const handleSaveTranslations = () => {
    const finalTranslations = translationLanguages.map(lang => ({
        language: lang,
        translatedName: newTranslations[lang].translatedName,
        translatedDescription: newTranslations[lang].translatedDescription
    }));
    setTranslations(finalTranslations);
    setShowCreateMenuItemTranslations(false);
};

const closeCreateMenuItemTranslations = () => {
    setShowCreateMenuItemTranslations(false);
};

console.log('TRADUZIONI SECONDO MODALE', translations)
return (
    <>
    {showCreateMenuItemTranslations && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-90 flex items-center justify-center z-10 overflow-y-auto">
            <form className='font-primary form text-black text-center bg-gray-300 border w-9/12 lg:h-5/6 max-h-[80vh] rounded-lg p-5 overflow-y-auto'>
                <div className='text-black font-primary font-bold'>Traduzioni Piatto</div>
                <div className='flex flex-col items-center lg:flex lg:flex-row lg:justify-around lg:items-center lg:flex-wrap gap-1 mt-5'>
                {translationLanguages.map(lang => (
                    <div key={lang} className='flex flex-col items-center p-2'>
                        <img src={flagImages[lang]} alt={`${lang} flag`} className="w-20 rounded-lg" />
                        <input
                            type="text"
                            placeholder="Nome"
                            className="border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                            value={newTranslations[lang].translatedName }
                            onChange={handleTranslationChange(lang, 'translatedName')}
                        />
                        {newTranslations[lang].translatedDescription && !newTranslations[lang].translatedName && (
                            <span className="text-red-500 text-sm">Il nome è richiesto</span>
                        )}
                        <input
                            type="text"
                            placeholder="Descrizione"
                            className="border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                            value={newTranslations[lang].translatedDescription}
                            onChange={handleTranslationChange(lang, 'translatedDescription')}
                        />
                        {newTranslations[lang].translatedName && !newTranslations[lang].translatedDescription && (
                            <span className="text-red-500 text-sm">La descrizione è richiesta</span>
                        )}
                    </div>
                ))}
                </div>
                <div className='flex flex-row justify-center mt-4'>
                    <button className="p-2 w-40 rounded-lg black-background text-white text-sm" onClick={closeCreateMenuItemTranslations}>Chiudi</button>
                    <button className="ml-4 p-2 w-40 rounded-lg green-background text-white text-sm" onClick={handleSaveTranslations}>Salva</button>
                </div>
            </form>
        </div>
    )}
    </>
);
  
}

export default CreateMenuItemTranslations