import React from 'react';
import { XMarkIcon } from "@heroicons/react/24/outline";


const Modal = ({ children, closeFunction, undoFunction, deleteFunction, handleSubmit, title, success, successMessage, error, errorMessage, isLoading, loading
    

 }) => {
    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-90 flex items-start  justify-center z-10 ">
            <div className='lg:mt-20 mt-16 font-primary form text-black text-center bg-white border lg:w-8/12 h-[83vh] w-11/12 rounded-lg p-5 overflow-y-auto'  >
                <div className='flex justify-between items-center  '>
                    <h1 className="text-black font-primary font-bold text-2xl">{title}</h1>
                    <XMarkIcon className="w-8 text-end" onClick={closeFunction}></XMarkIcon>
                </div>

                <hr className="border-gray-400 mt-8"></hr>

                {children}

                {undoFunction &&
                    <button className="mt-6  mx-2 p-2 font-primary w-40 rounded-lg black-background text-center text-white text-sm" onClick={undoFunction}>Annulla</button>
                }

                {handleSubmit && 
                    <button className={`mt-6  mx-2 p-2 font-primary w-40 rounded-lg green-background text-center text-white text-sm `} type="submit" onClick={handleSubmit} >Salva</button>
                }
               {deleteFunction &&
                    <button className="p-2 mt-6 mx-2 font-primary w-28  rounded-lg bg-red-700  text-center text-white text-sm " onClick={deleteFunction}> Elimina </button>
                }

                {isLoading && loading &&
                    <div className='flex flex-row justify-center gap-2 pt-2 my-4'><div className='custom-loader'></div></div>
                }
                {success && successMessage &&
                    <div className='flex flex-row justify-center gap-2 font-light text-emerald-400 p-2 text-xs my-4'>{successMessage}</div>
                }
                {error && errorMessage &&
                    <div className='flex flex-row justify-center gap-2 font-light text-red-600 p-2 text-xs my-4' >{errorMessage}</div>
                }

            </div>


        </div>

    );
}

export default Modal;
