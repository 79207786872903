import React from 'react';

const Input = ({ children, description, name, tipo, onChange }) => {
    return (
        <>
            {tipo === "InputText" &&
                <div className='text-start mt-4'>
                    <div className='font-bold'>{children}</div>
                    <div className=''>
                        <input
                            className='border w-full rounded-lg border-gray-200 p-2 bg-transparent'
                            value={description}
                            name={name}
                            onChange={onChange}
                        />
                    </div>
                </div>}


        </>
    )
}

export default Input;
