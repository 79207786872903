
import React, { useEffect, useState, useRef } from 'react';
import itaFlag from '../asset/ita.jpg';
import fraFlag from '../asset/fra.jpg';
import spaFlag from '../asset/spa.jpg';
import engFlag from '../asset/eng.jpg';
import araFlag from '../asset/ara.jpg';
import cmnFlag from '../asset/cmn.jpg';
import jpnFlag from '../asset/jpn.jpg';
import rusFlag from '../asset/rus.jpg';
import porFlag from '../asset/por.jpg';
import nldFlag from '../asset/nld.jpg';
import sweFlag from '../asset/swe.jpg';
import deuFlag from '../asset/deu.jpg';
import { FiEdit } from 'react-icons/fi';
import _ from 'lodash'; 




const TranslationsNew = ({ languages, defaultLanguage, handleLanguageChange, inputTranslations, outputTranslations, setOutputTranslations }) => {

    const flagImages = {
        ita: itaFlag,
        fra: fraFlag,
        spa: spaFlag,
        eng: engFlag,
        ara: araFlag,
        cmn: cmnFlag,
        jpn: jpnFlag,
        rus: rusFlag,
        por: porFlag,
        nld: nldFlag,
        swe: sweFlag,
        deu: deuFlag
    };

    const [showCreateTranslations, setShowCreateTranslations] = useState(false);

    const handleTranslations = () => {
        setShowCreateTranslations(true)
    }

    const closeCreateTranslations = (e) => {
        e.preventDefault();
        setShowCreateTranslations(false);
    }
    
    const [tempTranslations, setTempTranslations] = useState(inputTranslations);
    const [formData, setFormData] = useState(inputTranslations);


    useEffect(() => {
        if (!_.isEqual(formData, inputTranslations)) {
            setTempTranslations(inputTranslations);
            setFormData(inputTranslations);

        }
    }, [inputTranslations]);


    const handleTranslationChange = (index, field, value) => {
        setTempTranslations(prevTranslations =>
            prevTranslations.map((item, idx) =>
                idx === index ? { ...item, [field]: value } : item
            )
        );
    };



    const handleSaveTranslations = (e) => {
        e.preventDefault();
        setOutputTranslations(tempTranslations);
        setShowCreateTranslations(false);
    }
    
    // Use Effect per mettere Italiano di default. Quando andremo all'estero potremo aprire questa possibilità commentando questa parte
    useEffect(() => {
        if (handleLanguageChange) {
        const event = { target: { value: 'ita' } };
        handleLanguageChange(event)
    }
    },[])

return (
    <>
        <div className='text-start flex items-center mt-4'>

            {!defaultLanguage &&
                <div>
                    <h3 className='font-bold mr-2'>Lingua di default</h3>
                    <select
                        className="p-2 border border-gray-300 rounded-lg font-primary text-sm w-40"
                        value={defaultLanguage}
                        onChange={handleLanguageChange}
                        name="defaultLanguage"
                    >
                        <option value="">Seleziona una lingua</option>
                        {languages.map((languageKey) => (
                            <option key={languageKey} value={languageKey} >
                                {languageKey.toUpperCase()}
                            </option>
                        ))}
                    </select>
                </div>
            }
            {defaultLanguage &&
                <div className='flex flex-row justify-between gap-2 items-center my-2'>
                    <h3 className='font-bold'>Lingua di default</h3>
                    <div className=' p-2 w-16  rounded-lg flex flex-row justify-between items-center'>
                        <img className='w-20 rounded-lg' src={flagImages[defaultLanguage]} alt={`${defaultLanguage} flag`} />
                    </div>
                </div>
            }
        </div>

        <div className='flex flex-row gap-2 items-center my-1 mt-6'>
            <h3 className='font-bold'>Traduzioni({tempTranslations.filter(translatiions => translatiions.translatedName).length})</h3>
            <div className='flex flex-row gap-2 w-10'>
                {defaultLanguage !== '' &&
                    <button className="p-1 text-black rounded-lg"
                        onClick={handleTranslations}
                    >
                        <FiEdit />
                    </button>
                }
                {
                    tempTranslations && tempTranslations.length > 0 && tempTranslations.map((lang) =>
                        lang.translatedName ? (
                            <img src={flagImages[lang.language]} alt={`${lang.language} flag`} className="w-20 rounded-lg" key={lang.language} />
                        ) : null
                    )
                }


            </div>
        </div>


        {showCreateTranslations && (
            <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-90 flex items-center justify-center z-10 overflow-y-auto">
                <div className='font-primary form text-black text-center bg-gray-300 border w-9/12 lg:h-5/6 max-h-[80vh] rounded-lg p-5 overflow-y-auto' >
                    <div className='text-black font-primary font-bold'>Traduzioni Menu</div>
                    <div className='flex flex-col items-center lg:flex lg:flex-row lg:justify-around lg:items-center lg:flex-wrap gap-1 mt-5'>

                        {tempTranslations.map((translation, index) => (
                            <div key={translation.language} className='flex flex-col items-center p-2'>
                                <img src={flagImages[translation.language]} alt={`${translation.language} flag`} className="w-20 rounded-lg" />
                                <input
                                    type="text"
                                    placeholder="Nome"
                                    className="border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                                    value={translation.translatedName}
                                    onChange={e => handleTranslationChange(index, 'translatedName', e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Descrizione"
                                    className="border rounded-lg border-gray-400 p-2 bg-transparent mt-2"
                                    value={translation.translatedDescription}
                                    onChange={e => handleTranslationChange(index, 'translatedDescription', e.target.value)}
                                />
                            </div>
                        ))}

                    </div>

                    <div className='flex flex-row justify-center mt-4'>
                        <button className="p-2 w-40 rounded-lg black-background text-white text-sm"
                                onClick={(e) => closeCreateTranslations(e)}
                        >Chiudi</button>
                        <button className="ml-4 p-2 w-40 rounded-lg green-background text-white text-sm"
                                onClick={(e) => handleSaveTranslations(e)}
                        >Salva</button>
                    </div>
                </div>
            </div>
        )}
    </>
)
}

export default TranslationsNew