import React, { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { RiEdit2Fill } from "react-icons/ri";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faFish, faSeedling, faDrumstickBite, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMenusOwner, getMenuOwner, deleteMenuCategory, deleteMenuItem } from '../redux/menusSlice';
import CreateMenu from './CreateMenu';
import EditMenu from './EditMenu';
import CreateMenuCategory from './CreateMenuCategory';
import EditMenuCategory from './EditMenuCategory';
import CreateMenuItem from './CreateMenuItem';
import EditMenuItem from './EditMenuItem';
import MyIframe from './myIframe';
import QRCodeModal from './QRCodeModal';
import { SlArrowRight, SlArrowDown } from 'react-icons/sl';
import Buttons from './modal/Buttons';
import { AiOutlineQrcode, AiOutlinePicture } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';

const MenuConfiguratorContainer = () => {
  const dispatch = useDispatch();
  const [selectedMenuId, setSelectedMenuId] = useState('');
  const [selectedMenuDetails, setSelectedMenuDetails] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [showCreateMenu, setShowCreateMenu] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [showCreateMenuCategory, setShowCreateMenuCategory] = useState(false);
  const [showEditMenuCategory, setShowEditMenuCategory] = useState(false);
  const [showCreateMenuItem, setShowCreateMenuItem] = useState(false);
  const [showEditMenuItem, setShowEditMenuItem] = useState(false);
  const [selectedEditItemId, setSelectedEditItemId] = useState(null);
  const [selectedEditCategoryId, setSelectedEditCategoryId] = useState(null);
  const [loading, setLoading] = useState(false);
  const isLoadingMenuCategorydelete = useSelector((state) => state.menus.isLoadingMenuCategorydelete);
  const isLoadingMenuDelete = useSelector((state) => state.menus.isLoadingMenuDelete);
  const isLoadingMenuItemdelete = useSelector((state) => state.menus.isLoadingMenuItemdelete);
  const [iframeUrl, setIframeUrl] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

  const owner = useSelector((state) => state.owners.ownerLogged?.owner);

  const handleOpenEditMenuItem = (itemId, categoryId) => {
    setSelectedEditItemId(itemId);
    setSelectedEditCategoryId(categoryId);
    setShowEditMenuItem(true);
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
  };

  useEffect(() => {
    dispatch(getAllMenusOwner());
    setOwnerId(owner?._id);
  }, [dispatch, owner]);

  const menus = useSelector((state) => state.menus.ownerMenus?.menus);
  const menu = useSelector((state) => state.menus.ownerMenu?.menuToRender);

  const handleMenuChange = (e) => {
    const menuId = e.target.value;
    setSelectedMenuId(menuId);
    dispatch(getMenuOwner(menuId)).then((response) => {
      if (response.payload) {
        setSelectedMenuDetails(response.payload);
      }
    });
  };

  const refreshMenuOwner = () => {
    dispatch(getMenuOwner(menu._id));
  };

  const refreshAllMenusOwner = () => {
    dispatch(getAllMenusOwner());
  };

  const handleShowEditMenu = () => {
    setShowEditMenu(true);
  };

  const handleShowCreateMenu = () => {
    setShowCreateMenu(true);
  };

  const handleOpenCreateMenuCategory = () => {
    setShowCreateMenuCategory(true);
  };

  const handleOpenShowEditMenuCategory = (categoryId) => {
    setSelectedEditCategoryId(categoryId);
    setShowEditMenuCategory(true);
  };

  const handleOpenShowCreateMenuItem = (categoryId) => {
    setSelectedEditCategoryId(categoryId);
    setShowCreateMenuItem(true);
  };

  const getHandleDeleteMenuCategory = (menuId, categoryId) => async () => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questa categoria?");
    if (confirmDelete) {
      await handleDeleteMenuCategory(menuId, categoryId);
    }
  };

  const handleDeleteMenuCategory = async (menuId, categoryId) => {
    setLoading(true);
    const response = await dispatch(deleteMenuCategory({ menuId, categoryId }));
    if (deleteMenuCategory.fulfilled.match(response)) {
      await refreshMenuOwner();
      setLoading(false);
    }
  };

  const getHandleDeleteMenuItem = (menuId, categoryId, itemId) => async () => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo piatto?");
    if (confirmDelete) {
      await handleDeleteMenuItem(menuId, categoryId, itemId);
    }
  };

  const handleDeleteMenuItem = async (menuId, categoryId, itemId) => {
    setLoading(true);
    const response = await dispatch(deleteMenuItem({ menuId, categoryId, itemId }));
    if (deleteMenuItem.fulfilled.match(response)) {
      await refreshMenuOwner();
      setLoading(false);
    }
  };

  const handleIframeUrlChange = (url) => {
    setIframeUrl(url);
  };

  const openQRCodeModal = () => {
    setIsQRCodeModalOpen(true);
  };

  const closeQRCodeModal = () => {
    setIsQRCodeModalOpen(false);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-20 ml-4 mr-4">
      <div className="col-span-1 lg:col-span-2">
        <div className="border border-gray-500 bg-teal-50 rounded-xl p-4 flex">
          <div className="flex flex-row flex-wrap items-center gap-2">
            <AiOutlineQrcode className="text-3xl cursor-pointer mr-4" onClick={openQRCodeModal} />
            {menus && menus.length > 0 ? (
              <div className="flex flex-row">
                <select
                  className="p-2 border border-green-800 bg-gray-50 rounded-lg font-primary text-sm w-48 mr-4"
                  value={selectedMenuId}
                  onChange={handleMenuChange}
                >
                  <option value="">Seleziona un menu</option>
                  {menus.map((menu) => (
                    <option className="p-2" key={menu.menuToRender._id} value={menu.menuToRender._id}>
                      {menu.menuToRender.menu.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <span className="font-primary text-sm text-center text-black">No menus found</span>
            )}
            {!selectedMenuId && <Buttons type="green" onClick={handleShowCreateMenu}> Nuovo menu </Buttons>}
            {selectedMenuId && <Buttons type="green" onClick={handleShowEditMenu}> Modifica menu </Buttons>}
          </div>
          {showCreateMenu && (
            <CreateMenu
              owner={owner}
              showCreateMenu={showCreateMenu}
              setShowCreateMenu={setShowCreateMenu}
              refreshAllMenusOwner={refreshAllMenusOwner}
              menu={menu}
            />
          )}
          {showEditMenu && (
            <EditMenu
              showEditMenu={showEditMenu}
              setShowEditMenu={setShowEditMenu}
              menu={menu}
              refreshMenuOwner={refreshMenuOwner}
              setSelectedMenuId={setSelectedMenuId}
            />
          )}
        </div>

        {selectedMenuId && (
          <>
            <button className="mt-4 p-2 font-primary rounded-lg green-background text-center text-white text-sm" onClick={handleOpenCreateMenuCategory}>
              Aggiungi Categoria
            </button>
            {menu && menu.menu.menuCategory && menu.menu.menuCategory.length > 0 ? (
              <div className="mt-4">
                {menu.menu.menuCategory.map((category) => (
                  <div key={category._id} className="py-2 rounded-lg">
                    <div className="flex flex-row justify-center items-center">
                      <div className="flex flex-row justify-between items-center w-full border border-gray-300 p-2 bg-gray-200 text-black text-lg rounded-lg" onClick={() => toggleCategory(category._id)}>
                        <div className="flex items-center justify-between">
                          <button
                            className="pr-4"
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Apri / Chiudi"
                          >
                            {expandedCategory === category._id ? <SlArrowDown /> : <SlArrowRight />}
                          </button>
                          <div className="flex flex-col">
                            <div className="flex flex-col">
                              <h3 className="font-primary">{category.name}<span className="italic text-sm"> ({category.menuItems.length})</span></h3>
                              <span className="flex justify-start font-primary italic text-sm">{category.description}</span>
                            </div>
                          </div>
                          {category.isComplete && category.price && (
                            <span className="flex justify-start font-primary italic text-sm">{category.price}</span>
                          )}
                        </div>
                        {category.menuItems && category.menuItems.length > 0 ? (
                          <div className="flex flex-row justify-end items-center">
                            <div className="flex flex-row items-center align-middle">
                              <RiEdit2Fill
                                className="h-8 w-8 mr-4 cursor-pointer text-gray-700"
                                onClick={() => handleOpenShowEditMenuCategory(category._id)}
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Modifica Categoria"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-row justify-end items-center">
                            <div className="flex flex-row items-center align-middle">
                              <RiEdit2Fill className="h-8 w-8 mr-4 cursor-pointer text-gray-700" onClick={() => handleOpenShowEditMenuCategory(category._id)}></RiEdit2Fill>
                            </div>
                          </div>
                        )}
                        {showEditMenuCategory && (
                          <EditMenuCategory
                            key={selectedEditCategoryId}
                            owner={owner}
                            menu={menu}
                            menuId={menu._id}
                            categoryId={selectedEditCategoryId}
                            showEditMenuCategory={showEditMenuCategory}
                            setShowEditMenuCategory={setShowEditMenuCategory}
                            refreshAllMenusOwner={refreshAllMenusOwner}
                            refreshMenuOwner={refreshMenuOwner}
                            menuDefaultLanguage={menu.defaultLanguage}
                          />
                        )}
                      </div>
                    </div>

                    {expandedCategory === category._id && (
                      <div className="flex flex-col items-end mt-2 border border-gray-200 bg-gray-50 p-4 rounded-xl">
                        {category.menuItems && category.menuItems.length > 0 ? (
                          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-2 w-full">
                            {category.menuItems.map((item) => (
                              <div key={item._id} className="mt-1 p-2 border border-green-200 bg-green-100 rounded-lg">
                                <div className="grid grid-cols-3 items-center">
                                  <div className="flex flex-row items-center flex-grow truncate">
                                    <FiEdit2
                                      className="h-4 w-5 cursor-pointer text-gray-600 mr-2"
                                      onClick={() => handleOpenEditMenuItem(item._id, category._id)}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content="Modifica Piatto"
                                    />
                                    <span className="text-sm font-primary truncate">{item.name}</span>
                                  </div>
                                  <div>
                                  </div>
                                  <div className="flex flex-row justify-end items-center gap-2">

                                    <div className="hidden sm:flex flex-row justify-end items-center gap-2">

                                      <FontAwesomeIcon
                                        icon={item.isActive ? faCheckCircle : faTimesCircle}
                                        className={item.isActive ? 'green-background-text' : 'text-gray-300'}
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content={item.isActive ? 'Attivo' : 'Inattivo'}
                                      />
                                      <div className="border-r-2 border-gray-400 h-6"></div> {/* Divider between isActive and color */}

                                      <div className="w-4 h-4 rounded-full" style={{ backgroundColor: item.color || '#d3d3d3' }}
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content="Colore"
                                      ></div>

                                      <AiOutlinePicture
                                        className={item.image && item.image !== "false" ? 'green-background-text' : 'text-gray-300'}
                                        data-tooltip-id="tooltip"
                                        style={{ minWidth: '24px' }}
                                        data-tooltip-content="Immagine"
                                      />
                                      <div className="border-r-2 border-gray-400 h-6"></div> {/* Divider between isActive and color */}

                                      <FontAwesomeIcon
                                        icon={faSeedling}
                                        className={item.isVegetarian ? 'green-background-text' : 'text-gray-300'}
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content="Vegetariano"
                                      />
                                      <FontAwesomeIcon
                                        icon={faLeaf}
                                        className={item.isVegan ? 'green-background-text' : 'text-gray-300'}
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content="Vegano"
                                      />
                                      <FontAwesomeIcon
                                        icon={faDrumstickBite}
                                        className={item.isMeat ? 'green-background-text' : 'text-gray-300'}
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content="Carne"
                                      />
                                      <FontAwesomeIcon
                                        icon={faFish}
                                        className={item.isFish ? 'green-background-text' : 'text-gray-300'}
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content="Pesce"
                                      />
                                    </div>
                                    <div className="flex flex-row justify-end items-center gap-2">
                                      <span className="text-sm text-end font-primary font-bold green-background-text ml-8 w-12">{parseFloat(item.price).toFixed(1)}€</span>
                                    </div>
                                  </div>




                                  {showEditMenuItem && selectedEditItemId === item._id && (
                                    <EditMenuItem
                                      owner={owner}
                                      menu={menu}
                                      menuId={menu._id}
                                      categoryId={selectedEditCategoryId}
                                      itemId={item._id}
                                      showEditMenuItem={showEditMenuItem}
                                      setShowEditMenuItem={setShowEditMenuItem}
                                      refreshAllMenusOwner={refreshAllMenusOwner}
                                      refreshMenuOwner={refreshMenuOwner}
                                      menuDefaultLanguage={menu.defaultLanguage}
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="font-primary italic p-2 text-sm text-center">Nessun piatto presente</div>
                        )}
                        <div className="mt-2 justify-start">
                          <Buttons type="green" onClick={() => handleOpenShowCreateMenuItem(category._id)}> Nuovo Piatto </Buttons>
                        </div>
                      </div>
                    )}
                    {showCreateMenuItem && (
                      <CreateMenuItem
                        menu={menu}
                        menuId={menu._id}
                        categoryId={selectedEditCategoryId}
                        showCreateMenuItem={showCreateMenuItem}
                        setShowCreateMenuItem={setShowCreateMenuItem}
                        refreshAllMenusOwner={refreshAllMenusOwner}
                        refreshMenuOwner={refreshMenuOwner}
                        menuDefaultLanguage={menu.defaultLanguage}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="font-primary text-xs italic">Questo menu non contiene ancora nessuna categoria</div>
            )}

            {showCreateMenuCategory && (
              <CreateMenuCategory
                owner={owner}
                menu={menu}
                menuId={menu._id}
                showCreateMenuCategory={showCreateMenuCategory}
                setShowCreateMenuCategory={setShowCreateMenuCategory}
                refreshAllMenusOwner={refreshAllMenusOwner}
                refreshMenuOwner={refreshMenuOwner}
                menuDefaultLanguage={menu.defaultLanguage}
              />
            )}
          </>
        )}
      </div>

      <div className="col-span-1 hidden lg:block">
        <div className="sticky top-20">
          <div className="relative mx-auto h-[700px] w-auto aspect-[430/932] overflow-hidden bg-no-repeat bg-center border border-gray-500 rounded-xl p-8">
            <div className="relative w-full h-full">
              <MyIframe
                myMenuId={selectedMenuId}
                showCreateMenu={showCreateMenu}
                showEditMenu={showEditMenu}
                showEditMenuCategory={showEditMenuCategory}
                showCreateMenuItem={showCreateMenuItem}
                showEditMenuItem={showEditMenuItem}
                owner={owner}
                iframeUrlIn={iframeUrl}
                onUrlChange={handleIframeUrlChange}
              />
            </div>
          </div>
        </div>
      </div>

      <QRCodeModal isOpen={isQRCodeModalOpen} onRequestClose={closeQRCodeModal} url={iframeUrl} />

      {isLoadingMenuCategorydelete && loading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center z-10 overflow-y-auto">
          <div className="flex flex-row justify-center gap-2 pt-2">
            <div className="custom-loader"></div>
          </div>
        </div>
      )}
      {isLoadingMenuDelete && loading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center z-10 overflow-y-auto">
          <div className="flex flex-row justify-center gap-2 pt-2">
            <div className="custom-loader"></div>
          </div>
        </div>
      )}
      {isLoadingMenuItemdelete && loading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center z-10 overflow-y-auto">
          <div className="flex flex-row justify-center gap-2 pt-2">
            <div className="custom-loader"></div>
          </div>
        </div>
      )}
      <Tooltip id="tooltip" />
    </div>
  );
};

export default MenuConfiguratorContainer;
