import React, { useState } from 'react';
import Modal from './modal/Modal';
import Button from '../components/modal/Buttons'


const SendMail = ({closeSignUplModal}) => {

  const [formData, setFormData] = useState({ name: '', subject: '', message: '', userType: '' });


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSendEmail = () => {
    const { name, subject, message } = formData;
    const mailtoLink = `mailto:info@nemu-app.com?subject=${encodeURIComponent(subject)} (${encodeURIComponent(name)})&body=${encodeURIComponent(message)}`;
    if (window.navigator && window.navigator.share) {
      // Verifica se il dispositivo supporta la condivisione nativa
      window.navigator.share({
        title: subject,
        text: message,
        url: mailtoLink
      });
    } else {
      // Prova ad aprire il link mailto
      window.open(mailtoLink, '_self');
    }
  };
  

  return (
          <Modal closeFunction={closeSignUplModal} title='Registrati'>
            <div>
              <div className='mt-4 text-start text-gray-600 font-light'>
                Nemù è attualmente in fase di beta testing, le iscrizioni non sono ancora state attivate a tutti. 
                Se sei un ristorante e vuoi partecipare alla fase di testing contattaci compilando il form.
              </div>
              <hr className='mt-4 mb-4' />

              {/* Contenitore flessibile responsivo con allineamento verticale centrato */}
              <div className='flex flex-wrap items-center mb-4'>
                {/* Div contenente "Dicci chi sei" con flex-shrink-0 per evitare che si ridimensioni */}
                <div className='w-full md:w-auto flex items-center justify-center md:justify-start flex-shrink-0'>
                  Dicci chi sei
                </div>

                {/* Tendina di selezione */}
                <select
                  name='userType'
                  value={formData.userType}
                  onChange={handleChange}
                  className='w-full md:w-48 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-green-200 md:ml-4 mt-2 md:mt-0'
                  required
                >
                  <option value='' disabled>Seleziona il tuo tipo</option>
                  <option value='Ristorante'>Ristorante</option>
                  <option value='Investitore'>Investitore</option>
                  <option value='Altro'>Altro</option>
                </select>

                {/* Campo di input per il nome */}
                <input
                  type='text'
                  name='name'
                  placeholder='Nome'
                  value={formData.name}
                  onChange={handleChange}
                  className='w-full md:w-48 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-green-200 md:ml-4 mt-2 md:mt-0'
                  required
                />
              </div>

              {/* Form per oggetto e messaggio */}
              <form className='space-y-4'>
                <input
                  type='text'
                  name='subject'
                  placeholder='Oggetto'
                  value={formData.subject}
                  onChange={handleChange}
                  className='w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-green-200'
                  required
                />
                <textarea
                  name='message'
                  placeholder='Messaggio'
                  value={formData.message}
                  onChange={handleChange}
                  className='w-full p-3 h-60 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-green-200'
                  required
                />
                <Button
                  type='green'
                  onClick={handleSendEmail}
                >
                  Invia Email
                </Button>
              </form>
            </div>
          </Modal>
  );
};

export default SendMail;
