import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
const apiUrlIngredients = `${process.env.REACT_APP_SERVER_BASE_URL}/ingredients/owner`;
const apiUrlIngredientCreation = `${process.env.REACT_APP_SERVER_BASE_URL}/ingredients/create`;
const apiUrlIngredientUpdate = `${process.env.REACT_APP_SERVER_BASE_URL}/ingredients`;

export const createIngredient = createAsyncThunk('ingredients/create', async (ingredientData) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response =  await axios.post(`${apiUrlIngredientCreation}`, ingredientData, {
            headers: { 'Authorization': `${token}` }
          });
          console.log(response)
          return response.data;
    } catch (error) {
        console.log(error)
        console.error('Error creating ingredient', error);
        throw new Error('Error creating ingredient');
    }
})

export const updateIngredient = createAsyncThunk('ingredients/update', async ({ingredientId, dataToUpdate}) =>{
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response =  await axios.patch(`${apiUrlIngredientUpdate}/${ingredientId}/owner/update`, dataToUpdate, {
            headers: { 'Authorization': `${token}` }
          });
          console.log(response)
          return response.data;
    } catch (error) {
        console.log(error)
        console.error('Error updating ingredient', error);
        throw new Error('Error updating ingredient');
    }
})

export const getIngredients = createAsyncThunk('ingredients/owner', async () => {
    try {
        const token = JSON.parse(localStorage.getItem("userLoggedIn"));
        const response =  await axios.get(`${apiUrlIngredients}`, {
            headers: { 'Authorization': `${token}` }
          });
          console.log(response)
          return response.data;
    } catch (error) {
        console.log(error)
        console.error('Error getting owner ingredients', error);
        throw new Error('Error getting owner ingredients');
    }
})

const ingredientsSlice = createSlice({
    name: 'ingredients',
    initialState:{
        ingredientsOwner: null,
        ingredientsOwnerIsLoading: true,
        ingredientsOwnerError: null,
        ingredientsOwnerSuccess: null,
        ingredientCreation: null,
        ingredientCreationIsLoading: true,
        ingredientCreationError: null,
        ingredientCreationSuccess: null,
        ingredientUpdate: null,
        ingredientUpdateIsLoading: true,
        ingredientUpdateError: null,
        ingredientUpdateSuccess: null
    }, reducers:{

    }, extraReducers: (builder) =>{
        builder
        .addCase(getIngredients.pending, (state, action) => {
            state.ingredientsOwnerIsLoading = true
        })
        .addCase(getIngredients.rejected, (state, action) => {
            state.ingredientsOwnerError = action.payload
            state.ingredientsOwnerIsLoading = false
        })
        .addCase(getIngredients.fulfilled, (state, action) => {
            state.ingredientsOwner = action.payload
            state.ingredientsOwnerSuccess = action.payload.message
            state.ingredientsOwnerIsLoading = false
        })
        .addCase(createIngredient.pending, (state, action) => {
            state.ingredientCreationIsLoading = true
        })
        .addCase(createIngredient.rejected, (state, action) => {
            state.ingredientCreationError = action.payload
            state.ingredientCreationIsLoading = false
        })
        .addCase(createIngredient.fulfilled, (state, action) => {
            state.ingredientCreation = action.payload
            state.ingredientCreationSuccess = action.payload.message
            state.ingredientCreationIsLoading = false
        })
        .addCase(updateIngredient.pending, (state, action) => {
            state.ingredientUpdateIsLoading = true
        })
        .addCase(updateIngredient.rejected, (state, action) => {
            state.ingredientUpdateError = action.payload
            state.ingredientUpdateIsLoading = false
        })
        .addCase(updateIngredient.fulfilled, (state, action) => {
            state.ingredientUpdate = action.payload
            state.ingredientUpdateSuccess = action.payload.message
            state.ingredientUpdateIsLoading = false
        })
    }
})

export default ingredientsSlice.reducer;